import { Text } from "@gogeo-io/ui-library";
import styled from "styled-components";

interface ParamsFreqProps {
  freq: number;
  isBlocked?: boolean;
}

export function ParamsFreq({ freq, isBlocked = false }: ParamsFreqProps) {
  return (
    <Container isBlocked={isBlocked}>
      <Text css={{ color: "$gray500" }}>
        Frequência de atendimento: <strong> {freq} dias</strong>
      </Text>
    </Container>
  );
}

export const Container = styled.div<{
  isBlocked: boolean;
}>`
  cursor: ${({ isBlocked }) => (isBlocked ? "not-allowed" : "pointer")};
`;
