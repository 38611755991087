import { AddNewAdvancedFilterToHistoryFilter } from "@/components/advancedFilters/AddNewAdvancedFilterToHistoryFilter";
import { AdvancedFilter } from "@/core/models/advanced-filters.model";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Flex, Text } from "@gogeo-io/ui-library";
import { useSelector } from "react-redux";
import {
  removeSelectedHistoryFilter,
  selectSelectedHistoryFilters,
  setSelectedHistoryFilters,
  updateValueFromSelectedHistoryFilter,
} from "../../store/historyFiltersSlice";
import { HistoryFilterContainer } from "./HistoryFilterContainer";
import { HistoryFilterTypes } from "./HistoryFilterTypes";

export function HistoryFilter() {
  const selectedHistoryFilters = useSelector(selectSelectedHistoryFilters);

  const dispatch = useAppDispatch();

  const handleRemoveFilter = async (filter: AdvancedFilter) => {
    await dispatch(removeSelectedHistoryFilter(filter));
  };

  const handleUpdateValueFromSelectedHistoryFilter = async (
    filterId: number,
    newValue: any
  ) => {
    await dispatch(
      updateValueFromSelectedHistoryFilter({ filterId, newValue })
    );
  };

  const handleCleanFilters = async () => {
    await dispatch(setSelectedHistoryFilters([]));
  };

  return (
    <Flex css={{ flexDirection: "column", gap: "$1" }}>
      <Text wider css={{ color: "$gray500" }}>
        Filtros
      </Text>
      <HistoryFilterContainer>
        {selectedHistoryFilters.length === 0 ? (
          <Text css={{ color: "$gray500" }}>
            Você não tem nenhum filtro selecionado
          </Text>
        ) : (
          <Flex
            css={{
              gap: "$2",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            <HistoryFilterTypes
              onUpdateFilter={handleUpdateValueFromSelectedHistoryFilter}
              onRemoveFilter={handleRemoveFilter}
            />
          </Flex>
        )}

        <Flex css={{ alignItems: "center", gap: "$4" }}>
          <Text
            size="body2"
            wider
            css={{ color: "$gray400", cursor: "pointer" }}
            onClick={handleCleanFilters}
          >
            Limpar Filtros
          </Text>

          <AddNewAdvancedFilterToHistoryFilter />
        </Flex>
      </HistoryFilterContainer>
    </Flex>
  );
}
