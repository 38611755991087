import { Box, Button } from "@gogeo-io/ui-library";
import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectClient, selectClientStatus } from "../store/clientSlice";
import UI from "../ui";

type Tabs = "client-info" | "history" | "products-suggestion" | "notes";
interface ClientInfoTabsProps {
  route: Tabs;
  children: ReactNode;
}

export const ClientInfoTabs = ({ route, children }: ClientInfoTabsProps) => {
  const navigate = useNavigate();
  const selectedClient = useSelector(selectClient);
  const clientStatus = useSelector(selectClientStatus);

  const onPageChange = (tab: Tabs) =>
    navigate(`/info/client/${selectedClient.id}/${tab}`);

  return (
    <UI.ClientInfoTabs>
      <UI.ClientHistoryNavbar>
        <Button
          variant={route === "client-info" ? "solid" : "ghost"}
          onClick={() => onPageChange("client-info")}
        >
          Informações do cliente
        </Button>
        <Button
          variant={route === "history" ? "solid" : "ghost"}
          onClick={() => onPageChange("history")}
        >
          Histórico
        </Button>
        <Button
          variant={route === "products-suggestion" ? "solid" : "ghost"}
          onClick={() => onPageChange("products-suggestion")}
        >
          Sugestão de Produtos
        </Button>
        <Button
          variant={route === "notes" ? "solid" : "ghost"}
          onClick={() => onPageChange("notes")}
        >
          Notas
        </Button>
      </UI.ClientHistoryNavbar>

      <Box
        css={{
          width: "100%",
          height: "100%",
          overflowY: "scroll",
        }}
      >
        {clientStatus === "success" && <>{children}</>}
      </Box>
    </UI.ClientInfoTabs>
  );
};
