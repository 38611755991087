import styled from "styled-components";

export const AttendanceInfoWrapper = styled.div`
  display: grid;
  width: 100%;

  gap: ${({ theme }) => theme.space[2]};

  padding: ${({ theme }) => theme.space[3]};
`;
