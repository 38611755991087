import { ClientNote } from "@/core/models/client-notes.model";
import useAppDispatch from "@/hooks/useAppDispatch";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
  Flex,
  IconButton,
} from "@gogeo-io/ui-library";
import { AiOutlineClose } from "react-icons/ai";
import { BiSolidPencil } from "react-icons/bi";
import { BsTrashFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import {
  deleteClientNote,
  getClientNote,
  removeClientNoteFromState,
} from "../../store/clientNotesSlice";
import { DeleteClientNoteConfirmation } from "./DeleteClientNoteConfirmation";

export interface ClientNoteItemActionsProps {
  note: ClientNote;
}

export function ClientNoteItemActions({ note }: ClientNoteItemActionsProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleClickNote = async (note_id: string) => {
    await dispatch(getClientNote(note_id)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        navigate(`edit/${note.id}`);
      }
    });
  };

  const handleRemoveClientNote = async () => {
    await dispatch(deleteClientNote(note.id)).then(async (res) => {
      if (res.meta.requestStatus === "fulfilled") {
        await dispatch(removeClientNoteFromState(res.payload.id));
      }
    });
  };

  return (
    <Flex css={{ gap: "$1" }}>
      <Flex
        css={{
          width: "28px",
          height: "28px",
          borderRadius: "100%",
          background: "$primary600",
          color: "$primary100",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}
        onClick={() => handleClickNote(note.id)}
      >
        <BiSolidPencil color="inherit" fontSize="inherit" />
      </Flex>

      <Dialog>
        <DialogTrigger>
          <Flex
            css={{
              width: "28px",
              height: "28px",
              borderRadius: "100%",
              background: "$red600",
              color: "$red100",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <BsTrashFill color="inherit" fontSize="inherit" />
          </Flex>
        </DialogTrigger>
        <DialogContent style={{ width: "450px" }}>
          <DialogClose asChild>
            <IconButton
              type="ghost"
              css={{ position: "absolute", top: "1rem", right: "1rem" }}
              onClick={(event) => event.stopPropagation()}
            >
              <AiOutlineClose />
            </IconButton>
          </DialogClose>

          <DeleteClientNoteConfirmation
            onDeleteClientNote={handleRemoveClientNote}
          />
        </DialogContent>
      </Dialog>
    </Flex>
  );
}
