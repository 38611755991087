import { AdvancedFilterContainer } from "@/components/advancedFilters/AdvancedFilterContainer";
import { GenericDateFilter } from "@/components/advancedFilters/GenericDateFilter";
import { DateFilterTrigger } from "@/components/advancedFilters/Triggers/DateFilterTrigger";
import { AdvancedFilter } from "@/core/models/advanced-filters.model";
import { Flex, Text } from "@gogeo-io/ui-library";
import { ReportFilterContainer } from "./ReportFilterContainer";

interface ReportFiltersProps {
  dateToReport: string;
  onChangeDate: (filterId: number, newDate: string) => Promise<void>;
}

export function ReportFilters({
  dateToReport,
  onChangeDate,
}: ReportFiltersProps) {
  return (
    <Flex css={{ flexDirection: "column", gap: "$1" }}>
      <Text wider css={{ color: "$gray500" }}>
        Filtros
      </Text>
      <ReportFilterContainer>
        <Flex
          css={{
            gap: "$2",
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          <GenericDateFilter
            value={dateToReport}
            filterId={1}
            onUpdatedFilter={onChangeDate}
          >
            <AdvancedFilterContainer
              filter={
                {
                  label: "Filtro de Data",
                  type: "date",
                  description: "Filtro de Data",
                } as AdvancedFilter
              }
              triggerComponent={
                <DateFilterTrigger
                  filter={
                    {
                      label: "Filtro de Data",
                      type: "date",
                    } as AdvancedFilter
                  }
                  value={dateToReport}
                  notShowCloseIcon
                />
              }
            />
          </GenericDateFilter>
        </Flex>
      </ReportFilterContainer>
    </Flex>
  );
}
