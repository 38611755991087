import { EmptyContent } from "@/components/EmptyContent";
import { ErrorContent } from "@/components/ErrorContent";
import {
  getUserTags,
  selectUserTagsHasError500,
} from "@/core/store/tags/tagsSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Tag } from "@core/models/tag.model";
import {
  Caption,
  Checkbox,
  Flex,
  Input,
  Spinner,
  Text,
} from "@gogeo-io/ui-library";
import SearchIcon from "@mui/icons-material/Search";
import { debounce, isEmpty, isUndefined } from "lodash";
import React, { useEffect, useState } from "react";
import RLSSkeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useAttendanceMixpanel } from "../../hooks/useAttendanceMixpanel";
import {
  addTagOnSelectedClientFilter,
  removeTagOnSelectedClientFilter,
  selectSelectedTagsOnClientFilters,
} from "../../store/clientAdvancedFiltersSlice";
import { emitEventToChangeClients } from "../../store/emitEventToChangeClientsSlice";
import { selectSelectedList } from "../../store/listSelectedSlice";
import UI from "../../ui";
import { AdvancedFiltersTagsSkeleton } from "./AdvancedFiltersTagsSkeleton";

export function AdvancedFiltersTags() {
  const [tags, setTags] = useState<Tag[]>([]);
  const [tagsStatus, setTagsStatus] = useState("loading");
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMorePages, setHasMorePages] = useState(true);
  const [query, setQuery] = useState("");

  const selectedTags = useSelector(selectSelectedTagsOnClientFilters);
  const selectedList = useSelector(selectSelectedList);
  const tagsHasError500 = useSelector(selectUserTagsHasError500);

  const dispatch = useAppDispatch();
  const { tagRemovedOnListEvent, tagAddedOnListEvent } =
    useAttendanceMixpanel();

  const handleInputChange = async (value: string) => {
    setCurrentPage(0);
    setIsLoading(true);
    setHasMorePages(true);
    setQuery(value);

    await dispatch(
      getUserTags({
        page: 0,
        size: import.meta.env.VITE_PAGE_SIZE,
        query: value,
      })
    ).then(async (res) => {
      if (res.payload.tags.length === 0) {
        setHasMorePages(false);
        setTags([]);
      } else {
        setTags([...res.payload.tags]);
      }

      setIsLoading(false);
    });
  };

  const debounceOnChangeInput = debounce(handleInputChange, 500);

  const handleCheck = async (e: React.MouseEvent, tag: Tag) => {
    await dispatch(emitEventToChangeClients(false));

    if (e.currentTarget === e.target) {
      if (e) {
        addTag(tag);
        !isEmpty(selectedList) || isUndefined(selectedList)
          ? tagAddedOnListEvent(tag, {}, selectedList)
          : tagAddedOnListEvent(tag, selectedList.filters);
      } else {
        removeTag(tag);
        !isEmpty(selectedList) || isUndefined(selectedList)
          ? tagRemovedOnListEvent(tag, {}, selectedList)
          : tagRemovedOnListEvent(tag, selectedList.filters);
      }
    }

    await dispatch(emitEventToChangeClients(true));
  };

  const addTag = async (tag: Tag) => {
    await dispatch(addTagOnSelectedClientFilter(tag));
  };

  const removeTag = async (tag: Tag) => {
    await dispatch(removeTagOnSelectedClientFilter(tag));
  };

  const getPaginatedTags = async (currentPage: number) => {
    await dispatch(
      getUserTags({
        page: currentPage,
        size: import.meta.env.VITE_PAGE_SIZE,
        ...(query !== "" && {
          query,
        }),
      })
    ).then((res) => {
      if (res.payload.tags.length === 0) {
        setHasMorePages(false);
      } else {
        setTags((prevTags) => [...prevTags, ...res.payload.tags]);
      }
    });
  };

  const handleIncreasePageNumber = () => {
    setCurrentPage((prev) => prev + 1);
  };

  useEffect(() => {
    setHasMorePages(true);

    async function loadTags() {
      await dispatch(
        getUserTags({
          page: 0,
          size: import.meta.env.VITE_PAGE_SIZE,
          ...(query !== "" && {
            query,
          }),
        })
      ).then(async (res) => {
        if (res.payload.tags.length === 0) {
          setHasMorePages(false);
        } else {
          setTags((prevTags) => [...prevTags, ...res.payload.tags]);
        }

        setTagsStatus("fulfilled");
      });
    }

    loadTags();
  }, []);

  useEffect(() => {
    if (tagsStatus === "fulfilled") {
      const intersectionObserver = new IntersectionObserver((entries) => {
        if (entries.some((entry) => entry.isIntersecting)) {
          if (hasMorePages) {
            handleIncreasePageNumber();
          }
        }
      });

      intersectionObserver.observe(document.querySelector("#sentry"));

      return () => intersectionObserver.disconnect();
    }
  }, [tagsStatus]);

  useEffect(() => {
    if (currentPage !== 0) {
      if (hasMorePages) {
        getPaginatedTags(currentPage);
      }
    }
  }, [currentPage]);

  const tagIsSelected = (tag: Tag) => {
    return selectedTags?.some(
      (list) =>
        list.label === tag.label || list.slug === tag.slug || list.id === tag.id
    );
  };

  return (
    <Flex
      direction="column"
      css={{
        marginTop: "$2",
        width: "100%",
        gap: "$2",
        height: "calc(100% - 10px)",
      }}
    >
      {tagsHasError500 ? (
        <ErrorContent size="medium">
          Não conseguimos buscar suas tags, tente novamente mais tarde ou
          atualize a página
        </ErrorContent>
      ) : (
        <>
          <Input
            placeholder="Busque por uma tag"
            preffix={
              isLoading ? <Spinner /> : <SearchIcon fontSize="inherit" />
            }
            onChange={(e) => debounceOnChangeInput(e.target.value)}
            style={{ marginLeft: "2px", marginRight: "2px" }}
          />

          <Flex
            direction="column"
            css={{
              width: "100%",
              gap: "$2",
              overflowY: "auto",
            }}
          >
            <Flex css={{ flexDirection: "column", gap: "20px" }}>
              {tagsStatus === "fulfilled" ? (
                <>
                  {tags.length === 0 ? (
                    <>
                      <EmptyContent size="small">
                        Não encontramos tags com essa busca
                      </EmptyContent>
                      <div id="sentry"></div>
                    </>
                  ) : (
                    <>
                      {tags.map((tag) => {
                        return (
                          <UI.TagsListItem
                            key={tag.label}
                            className={tagIsSelected(tag) ? "active" : ""}
                          >
                            <Flex>
                              <Checkbox
                                id={String(tag.id)}
                                checked={tagIsSelected(tag)}
                                onCheckedChange={(e: any) =>
                                  handleCheck(e, tag)
                                }
                                css={{ marginLeft: "5px", marginRight: "5px" }}
                              />
                            </Flex>

                            <Flex css={{ flexDirection: "column", gap: "2px" }}>
                              <Text size="body2">{tag.label}</Text>
                              <Caption style={{ display: "block" }}>
                                <label htmlFor={String(tag.id)}>
                                  {tag.description}
                                </label>
                              </Caption>
                            </Flex>
                          </UI.TagsListItem>
                        );
                      })}
                      <div id="sentry">
                        {hasMorePages ? (
                          <Flex css={{ mt: "$1" }}>
                            <RLSSkeleton width={300} height={35} />
                          </Flex>
                        ) : null}
                      </div>
                    </>
                  )}
                </>
              ) : (
                <AdvancedFiltersTagsSkeleton count={8} />
              )}
            </Flex>
          </Flex>
        </>
      )}
    </Flex>
  );
}

export const TagCloudWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 10px;
`;
