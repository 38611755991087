import { selectUser } from "@/core/store/users/usersSlice";
import { ErrorPageLayout } from "@/layouts/ErrorPageLayout";
import { Button, Flex, Heading } from "@gogeo-io/ui-library";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import UnderConstructionSvg from "../assets/under-construction.svg";

export function NotASalesmanLogged() {
  const user = useSelector(selectUser);

  const navigate = useNavigate();

  const handleLogout = () => {
    navigate("/logout");
  };

  return (
    <ErrorPageLayout
      content={
        <Wrapper>
          <img src={UnderConstructionSvg} alt="sistema fora do ar" />
          <Flex
            css={{ flexDirection: "column", gap: "$2", textAlign: "center" }}
          >
            <Heading size="title3" className="title">
              Nova versão em construção...
            </Heading>
            <Heading size="subtitle1">
              Estamos trabalhando para criar uma nova versão do goVendas
              especialmente para você.
            </Heading>
          </Flex>
          <Flex css={{ gap: "$2" }}>
            {user !== undefined && (
              <Button bordered onClick={handleLogout}>
                Deslogar
              </Button>
            )}
          </Flex>
        </Wrapper>
      }
    />
  );
}

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.space[6]};

  img {
    width: 600px;
  }

  .title {
    color: ${({ theme }) => theme.colors.primary500};
  }
`;
