import { ReduxThunkError } from "@/core/errors/ReduxThunkError";
import { AdvancedFilter } from "@/core/models/advanced-filters.model";
import { RootState } from "@/core/store/store";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";

interface HistoryState {
  available_filters: AdvancedFilter[];
  selected_filters: AdvancedFilter[];
  status: "loading" | "success" | "failed";
}

const initialState = {
  available_filters: [],
  selected_filters: [],
  status: "loading",
} as HistoryState;

export const getAvailableHistoryFilters = createAsyncThunk(
  "historyFilters/getAvailableHistoryFilters",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(`/available-advanced-filters/history`);
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

export const historySlice = createSlice({
  name: "historySlice",
  initialState,
  reducers: {
    updateValueFromSelectedHistoryFilter(
      state,
      action: PayloadAction<{ filterId: number; newValue: any }>
    ) {
      state.selected_filters = state.selected_filters.map((filter) =>
        filter.id === action.payload.filterId
          ? { ...filter, value: action.payload.newValue }
          : filter
      );
    },
    removeSelectedHistoryFilter(state, action: PayloadAction<AdvancedFilter>) {
      state.selected_filters = state.selected_filters.filter(
        (filter) => filter.id !== action.payload.id
      );
    },
    addSelectedHistoryFilters(state, action: PayloadAction<AdvancedFilter[]>) {
      state.selected_filters = [...state.selected_filters, ...action.payload];
    },
    setSelectedHistoryFilters(state, action: PayloadAction<AdvancedFilter[]>) {
      state.selected_filters = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAvailableHistoryFilters.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getAvailableHistoryFilters.fulfilled, (state, action) => {
      state.status = "success";
      state.available_filters = action.payload;
    });
    builder.addCase(
      getAvailableHistoryFilters.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = "failed";
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName(
            "Error getting the available filters for history"
          );
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
  },
});

////////////////////////////////////////////////////

export const selectAvailableHistoryFilters = (state: RootState) =>
  state.historyFiltersState.available_filters;
export const selectSelectedHistoryFilters = (state: RootState) =>
  state.historyFiltersState.selected_filters;
export const selectAvailableHistoryFiltersStatus = (state: RootState) =>
  state.historyFiltersState.status;

////////////////////////////////////////////////////

export const {
  setSelectedHistoryFilters,
  removeSelectedHistoryFilter,
  updateValueFromSelectedHistoryFilter,
  addSelectedHistoryFilters,
} = historySlice.actions;

////////////////////////////////////////////////////

export const historyFiltersReducer = historySlice.reducer;
