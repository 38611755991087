import { ReduxThunkError } from "@/core/errors/ReduxThunkError";
import { ReportParams } from "@/core/models/reports.model";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";
import { RootState } from "../../../core/store/store";

interface ReportsState {
  data: Report;
  status: "loading" | "success" | "failed";
}

const initialState = {
  data: {},
  status: "loading",
} as ReportsState;

export interface GetReportProps {
  data: ReportParams;
  dashboard_name: string;
}

export const getReport = createAsyncThunk(
  "report/getReport",
  async ({ data, dashboard_name }: GetReportProps, thunkAPI) => {
    try {
      const response = await axios.post(`/dashboards/${dashboard_name}`, data);
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

export const reportSlice = createSlice({
  name: "reportSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getReport.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getReport.fulfilled, (state, action) => {
      state.status = "success";
      state.data = action.payload;
    });
    builder.addCase(getReport.rejected, (state, action: PayloadAction<any>) => {
      state.status = "failed";
      Sentry.configureScope((scope) => {
        scope.setLevel("error");
        scope.setTransactionName("Error getting the report");
        scope.setExtras(action.payload);
      });
      Sentry.captureException(new ReduxThunkError(action.payload));
      toast.error(action.payload.errorMessage);
    });
  },
});

export const selectReport = (state: RootState) => state.reportState.data;
export const selectReportStatus = (state: RootState) =>
  state.reportState.status;

export const reportReducer = reportSlice.reducer;
