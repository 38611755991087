import { MixpanelTracking, TrackingEvents } from "@/config/mixpanel";
import { BuildMixpanelProps } from "@/utils/buildMixpanelProps";

export const useMixpanel = () => {
  const buildProps = BuildMixpanelProps();

  const pageViewed = (event: TrackingEvents) => {
    MixpanelTracking.getInstance().track(event, buildProps());
  };

  const clientPageViewed = (event: TrackingEvents, props?: any) => {
    MixpanelTracking.getInstance().track(event, {
      ...buildProps("client"),
      props,
    });
  };

  const reportPageViewed = (event: TrackingEvents, dashboard_name: string) => {
    MixpanelTracking.getInstance().track(event, {
      dashboard_name,
    });
  };

  return {
    pageViewed,
    clientPageViewed,
    reportPageViewed,
  };
};
