import { SelectOption } from "@/core/models/advanced-filters.model";
import { ReactNode, useState } from "react";
import { GenericFilterPopover } from "../GenericFilterPopover";
import { GenericMultiSelectFilterContent } from "./GenericMultiSelectFilterContent";

interface GenericMultiSelectFilterProps {
  children?: ReactNode;
  filterId: number;
  value: SelectOption[];
  options: SelectOption[];
  onUpdatedFilter: (filterId: number, newOptions: SelectOption[]) => void;
}

interface FilterToChange {
  filterId: number;
  newOptions: SelectOption[];
}

export function GenericMultiSelectFilter({
  children,
  filterId,
  value,
  options,
  onUpdatedFilter,
}: GenericMultiSelectFilterProps) {
  const [isOpen, setIsOpen] = useState(false);

  const [filterChanged, setFilterChanged] = useState<FilterToChange>(
    {} as FilterToChange
  );

  const [optionsSelected, setOptionsSelected] = useState(value);

  const handleCheckboxChange = (isChecked: boolean, option: SelectOption) => {
    if (isChecked) {
      const newOptions = [...optionsSelected, option];
      setOptionsSelected(newOptions);
      const filter: FilterToChange = { filterId, newOptions };
      setFilterChanged(filter);
    } else {
      const newOptions = optionsSelected.filter((opt) => opt.id !== option.id);
      setOptionsSelected(newOptions);
      const filter: FilterToChange = { filterId, newOptions };
      setFilterChanged(filter);
    }
  };

  const handlePopoverOpenChange = (value: boolean) => {
    setIsOpen(value);
  };

  const openPopover = () => {
    setIsOpen(true);
  };

  const closePopover = () => {
    setIsOpen(false);
  };

  const applyFilter = () => {
    onUpdatedFilter(filterChanged.filterId, filterChanged.newOptions);
    setFilterChanged({} as FilterToChange);
    closePopover();
  };

  return (
    <GenericFilterPopover
      trigger={children}
      popoverIsOpen={isOpen}
      onOpenPopover={openPopover}
      onPopoverOpenChange={handlePopoverOpenChange}
    >
      <GenericMultiSelectFilterContent
        options={options}
        optionsSelected={optionsSelected}
        onCheckboxChange={handleCheckboxChange}
        onApplyFilter={applyFilter}
      />
    </GenericFilterPopover>
  );
}
