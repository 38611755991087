import { EmptyContent } from "@/components/EmptyContent";
import { AdvancedFilter } from "@/core/models/advanced-filters.model";
import {
  addSelectedHistoryFilters,
  selectAvailableHistoryFilters,
  selectAvailableHistoryFiltersStatus,
  selectSelectedHistoryFilters,
} from "@/features/history/store/historyFiltersSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
  Flex,
  Heading,
  IconButton,
} from "@gogeo-io/ui-library";
import { Add as AddIcon, Close } from "@mui/icons-material";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { AddNewAdvancedFiltersSkeleton } from "./AddNewAdvancedFiltersSkeleton";
import { FiltersThatTheUserCanSelect } from "./FiltersThatTheUserCanSelect";

export function AddNewAdvancedFilterToHistoryFilter() {
  const availableHistoryFilters = useSelector(selectAvailableHistoryFilters);
  const selectedHistoryFilters = useSelector(selectSelectedHistoryFilters);
  const availableHistoryFiltersStatus = useSelector(
    selectAvailableHistoryFiltersStatus
  );
  const [selectedHistoryFilterToAdd, setSelectedHistoryFilterToAdd] = useState<
    AdvancedFilter[]
  >([]);

  const dispatch = useAppDispatch();

  const handleSetSelectedHistoryFilter = async (advFilter: AdvancedFilter) => {
    setSelectedHistoryFilterToAdd((prevState) => [...prevState, advFilter]);
  };

  const handleRemoveSelectedClientFilter = async (
    filterToRemove: AdvancedFilter
  ) => {
    const filtersWithoutFilterToRemove = selectedHistoryFilterToAdd.filter(
      (filter) => filter.id !== filterToRemove.id
    );
    setSelectedHistoryFilterToAdd(filtersWithoutFilterToRemove);
  };

  const handleSetSelectedHistoryFilterToEmpty = () =>
    setSelectedHistoryFilterToAdd([]);

  const handleAddFilter = async () => {
    await dispatch(addSelectedHistoryFilters(selectedHistoryFilterToAdd));
    handleSetSelectedHistoryFilterToEmpty();
  };

  const getFilterThatTheUserCanSelect = () => {
    const filters = availableHistoryFilters.filter(
      (availableFilter) =>
        !selectedHistoryFilters.some(
          (selectedFilter) => selectedFilter.id === availableFilter.id
        )
    );

    return filters;
  };

  useEffect(() => {
    if (selectedHistoryFilters.length === 0) {
      handleSetSelectedHistoryFilterToEmpty();
    }
  }, [selectedHistoryFilters]);

  const filtersThatTheUserCanSelect = useMemo(
    () => getFilterThatTheUserCanSelect(),
    [availableHistoryFilters, selectedHistoryFilters]
  );

  return (
    <Dialog>
      <DialogTrigger asChild>
        <IconButton type="ghost" bordered>
          <AddIcon />
        </IconButton>
      </DialogTrigger>
      <DialogContent>
        <DialogClose asChild={true}>
          <IconButton
            type="ghost"
            css={{ position: "absolute", top: "1rem", right: "1rem" }}
          >
            <Close />
          </IconButton>
        </DialogClose>
        <Flex
          css={{
            flexDirection: "column",
            maxHeight: "700px",
            overflowY: "auto",
            gap: "$6",
          }}
        >
          <Flex css={{ flexDirection: "column", gap: "24px", flex: "1" }}>
            <Heading css={{ fontWeight: "bold" }}>Filtros Disponíveis</Heading>

            {availableHistoryFiltersStatus === "success" ? (
              <>
                {availableHistoryFilters.length === 0 ? (
                  <EmptyContent size="small">
                    Não encontramos filtros disponíveis
                  </EmptyContent>
                ) : (
                  <FiltersThatTheUserCanSelect
                    filtersThatTheUserCanSelect={filtersThatTheUserCanSelect}
                    selectedFilterToAdd={selectedHistoryFilterToAdd}
                    onSetSelectedFilter={handleSetSelectedHistoryFilter}
                    onRemoveSelectedFilter={handleRemoveSelectedClientFilter}
                  />
                )}
              </>
            ) : (
              <AddNewAdvancedFiltersSkeleton count={5} />
            )}
          </Flex>

          {filtersThatTheUserCanSelect.length > 0 && (
            <DialogClose asChild>
              <Flex
                css={{ alignItems: "flex-end", justifyContent: "flex-end" }}
              >
                <Button onClick={handleAddFilter}>Adicionar Filtro</Button>
              </Flex>
            </DialogClose>
          )}
        </Flex>
      </DialogContent>
    </Dialog>
  );
}
