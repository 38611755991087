import { Client } from "@/core/models/client.model";
import { useByFeedback } from "@/hooks/useByFeedback";
import { Caption, Text } from "@gogeo-io/ui-library";
import { format } from "date-fns";
import styled from "styled-components";

interface ClientInfoProps {
  client: Client;
}

export function ClientProfileLastAttendance({ client }: ClientInfoProps) {
  const { getColorByFeedback, getIconByStatus, getTextByFeedback } =
    useByFeedback();

  const lastAttendance = client.attendance_situation.lastAttendanceRecord;

  return (
    <Container>
      {lastAttendance.feedback_timestamp && (
        <Caption>
          {format(new Date(lastAttendance.feedback_timestamp), "hh:mm")}
        </Caption>
      )}

      <Icon color={getColorByFeedback(lastAttendance.feedback_id)}>
        {getIconByStatus(lastAttendance.feedback_id)}
      </Icon>

      <Info>
        <Text size="body2" bold css={{ color: "$gray900" }}>
          {getTextByFeedback(lastAttendance.feedback_id)} -{" "}
          {lastAttendance.feedback}
        </Text>
        <Caption css={{ color: "$gray600" }}>{lastAttendance.salesman}</Caption>
      </Info>
    </Container>
  );
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: ${({ theme }) => theme.radii.base};
  gap: ${({ theme }) => theme.space[2]};
  padding: ${({ theme }) => theme.space[3]};
  background-color: ${({ theme }) => theme.colors.gray100};
`;

export const Icon = styled.div<{ color: string }>`
  width: 28px;
  font-size: 20px;
  height: 28px;
  border-radius: ${({ theme }) => theme.radii.full};
  background-color: ${({ color }) => color};
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
`;
