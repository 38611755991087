import { ListDivider } from "@/components/ListDivider";
import { Flex } from "@gogeo-io/ui-library";
import RLSSkeleton from "react-loading-skeleton";

export function ClientNoteSkeleton() {
  return (
    <Flex
      css={{
        flexDirection: "column",
        padding: "$4 0",
        gap: "$5",
        width: "100%",
        height: "100%",
      }}
    >
      <Flex css={{ alignItems: "center", justifyContent: "space-between" }}>
        <Flex css={{ alignItems: "center", gap: "$2" }}>
          <RLSSkeleton width={20} height={20} />
          <RLSSkeleton width={122} height={28} />
          <RLSSkeleton width={20} height={20} />
        </Flex>

        <RLSSkeleton width={90} height={32} />
      </Flex>

      <ListDivider isSkeleton />

      <RLSSkeleton width={300} height={36} />
      <RLSSkeleton width={120} height={24} />

      <Flex css={{ alignItems: "center", gap: "$4" }}>
        <RLSSkeleton width={150} height={24} />
        <RLSSkeleton width={120} height={32} />
      </Flex>

      <ListDivider isSkeleton />

      <RLSSkeleton height={400} />
    </Flex>
  );
}
