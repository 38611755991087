import axios from "axios";
import { useEffect, useState } from "react";
import { hasAuthParams, useAuth } from "react-oidc-context";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { App } from "./App";
import { SplashScreen } from "./components/SplashScreen";
import { User } from "./core/models/user.model";
import { getUser, selectUserStatus } from "./core/store/users/usersSlice";
import useAppDispatch from "./hooks/useAppDispatch";
import { useCheckIfUserIsInWorkingTime } from "./hooks/useCheckIfUserIsInWorkingTime";

const timezoneOffset = new Date().getTimezoneOffset() / -60;

export const defaultHeaders = {
  Accept: "application/json",
  "content-type": "application/json",
  "Accept-Language": "pt-BR",
  "Accept-Timezone": `UTC${
    timezoneOffset !== 0
      ? `${timezoneOffset > 0 ? `+` : ""}${timezoneOffset}`
      : ""
  }`,
};

export const ProdMode: React.FC = () => {
  const userStatus = useSelector(selectUserStatus);

  const [tokenIsExpiring, setTokenIsExpiring] = useState(false);
  const [tokenHasExpired, setTokenHasExpired] = useState(false);
  const [isUserLoadedFirstTime, setIsUserLoadedFirstTime] = useState(true);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { checkIfUserIsInWorkingTime } = useCheckIfUserIsInWorkingTime();
  const auth = useAuth();

  const loadUserInfo = async () => {
    axios.defaults.baseURL = import.meta.env.VITE_BASE_URL;
    axios.defaults.headers = {
      ...defaultHeaders,
      Authorization: `Bearer ${auth.user?.access_token}`,
    } as any;

    await dispatch(getUser()).then((obj) => {
      if (obj.payload.status === 403 || obj.payload.status === 404) {
        navigate("/logout");
      }

      const user: User = obj.payload as User;

      setIsUserLoadedFirstTime(false);

      if (user.role !== "SALESMAN") {
        navigate("/not-a-salesman");
      }

      if (!checkIfUserIsInWorkingTime({ user })) {
        navigate("/user-is-not-in-working-time");
      }
    });
  };

  // when token change, update the Authorization on axios headers
  useEffect(() => {
    axios.defaults.headers = {
      ...defaultHeaders,
      Authorization: `Bearer ${auth.user?.access_token}`,
    } as any;
  }, [auth.user?.access_token]);

  // check if user is logged
  useEffect(() => {
    const checkIfExistingSession = async () => {
      if (!auth.isLoading && !auth.isAuthenticated) {
        await auth.signinSilent();
      }
    };

    void checkIfExistingSession();

    if (auth.isAuthenticated && isUserLoadedFirstTime) {
      loadUserInfo();
    }
  }, [auth.isAuthenticated]);

  // error on token renew
  useEffect(() => {
    return auth.events.addSilentRenewError(() => {
      setTokenHasExpired(true);
    });
  }, [auth.events]);

  // token is expiring due to inactivity
  useEffect(() => {
    return auth.events.addAccessTokenExpiring(() => {
      setTokenIsExpiring(true);
    });
  }, [auth.events]);

  // token expired
  useEffect(() => {
    return auth.events.addAccessTokenExpired(() => {
      setTokenHasExpired(true);
    });
  }, [auth.events]);

  // automatically sign-in
  useEffect(() => {
    if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading
    ) {
      auth.signinRedirect();
    }
  }, [
    auth.isAuthenticated,
    auth.activeNavigator,
    auth.isLoading,
    auth.signinRedirect,
  ]);

  return userStatus !== "success" ? (
    <SplashScreen />
  ) : (
    <App tokenIsExpiring={tokenIsExpiring} tokenHasExpired={tokenHasExpired} />
  );
};
