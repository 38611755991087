import { NotificationColorType } from "@/core/models/notification.model";
import { Text } from "@gogeo-io/ui-library";
import { motion } from "framer-motion";
import styled from "styled-components";

interface TopbarProps {
  type: NotificationColorType;
  bgColor: string;
  color: string;
}

export const TopbarStyled = styled(motion.div)<TopbarProps>`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 1rem;

  width: 100%;
  margin-bottom: 0;

  height: 2.5rem;

  position: absolute;
  z-index: 9999;

  .message-container {
    display: flex;
    align-items: center;
  }

  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.color};

  p {
    margin-left: 1rem;
    font-size: 0.9rem;
  }
`;

interface TopbarLinkProps {
  color: string;
}

export const TopbarLink = styled(Text)<TopbarLinkProps>`
  color: ${(props) => props.color};

  a {
    color: inherit;
    text-decoration: underline;
  }
`;
