import { IconWithBadge } from "@/components/IconWithBadge";
import { Reminders } from "@/features/reminder";
import { useReminderMixpanel } from "@/features/reminder/hooks/useReminderMixpanel";
import {
  getCountOfRemindersUnreaded,
  selectCountOfRemindersUnreaded,
  selectReminders,
} from "@/features/reminder/store/reminderSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import LogoAppBarSvg from "@assets/logo-app-bar.svg";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
  Flex,
} from "@gogeo-io/ui-library";
import { AccessAlarm as AccessAlarmIcon } from "@mui/icons-material";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { BreadCrumbs } from "./BreadCrumbs";
import { UserMenuWithKeycloak } from "./UserMenuWithKeycloak";

export function Appbar() {
  const countOfRemindersUnreaded = useSelector(selectCountOfRemindersUnreaded);
  const reminders = useSelector(selectReminders);

  const { createFeedbackModalOpenedEvent } = useReminderMixpanel();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleClickOnLogo = () => navigate("/attendance");

  useEffect(() => {
    const loadReminders = async () => {
      await dispatch(getCountOfRemindersUnreaded());
    };

    loadReminders();
  }, [reminders]);

  return (
    <Container>
      <Flex css={{ gap: "$4", alignItems: "center", cursor: "pointer" }}>
        <Flex onClick={handleClickOnLogo}>
          <img src={LogoAppBarSvg} alt="GoVendas Logo" />
        </Flex>
        <BreadCrumbs />
      </Flex>
      <Flex css={{ gap: "$4", alignItems: "center" }}>
        <Flex css={{ gap: "$3", alignItems: "center" }}>
          {/* <Dialog>
            <DialogTrigger>
              <IconWithBadge count={countOfRemindersNotReaded}>
                <NotificationsIcon fontSize="inherit" />
              </IconWithBadge>
            </DialogTrigger>
            <DialogContent>
              <Notifications />
            </DialogContent>
          </Dialog> */}

          <Dialog>
            <DialogTrigger onClick={() => createFeedbackModalOpenedEvent()}>
              <IconWithBadge count={countOfRemindersUnreaded}>
                <AccessAlarmIcon fontSize="inherit" />
              </IconWithBadge>
            </DialogTrigger>
            <DialogContent>
              <Reminders />
            </DialogContent>
          </Dialog>
        </Flex>

        <Flex css={{ height: "30px", width: "2px", background: "$gray200" }} />

        <UserMenuWithKeycloak />
      </Flex>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  width: 100%;
  height: 4rem;
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: 2px solid ${({ theme }) => theme.colors.gray100};
`;
