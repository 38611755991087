import { AdvancedFilterContainer } from "@/components/advancedFilters/AdvancedFilterContainer";
import { GenericBooleanFilter } from "@/components/advancedFilters/GenericBooleanFilter";
import { GenericCurrencyFilter } from "@/components/advancedFilters/GenericCurrencyFilter.tsx";
import { GenericDateFilter } from "@/components/advancedFilters/GenericDateFilter";
import { GenericMultiSelectFilter } from "@/components/advancedFilters/GenericMultiSelectFilter";
import { GenericSelectFilter } from "@/components/advancedFilters/GenericSelectFilter.tsx";
import { BooleanFilterTrigger } from "@/components/advancedFilters/Triggers/BooleanFilterTrigger";
import { CurrencyFilterTrigger } from "@/components/advancedFilters/Triggers/CurrencyFilterTrigger";
import { DateFilterTrigger } from "@/components/advancedFilters/Triggers/DateFilterTrigger";
import { MultiSelectFilterTrigger } from "@/components/advancedFilters/Triggers/MultiSelectFilterTrigger";
import { SelectFilterTrigger } from "@/components/advancedFilters/Triggers/SelectFilterTrigger";
import { AdvancedFilter } from "@/core/models/advanced-filters.model";
import { useSelector } from "react-redux";
import { selectSelectedHistoryFilters } from "../../store/historyFiltersSlice";

interface HistoryFilterTypes {
  onUpdateFilter: (filterId: number, newValue: any) => Promise<void>;
  onRemoveFilter: (filter: AdvancedFilter) => Promise<void>;
}

export function HistoryFilterTypes({
  onUpdateFilter,
  onRemoveFilter,
}: HistoryFilterTypes) {
  const selectedHistoryFilters = useSelector(selectSelectedHistoryFilters);

  return (
    <>
      {selectedHistoryFilters.map((filter) => {
        if (filter.type === "date") {
          return (
            <GenericDateFilter
              key={filter.label}
              value={filter.value}
              filterId={filter.id}
              onUpdatedFilter={onUpdateFilter}
            >
              <AdvancedFilterContainer
                key={filter.label}
                filter={filter}
                triggerComponent={
                  <DateFilterTrigger
                    filter={filter}
                    value={filter.value}
                    onRemoveFilter={onRemoveFilter}
                  />
                }
              />
            </GenericDateFilter>
          );
        }

        if (filter.type === "currency") {
          return (
            <GenericCurrencyFilter
              key={filter.label}
              value={filter.value}
              filterId={filter.id}
              onUpdatedFilter={onUpdateFilter}
            >
              <AdvancedFilterContainer
                key={filter.label}
                filter={filter}
                triggerComponent={
                  <CurrencyFilterTrigger
                    filter={filter}
                    value={filter.value}
                    onRemoveFilter={onRemoveFilter}
                  />
                }
              />
            </GenericCurrencyFilter>
          );
        }

        if (filter.type === "boolean") {
          return (
            <GenericBooleanFilter
              key={filter.label}
              value={filter.value}
              filterId={filter.id}
              onUpdatedFilter={onUpdateFilter}
            >
              <AdvancedFilterContainer
                key={filter.label}
                filter={filter}
                triggerComponent={
                  <BooleanFilterTrigger
                    filter={filter}
                    value={filter.value}
                    onRemoveFilter={onRemoveFilter}
                  />
                }
              />
            </GenericBooleanFilter>
          );
        }

        if (filter.type === "select") {
          return (
            <GenericSelectFilter
              key={filter.label}
              value={filter.value}
              filterId={filter.id}
              options={filter.options}
              onUpdatedFilter={onUpdateFilter}
            >
              <AdvancedFilterContainer
                key={filter.label}
                filter={filter}
                triggerComponent={
                  <SelectFilterTrigger
                    filter={filter}
                    value={filter.value}
                    onRemoveFilter={onRemoveFilter}
                  />
                }
              />
            </GenericSelectFilter>
          );
        }

        if (filter.type === "multiselect") {
          return (
            <GenericMultiSelectFilter
              value={filter.value}
              filterId={filter.id}
              options={filter.options}
              onUpdatedFilter={onUpdateFilter}
              key={filter.label}
            >
              <AdvancedFilterContainer
                key={filter.label}
                filter={filter}
                triggerComponent={
                  <MultiSelectFilterTrigger
                    filter={filter}
                    value={filter.value}
                    onRemoveFilter={onRemoveFilter}
                  />
                }
              />
            </GenericMultiSelectFilter>
          );
        }
      })}
    </>
  );
}
