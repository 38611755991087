import { useEffect } from "react";
import { useAuth } from "react-oidc-context";

export function Logout() {
  const auth = useAuth();

  useEffect(() => {
    auth.removeUser();
    auth.signoutRedirect({ id_token_hint: auth.user.id_token });
  }, []);

  return null;
}
