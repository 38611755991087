import { ReportParams } from "@/core/models/reports.model";
import { selectUser } from "@/core/store/users/usersSlice";
import { AppDispatch } from "@core/store/store";
import { Flex } from "@gogeo-io/ui-library";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { ReportSkeletonPage } from "../pages/ReportSkeletonPage";
import {
  getReport,
  selectReport,
  selectReportStatus,
} from "../store/reportSlice";
import { ReportFilters } from "./ReportFilters";

interface ReportItemProps {
  dateToReport: string;
  onSetDateToReport: (newValue: string) => void;
}

export function ReportItem({
  dateToReport,
  onSetDateToReport,
}: ReportItemProps) {
  const report = useSelector(selectReport);
  const user = useSelector(selectUser);
  const reportStatus = useSelector(selectReportStatus);

  const { dashboard_name } = useParams();

  const dispatch = useDispatch<AppDispatch>();

  const handleChangeDate = async (filterId: number, newDate: string) => {
    onSetDateToReport(newDate);
    await dispatch(
      getReport({
        dashboard_name,
        data: {
          date_filter: newDate,
          salesman_ids: [user.salesman_id],
          salesman_type: null,
          schedule_type: null,
        } as ReportParams,
      })
    );
  };

  return (
    <Flex
      css={{
        flexDirection: "column",
        gap: "$2",
        width: "100%",
        height: "100%",
      }}
    >
      <ReportFilters
        dateToReport={dateToReport}
        onChangeDate={handleChangeDate}
      />

      <Flex css={{ width: "100%", height: "100%" }}>
        <>
          {reportStatus === "success" ? (
            <iframe src={report.url} style={{ width: "100%" }} />
          ) : (
            <ReportSkeletonPage />
          )}
        </>
      </Flex>
    </Flex>
  );
}

export const ShowText = styled.span`
  font-size: 14px;
  margin-left: 5px;
`;
