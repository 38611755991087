import { ClientNote } from "@/core/models/client-notes.model";
import { FileMetadata } from "@/core/models/file.model";
import { RootState } from "@/core/store/store";
import { convertRichTextToString } from "@/utils/convertRichTextToString";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";
import { Descendant } from "slate";
import { ReduxThunkError } from "../../../core/errors/ReduxThunkError";

interface ClientNotesState {
  has500error: boolean;
  notes: ClientNote[];
  selectedNote: ClientNote;
  notesStatus: "loading" | "success" | "failed";
  noteStatus: "loading" | "success" | "failed";
}

const initialState = {
  has500error: false,
  notes: [],
  selectedNote: {},
  notesStatus: "loading",
  noteStatus: "loading",
} as ClientNotesState;

interface GetClientNotesProps {
  client_id: string;
  title?: string;
  sort?: string;
}

export const getClientNotes = createAsyncThunk(
  "notes/getClientNotes",
  async (
    { client_id, title = "", sort = "" }: GetClientNotesProps,
    thunkAPI
  ) => {
    try {
      let baseUrl = `/client/note?clientId=${client_id}`;

      if (title !== "") {
        baseUrl += `&title=${title}`;
      }

      if (sort !== "") {
        if (sort === "hasFiles") {
          baseUrl += `&hasFiles=${true}`;
        } else {
          baseUrl += `&sort=${sort}`;
        }
      }

      const response = await axios.get(baseUrl);
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

export const getClientNote = createAsyncThunk(
  "notes/getClientNote",
  async (id: string, thunkAPI) => {
    try {
      const response = await axios.get(`/client/note/${id}`);
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

export interface UpdateClientNoteProps {
  id: string;
  title: string;
  content: Descendant[];
  files: FileMetadata[];
}

export const updateClientNote = createAsyncThunk(
  "notes/updateClientNotes",
  async (data: UpdateClientNoteProps, thunkAPI) => {
    const form = {
      ...data,
      excerpt: convertRichTextToString(data.content).slice(0, 250),
    };

    try {
      const response = await axios.put(`/client/note/${data.id}`, form);
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

interface UpdateIsFavoriteOnClientNoteProps {
  note_id: string;
  is_favorite: boolean;
}

export const updateIsFavoriteOnClientNote = createAsyncThunk(
  "notes/updateIsFavoriteOnClientNote",
  async (data: UpdateIsFavoriteOnClientNoteProps, thunkAPI) => {
    try {
      const response = await axios.put(`/client/note/${data.note_id}`, {
        is_favorite: data.is_favorite,
      });
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

export const deleteClientNote = createAsyncThunk(
  "notes/deleteClientNote",
  async (id: string, thunkAPI) => {
    try {
      const response = await axios.delete(`/client/note/${id}`);
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

export interface CreateClientNoteProps {
  client_id: string;
  title: string;
  content: Descendant[];
}

export const createClientNote = createAsyncThunk(
  "notes/createClientNote",
  async (data: CreateClientNoteProps, thunkAPI) => {
    const form = {
      ...data,
      excerpt: convertRichTextToString(data.content).slice(0, 250),
    };

    try {
      const response = await axios.post(`/client/note?${data.client_id}`, form);
      if (
        response.status !== 200 &&
        response.status !== 201 &&
        response.status !== 202
      ) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

export const clientNotesSlice = createSlice({
  name: "clientNotesSlice",
  initialState,
  reducers: {
    addClientNotesOnState(state, action: PayloadAction<ClientNote>) {
      state.notes.push(action.payload);
    },
    setSelectedClientNote(state, action: PayloadAction<ClientNote>) {
      state.selectedNote = action.payload;
    },
    removeClientNoteFromState(state, action: PayloadAction<string>) {
      const stateWithoutRemovedOne = state.notes.filter(
        (reminder) => reminder.id !== action.payload
      );
      state.notes = stateWithoutRemovedOne;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getClientNotes.pending, (state, action) => {
      state.notesStatus = "loading";
      state.has500error = false;
    });
    builder.addCase(getClientNotes.fulfilled, (state, action) => {
      state.notes = action.payload;
      state.notesStatus = "success";
      state.has500error = false;
    });
    builder.addCase(
      getClientNotes.rejected,
      (state, action: PayloadAction<any>) => {
        if (action.payload.status === 500) {
          state.has500error = true;
        }
        state.notesStatus = "failed";
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error getting the client notes");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
    builder.addCase(getClientNote.pending, (state, action) => {
      state.noteStatus = "loading";
      state.has500error = false;
    });
    builder.addCase(getClientNote.fulfilled, (state, action) => {
      state.selectedNote = action.payload;
      state.noteStatus = "success";
      state.has500error = false;
    });
    builder.addCase(
      getClientNote.rejected,
      (state, action: PayloadAction<any>) => {
        if (action.payload.status === 500) {
          state.has500error = true;
        }
        state.notesStatus = "failed";
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error getting the specific client note");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
    builder.addCase(updateClientNote.pending, (state, action) => {
      state.notesStatus = "loading";
      state.has500error = false;
    });
    builder.addCase(updateClientNote.fulfilled, (state, action) => {
      [...state.notes].filter((note) =>
        note.id === action.payload.id ? action.payload : note
      );
      state.notesStatus = "success";
      state.has500error = false;
    });
    builder.addCase(
      updateClientNote.rejected,
      (state, action: PayloadAction<any>) => {
        state.notesStatus = "failed";
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error updating the client note");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
    builder.addCase(updateIsFavoriteOnClientNote.pending, (state, action) => {
      state.has500error = false;
    });
    builder.addCase(
      updateIsFavoriteOnClientNote.fulfilled,
      (state, action: PayloadAction<ClientNote>) => {
        state.notes.map((note) =>
          note.id === action.payload.id
            ? (note.is_favorite = action.payload.is_favorite)
            : note
        );
        state.has500error = false;
      }
    );
    builder.addCase(
      updateIsFavoriteOnClientNote.rejected,
      (state, action: PayloadAction<any>) => {
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName(
            "Error updating the is favorite on client note"
          );
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
    builder.addCase(
      deleteClientNote.rejected,
      (state, action: PayloadAction<any>) => {
        state.notesStatus = "failed";
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error deleting the client note");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
    builder.addCase(createClientNote.pending, (state, action) => {
      state.notesStatus = "loading";
      state.has500error = false;
    });
    builder.addCase(createClientNote.fulfilled, (state, action) => {
      state.notes.push(action.payload);
      state.notesStatus = "success";
      state.has500error = false;
    });
    builder.addCase(
      createClientNote.rejected,
      (state, action: PayloadAction<any>) => {
        state.notesStatus = "failed";
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error when creating the client note");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
  },
});

export const selectClientNotes = (state: RootState) => state.clientNotes.notes;
export const selectSelectedClientNote = (state: RootState) =>
  state.clientNotes.selectedNote;
export const selectClientNotesStatus = (state: RootState) =>
  state.clientNotes.notesStatus;
export const selectClientNoteStatus = (state: RootState) =>
  state.clientNotes.noteStatus;
export const selectClientNotesHasError500 = (state: RootState) =>
  state.clientNotes.has500error;

export const {
  addClientNotesOnState,
  removeClientNoteFromState,
  setSelectedClientNote,
} = clientNotesSlice.actions;

export const clientNotesReducer = clientNotesSlice.reducer;
