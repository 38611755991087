import { EmptyContent } from "@/components/EmptyContent";
import { AdvancedFilter } from "@/core/models/advanced-filters.model";
import {
  setAttributesToFilter,
  setDefaultAttributesToFilter,
} from "@/features/clientInfo/store/clientAttrOptsSlice";
import {
  cleanColumnsToSort,
  setColumnsToSort,
} from "@/features/clientInfo/store/clientColumnsToSortSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { useGoBack } from "@/hooks/useGoBack";
import { AddNewAdvancedFilterToClientFilter } from "@components/advancedFilters/AddNewAdvancedFilterToClientFilter";
import { Caption, Flex, Heading, IconButton } from "@gogeo-io/ui-library";
import { useEffect, useState } from "react";
import { BiChevronLeft } from "react-icons/bi";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  addTagsOnSelectedClientFilter,
  getAvailableClientFilters,
  removeSelectedAdvancedFilter,
  selectAvailableAdvancedFiltersStatus,
  selectSelectedAdvancedFilters,
  setSelectedAdvancedFilters,
  updateValueFromSelectedAdvancedFilter,
} from "../../store/clientAdvancedFiltersSlice";
import { emitEventToChangeClients } from "../../store/emitEventToChangeClientsSlice";
import {
  selectListToEdit,
  selectSelectedListToEdit,
} from "../../store/listSelectedSlice";
import { AdvancedFiltersActions } from "./AdvancedFiltersActions";
import { AdvancedFiltersSkeleton } from "./AdvancedFiltersSkeleton";
import { AdvancedFiltersTags } from "./AdvancedFiltersTags";
import { AdvancedFiltersTypes } from "./AdvancedFiltersTypes";

export function AdvancedFiltersToParams() {
  const [showMoreFilters, setShowMoreFilters] = useState(false);

  const selectedListToEdit = useSelector(selectSelectedListToEdit);
  const selectedAdvancedFilters = useSelector(selectSelectedAdvancedFilters);
  const availableAdvancedFiltersStatus = useSelector(
    selectAvailableAdvancedFiltersStatus
  );
  const dispatch = useAppDispatch();
  const { navigateGoBack } = useGoBack();

  const handleSetShowMoreFilters = () => {
    setShowMoreFilters((value) => !value);
  };

  const handleSetShowMoreFiltersToTrue = () => {
    setShowMoreFilters(true);
  };

  const handleRemoveFilter = async (filter: AdvancedFilter) => {
    await dispatch(emitEventToChangeClients(false));

    await dispatch(removeSelectedAdvancedFilter(filter));

    await dispatch(emitEventToChangeClients(true));
  };

  const handleUpdateValueFromSelectedHistoryFilter = async (
    filterId: number,
    newValue: any
  ) => {
    await dispatch(emitEventToChangeClients(false));

    await dispatch(
      updateValueFromSelectedAdvancedFilter({ filterId, newValue })
    );

    await dispatch(emitEventToChangeClients(true));
  };

  const handleCleanFilters = async () => {
    await dispatch(emitEventToChangeClients(false));

    await dispatch(selectListToEdit(selectedListToEdit));

    const columnsToSort = selectedListToEdit.filters?.sort?.orders;
    if (columnsToSort !== undefined && columnsToSort.length > 0) {
      await dispatch(setColumnsToSort(columnsToSort));
    } else {
      await dispatch(cleanColumnsToSort());
    }

    const advancedFilters = selectedListToEdit.filters?.advanced_filters;
    if (advancedFilters !== undefined && advancedFilters.length > 0) {
      await dispatch(setSelectedAdvancedFilters(advancedFilters));
    } else {
      await dispatch(setSelectedAdvancedFilters([]));
    }

    const tags = selectedListToEdit.filters?.tags;
    if (tags !== undefined && tags.length > 0) {
      await dispatch(addTagsOnSelectedClientFilter(tags));
    } else {
      await dispatch(addTagsOnSelectedClientFilter([]));
    }

    const attrOpts = selectedListToEdit.filters?.attr_opts;
    if (attrOpts !== undefined) {
      await dispatch(setAttributesToFilter(attrOpts));
    } else {
      await dispatch(setDefaultAttributesToFilter());
    }

    await dispatch(emitEventToChangeClients(true));
  };

  useEffect(() => {
    async function loadHistoryAvailableFilters() {
      await dispatch(getAvailableClientFilters());
    }

    loadHistoryAvailableFilters();
  }, [selectedAdvancedFilters]);

  return (
    <Container>
      <Flex css={{ alignItems: "center", gap: "$2" }}>
        <IconButton
          size="small"
          type="ghost"
          bordered
          onClick={() => navigateGoBack()}
        >
          <BiChevronLeft />
        </IconButton>
        <Heading size="subtitle2">{selectedListToEdit.name}</Heading>
      </Flex>
      <Flex
        css={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Caption
          onClick={handleSetShowMoreFilters}
          css={{
            cursor: "pointer",
            color: "$primary500",
            textDecoration: "underline",
          }}
          style={{ display: "block" }}
        >
          {!showMoreFilters
            ? "Mostrar filtros avançados"
            : "Ocultar filtros avançados"}
        </Caption>
      </Flex>
      {!showMoreFilters ? (
        <Flex
          direction="column"
          css={{
            width: "100%",
            height: "calc(100% - 15px)",
          }}
        >
          <AdvancedFiltersTags />
        </Flex>
      ) : (
        <>
          <Flex
            direction="column"
            css={{
              gap: "$4",
              width: "100%",
              height: "calc(100% - 90px)",
              overflowY: "auto",
              mt: "$4",
            }}
          >
            <Heading>Filtros Selecionados</Heading>
            {availableAdvancedFiltersStatus === "success" ? (
              <Flex
                css={{
                  flexDirection: "column",
                  gap: "$4",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                {selectedAdvancedFilters.length > 0 ? (
                  <AdvancedFiltersTypes
                    onUpdateFilter={handleUpdateValueFromSelectedHistoryFilter}
                    onRemoveFilter={handleRemoveFilter}
                  />
                ) : (
                  <EmptyContent size="small">
                    Você Não Tem filtros Selecionados
                  </EmptyContent>
                )}
              </Flex>
            ) : (
              <AdvancedFiltersSkeleton />
            )}
          </Flex>

          <Flex
            direction="column"
            css={{
              alignItems: "center",
              gap: "$2",
              width: "100%",
              margin: "$2",
            }}
          >
            <AddNewAdvancedFilterToClientFilter
              onClickAddNewFilter={handleSetShowMoreFiltersToTrue}
            />
          </Flex>
        </>
      )}

      <AdvancedFiltersActions onCleanFilters={handleCleanFilters} />
    </Container>
  );
}

export const Container = styled.div`
  height: calc(100% - 60px);
`;
