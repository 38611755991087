import { AdvancedFilter } from "@/core/models/advanced-filters.model";
import { Box, Caption, Checkbox, Flex, Text } from "@gogeo-io/ui-library";
import { EmptyContent } from "../EmptyContent";
import { AdvancedFilterListItem } from "./styles";

interface FiltersThatTheUserCanSelectProps {
  filtersThatTheUserCanSelect: AdvancedFilter[];
  selectedFilterToAdd: AdvancedFilter[];
  onSetSelectedFilter: (advFilter: AdvancedFilter) => void;
  onRemoveSelectedFilter: (advFilter: AdvancedFilter) => void;
}

export function FiltersThatTheUserCanSelect({
  filtersThatTheUserCanSelect,
  selectedFilterToAdd,
  onSetSelectedFilter,
  onRemoveSelectedFilter,
}: FiltersThatTheUserCanSelectProps) {
  const addAdvancedFilter = (advFilter: AdvancedFilter) => {
    onSetSelectedFilter(advFilter);
  };

  const removeAdvancedFilter = async (advFilter: AdvancedFilter) => {
    onRemoveSelectedFilter(advFilter);
  };

  const handleCheck = (e: React.MouseEvent, advFilter: AdvancedFilter) => {
    if (e.currentTarget === e.target) {
      e ? addAdvancedFilter(advFilter) : removeAdvancedFilter(advFilter);
    }
  };

  const advancedFiltersIsSelected = (advFilter: AdvancedFilter): boolean => {
    return selectedFilterToAdd.some((filter) => filter.id === advFilter.id);
  };

  return (
    <Box css={{ maxWidth: "500px" }}>
      {filtersThatTheUserCanSelect.length > 0 ? (
        <Flex css={{ flexDirection: "column", gap: "$3" }}>
          {filtersThatTheUserCanSelect.map((filter) => {
            return (
              <AdvancedFilterListItem
                key={filter.property}
                className={advancedFiltersIsSelected(filter) ? "active" : ""}
              >
                <Flex key={filter.id} css={{ gap: "0.5rem" }}>
                  <Flex css={{ alignItems: "center" }}>
                    <Checkbox
                      id={filter.property}
                      checked={advancedFiltersIsSelected(filter)}
                      onCheckedChange={(e: any) => handleCheck(e, filter)}
                      css={{
                        marginLeft: "5px",
                        marginRight: "5px",
                      }}
                    />
                  </Flex>
                  <Flex css={{ flexDirection: "column", gap: "2px" }}>
                    <Text size="body2">{filter.label}</Text>
                    <Caption style={{ display: "block" }}>
                      <label htmlFor={filter.property}>
                        {filter.description}
                      </label>
                    </Caption>
                  </Flex>
                </Flex>
              </AdvancedFilterListItem>
            );
          })}
        </Flex>
      ) : (
        <EmptyContent size="small">
          Não há mais filtros disponíveis para você selecionar
        </EmptyContent>
      )}
    </Box>
  );
}
