import { WhatsappClientDialog } from "@/components/WhatsappClientDialog";
import { User } from "@/core/models/user.model";
import { getPreDefinedMessages } from "@/features/profile/store/preDefinedMessagesSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { IconButton } from "@gogeo-io/ui-library";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import styled from "styled-components";
import { ProfileInfo } from "./ProfileInfo";

interface ClientInfoProps {
  label: string;
  value: string;
  client: any;
  user: User;
  phoneActionsIsVisible?: boolean;
}

export function ClientProfileInfoPhone({
  label,
  value,
  client,
  user,
  phoneActionsIsVisible = false,
}: ClientInfoProps) {
  const [isVisible, setIsVisible] = useState(phoneActionsIsVisible);

  const dispatch = useAppDispatch();

  const openDialog = async () => {
    await dispatch(
      getPreDefinedMessages({
        page: 0,
        size: import.meta.env.VITE_PAGE_SIZE,
      })
    );
  };

  const showButtons = () => setIsVisible(true);
  const hideButtons = () => setIsVisible(false);

  return (
    <Wrapper
      onMouseEnter={!phoneActionsIsVisible ? showButtons : () => {}}
      onMouseLeave={!phoneActionsIsVisible ? hideButtons : () => {}}
    >
      <ProfileInfo label={label} value={value} />

      <AnimatePresence>
        {isVisible && value !== "" && value !== undefined && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.15 } }}
            exit={{ opacity: 0 }}
          >
            {/* <IconButton size="medium" color="primary" type="ghost">
              <LocalPhoneIcon fontSize="inherit" />
            </IconButton> */}
            <WhatsappClientDialog client={client} user={user} phone={value}>
              <IconButton
                size="medium"
                color="primary"
                type="ghost"
                onClick={(event) => {
                  event.stopPropagation();
                  openDialog();
                }}
              >
                <WhatsAppIcon fontSize="inherit" />
              </IconButton>
            </WhatsappClientDialog>
          </motion.div>
        )}
      </AnimatePresence>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 35px;
  margin-top: -5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
