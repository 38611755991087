import {
  NotificationColorType,
  NotificationType,
} from "@/core/models/notification.model";
import { gvTheme } from "@gogeo-io/ui-library";
import ComputerIcon from "@mui/icons-material/Computer";
import InfoIcon from "@mui/icons-material/Info";

export const useNotificationType = () => {
  const getNotificationIcon = (type: NotificationType) => {
    switch (type) {
      case "update":
        return <ComputerIcon />;
      case "info":
        return <InfoIcon />;
      default:
        return <InfoIcon />;
    }
  };

  const getBackgroundColor = (color: NotificationColorType) => {
    switch (color) {
      case "danger":
        return gvTheme.colors.red600;
      case "success":
        return gvTheme.colors.primary50;
      default:
        return gvTheme.colors.gray900;
    }
  };

  const getColor = (color: NotificationColorType) => {
    switch (color) {
      case "danger":
        return gvTheme.colors.white;
      case "success":
        return gvTheme.colors.gray900;
      case "neutral":
        return gvTheme.colors.white;
    }
  };

  return { getNotificationIcon, getBackgroundColor, getColor };
};
