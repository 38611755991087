import styled from "styled-components";

export const TimelineDot = styled.div<{
  colorByFeedback: string;
}>`
  width: 10px;
  height: 10px;
  border-radius: 9999px;
  background: ${(props) => props.colorByFeedback};
`;
