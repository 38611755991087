import { Caption } from "@gogeo-io/ui-library";
import CloseIcon from "@mui/icons-material/Close";
import styled from "styled-components";

interface TagLabelProps {
  isSelected?: boolean;
  selectable?: boolean;
  onClick?: () => void;
  hasCloseButton?: boolean;
  onClickCloseButton?: () => void;
  children: any;
  [x: string]: any;
}

export function TagLabel({
  isSelected = false,
  selectable = false,
  hasCloseButton = false,
  onClick,
  onClickCloseButton,
  children,
  ...props
}: TagLabelProps) {
  return (
    <TagStyled
      isSelected={isSelected}
      selectable={selectable}
      onClick={onClick}
      {...props}
    >
      {isSelected ? (
        <Caption css={{ color: "$white" }}>{children}</Caption>
      ) : (
        <Caption css={{ color: "$gray700" }}>{children}</Caption>
      )}
      {hasCloseButton && (
        <CloseIcon
          onClick={onClickCloseButton}
          color="inherit"
          fontSize="inherit"
        />
      )}
    </TagStyled>
  );
}

const TagStyled = styled.div<{
  isSelected?: boolean;
  selectable?: boolean;
}>`
  display: flex;
  font-size: ${({ theme }) => theme.fontSizes.sm};
  padding: 0.375rem 0.675rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: ${({ selectable }) => (selectable ? "pointer" : "normal")};
  color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.gray50 : theme.colors.gray700};
  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.gray700 : theme.colors.gray200};

  transition: all 0.15s ease-in-out;

  svg {
    cursor: pointer;
    margin-left: 5px;
  }

  &:hover {
    ${({ theme, isSelected }) =>
      isSelected
        ? `
      background-color: ${theme.colors.gray900};
    `
        : `
      background-color: ${theme.colors.gray300};
    `}
  }
`;
