import { ReduxThunkError } from "@/core/errors/ReduxThunkError";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";
import { User } from "../../models/user.model";
import { RootState } from "../store";

interface UsersState {
  has500error: boolean;
  user: User;
  status: "loading" | "success" | "failed";
}

const initialState = {
  has500error: false,
  user: {},
  status: "loading",
} as UsersState;

export const getUser = createAsyncThunk(
  "users/getUser",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(`/user`);
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

export const usersSlice = createSlice({
  name: "usersSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUser.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.status = "success";
      state.user = action.payload;
    });
    builder.addCase(getUser.rejected, (state, action: PayloadAction<any>) => {
      state.status = "failed";
      if (action.payload.status === 500) {
        state.has500error = true;
      }
      Sentry.configureScope((scope) => {
        scope.setLevel("error");
        scope.setTransactionName("Error getting the client info");
        scope.setExtras(action.payload);
      });
      Sentry.captureException(new ReduxThunkError(action.payload));
      toast.error(action.payload.errorMessage);
    });
  },
});

export const selectUser = (state: RootState) => state.userState.user;
export const selectUserStatus = (state: RootState) => state.userState.status;
export const selectUserHas500error = (state: RootState) =>
  state.userState.has500error;

export const usersReducer = usersSlice.reducer;
