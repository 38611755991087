import { Flex } from "@gogeo-io/ui-library";
import { ReactNode } from "react";
import { Appbar } from "./Appbar";
import { Background } from "./Background";
import { Main } from "./Main";
import { Page } from "./Page";
import SidebarLeft from "./SidebarLeft";

export type SidebarLeftSize = "large" | "medium" | "small" | "none";
interface PageLayoutProps {
  leftSidebar: ReactNode;
  content: ReactNode;
  sidebarLeftSize?: SidebarLeftSize;
  zeroContentPadding?: boolean;
}

export function PageLayout({
  leftSidebar,
  content,
  sidebarLeftSize = "large",
  zeroContentPadding = false,
}: PageLayoutProps) {
  return (
    <Background>
      <Page>
        <Appbar />
        <Flex css={{ width: "100%", height: "100%" }}>
          <SidebarLeft sidebarLeftSize={sidebarLeftSize}>
            {leftSidebar}
          </SidebarLeft>
          <Main zeroContentPadding={zeroContentPadding}>{content}</Main>
        </Flex>
      </Page>
    </Background>
  );
}
