import { Breadcrumb } from "@/core/models/breadcrumb";
import { selectBreadcrumbs } from "@/core/store/breadcrumbs/breadcrumbsSlice";
import { Flex, Text } from "@gogeo-io/ui-library";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { BreadcrumbItem } from "./BreadcrumbItem";
import { LastBreadcrumbItem } from "./LastBreadcrumbItem";

export function BreadCrumbs() {
  const breadcrumbs = useSelector(selectBreadcrumbs);

  const lastBreadcrumb: Breadcrumb = breadcrumbs.slice(-1)[0];
  const allBreadcrumbsExceptLastOne: Breadcrumb[] = breadcrumbs.slice(0, -1);

  return (
    <Flex css={{ gap: "$1", flexWrap: "wrap", alignItems: "center" }}>
      {allBreadcrumbsExceptLastOne &&
        allBreadcrumbsExceptLastOne.map((breadcrumb) => {
          return (
            <BreadcrumbItem key={breadcrumb.item} breadcrumb={breadcrumb} />
          );
        })}
      {lastBreadcrumb && <LastBreadcrumbItem breadcrumb={lastBreadcrumb} />}
    </Flex>
  );
}

export const BreadcrumbText = styled(Text)`
  color: ${(props) => props.theme.colors.gray500};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const BreadcrumbItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.space[1]};

  svg {
    width: 20px;
    height: 20px;
    color: ${(props) => props.theme.colors.gray500};
  }
`;
