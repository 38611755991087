import { Breadcrumb } from "@/core/models/breadcrumb";
import { ListOfTags } from "@/core/models/listOfTags.model";
import { setBreadcrumbs } from "@/core/store/breadcrumbs/breadcrumbsSlice";
import useAppDispatch from "@/hooks/useAppDispatch";

export const useBreadcrumbsForClientsListPage = () => {
  const dispatch = useAppDispatch();

  const showBreadcrumbs = async (list: ListOfTags) => {
    const breadcrumbs: Breadcrumb[] = [
      { item: "Atendimento", url: "/attendance" },
      {
        item: list.name,
        url: `/attendance/${list.slug}`,
      },
      { item: "Editar Filtro", url: "" },
    ];

    await dispatch(setBreadcrumbs(breadcrumbs));
  };

  return {
    showBreadcrumbs,
  };
};
