import { AttrOpts } from "@/core/models/listOfTags.model";
import {
  removeAttributeToFilter,
  selectAttrOptsToFilter,
  selectClientAttrOpts,
  setAttributeToFilter,
} from "@/features/clientInfo/store/clientAttrOptsSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import {
  Checkbox,
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@gogeo-io/ui-library";
import { FilterAlt as FilterAltIcon } from "@mui/icons-material";
import { isEmpty, isNull, isUndefined } from "lodash";
import { useSelector } from "react-redux";
import { useAttendanceMixpanel } from "../hooks/useAttendanceMixpanel";
import { emitEventToChangeClients } from "../store/emitEventToChangeClientsSlice";
import { selectSelectedList } from "../store/listSelectedSlice";

export function SelectAttrOptsPopover() {
  const client_attributes = useSelector(selectClientAttrOpts);
  const attributes_to_filter = useSelector(selectAttrOptsToFilter);
  const selected_list = useSelector(selectSelectedList);

  const { attributeAddedEvent, attributeRemovedEvent } =
    useAttendanceMixpanel();
  const dispatch = useAppDispatch();

  const attributesToFilterHasAttribute = (attribute: AttrOpts): boolean => {
    return (
      attributes_to_filter.filter(
        (a) =>
          !isUndefined(a) && !isNull(a) && !isEmpty(a) && a.id === attribute.id
      ).length > 0
    );
  };

  const handleCheckboxChange = async (value: boolean, attribute: AttrOpts) => {
    await dispatch(emitEventToChangeClients(false));

    if (value) {
      await dispatch(setAttributeToFilter(attribute));
      attributeAddedEvent(attribute, selected_list);
    } else {
      await dispatch(removeAttributeToFilter(attribute));
      attributeRemovedEvent(attribute, selected_list);
    }

    await dispatch(emitEventToChangeClients(true));
  };

  const cannotShowMoreColumns = (attribute: AttrOpts) =>
    attributes_to_filter.length === 5 &&
    !attributesToFilterHasAttribute(attribute);

  return (
    <Popover>
      <PopoverTrigger>
        <Flex css={{ padding: "$1", color: "$gray600", cursor: "pointer" }}>
          <FilterAltIcon />
        </Flex>
      </PopoverTrigger>
      <PopoverContent align="center" css={{ padding: "0" }}>
        <Flex
          css={{
            flexDirection: "column",
            gap: "0.5rem",
            maxHeight: "60vh",
            overflow: "scroll",
            padding: "1.25rem",
          }}
        >
          {client_attributes
            .filter(
              (attribute) =>
                attribute.id !== "attendance_situation.status_today"
            )
            .map((attribute) => {
              return (
                <Flex key={attribute.id} css={{ gap: "0.5rem" }}>
                  {cannotShowMoreColumns(attribute) ? (
                    <Tooltip>
                      <TooltipTrigger style={{ background: "transparent" }}>
                        <Checkbox disabled />
                      </TooltipTrigger>
                      <TooltipContent aria-label="Você pode escolher no máximo 2 colunas">
                        Você pode escolher no máximo 5 colunas, remove uma pelo
                        menos para selecionar outra(s).
                      </TooltipContent>
                    </Tooltip>
                  ) : (
                    <Checkbox
                      checked={attributesToFilterHasAttribute(attribute)}
                      onCheckedChange={(e) =>
                        handleCheckboxChange(Boolean(e), attribute)
                      }
                    />
                  )}

                  <Text>{attribute.name}</Text>
                </Flex>
              );
            })}
        </Flex>
      </PopoverContent>
    </Popover>
  );
}
