import { SelectOption } from "@/core/models/advanced-filters.model";
import {
  Button,
  Flex,
  Select,
  SelectItem,
  SelectItemText,
} from "@gogeo-io/ui-library";

interface SelectGenericOptionProps {
  optionSelected: SelectOption;
  setOptionSelected: (option: SelectOption) => void;
  options: SelectOption[];
  onApplyFilter: () => void;
}

export function SelectGenericOption({
  optionSelected,
  setOptionSelected,
  options,
  onApplyFilter,
}: SelectGenericOptionProps) {
  return (
    <Flex>
      <Select
        value={optionSelected.value}
        defaultValue={optionSelected.value}
        ariaLabel="Select option"
        onValueChange={(selectedOption) =>
          setOptionSelected({
            id: options.find((opt) => opt.value === selectedOption).id,
            value: options.find((opt) => opt.value === selectedOption).value,
          })
        }
      >
        {options.map((option) => {
          return (
            <SelectItem value={option.value} key={option.id}>
              <SelectItemText>{option.value}</SelectItemText>
            </SelectItem>
          );
        })}
      </Select>

      <Flex css={{ alignItems: "flex-end", justifyContent: "flex-end" }}>
        <Button onClick={onApplyFilter}>Aplicar</Button>
      </Flex>
    </Flex>
  );
}
