import { User } from "@/core/models/user.model";
import { useMask } from "@/hooks/useMask";

export const useGeneralFields = (user: User) => {
  const { usePhoneMask } = useMask();

  const fieldsToTranslate = [
    {
      user_field: "Meu nome",
      translated_field: user.name,
    },
    {
      user_field: "Meu email",
      translated_field: user.email,
    },
    {
      user_field: "Meu ID",
      translated_field: user.id,
    },
    {
      user_field: "Empresa que trabalho",
      translated_field: user.account_name,
    },
    {
      user_field: "Meu telefone",
      translated_field: usePhoneMask(user.phone),
    },
  ];

  return fieldsToTranslate;
};
