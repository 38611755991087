import { CellHeaderWithoutSort } from "@/components/CustomCells/CellHeaderWithoutSort";
import { ClientProfileWithExclusionStatus } from "@/components/CustomCells/ClientProfileWithExclusionStatus";
import { CurrencyCell } from "@/components/CustomCells/CurrencyCell";
import { DateCell } from "@/components/CustomCells/DateCell";
import { SelectClientToChangeParams } from "@/components/CustomCells/SelectClientToChangeParams";
import { SortableHeader } from "@/components/CustomCells/SortableHeader";
import { TextCell } from "@/components/CustomCells/TextCell";
import { ShowPreferedDays } from "@/components/PreferedDays/ShowPreferedDays";
import { PreferedWeeks } from "@/components/PreferedWeeks";
import { Client, ClientParamsResponse } from "@/core/models/client.model";
import { AttrOpts } from "@/core/models/listOfTags.model";
import { Params } from "@/core/models/params.model";
import { selectSelectedListToEdit } from "@/features/attendance/store/listSelectedSlice";
import { selectAttrOptsToFilter } from "@/features/clientInfo/store/clientAttrOptsSlice";
import { selectColumnsToSort } from "@/features/clientInfo/store/clientColumnsToSortSlice";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { ParamsSelectAllClientsCheckbox } from "../components/ParamsSelectAllClientsCheckbox";

export const useParamsColumns = (
  onSort: (direction: "ASC" | "DESC" | "", property: string) => void
) => {
  const selectedListToEdit = useSelector(selectSelectedListToEdit);
  const selectedAttributesToFilter = useSelector(selectAttrOptsToFilter);
  const selectedColumnsToSort = useSelector(selectColumnsToSort);

  const getSortOrder = (column: AttrOpts) => {
    const findedColumn = selectedListToEdit.filters?.sort?.orders?.find(
      (order) => order.property === column.id
    );

    return findedColumn !== undefined ? findedColumn.direction : "";
  };

  const selectedColumns = useMemo(() => {
    return selectedAttributesToFilter.map((column) => {
      return {
        title: (
          <SortableHeader
            headerName={column.name}
            property={column.id}
            onSort={onSort}
            initialSortOrder={getSortOrder(column)}
          />
        ),
        dataIndex: "client",
        key: `client.${column.id}`,
        render: (record: Client) => {
          const accessor = column.id;
          const value = accessor.split(".").reduce((a, b) => a[b], record);

          if (column.format === "date") {
            return <DateCell value={value as any} />;
          } else if (column.format === "currency") {
            return <CurrencyCell value={value as any} />;
          } else {
            return <TextCell value={value as any} />;
          }
        },
      };
    });
  }, [selectedListToEdit, selectedAttributesToFilter, selectedColumnsToSort]);

  const selectClientColumn = useMemo(() => {
    return [
      {
        title: () => <ParamsSelectAllClientsCheckbox />,
        accessor: "params",
        key: "params",
        render: (record: ClientParamsResponse) => (
          <SelectClientToChangeParams client={record} />
        ),
      },
    ];
  }, [selectedListToEdit, selectedColumnsToSort]);

  const clientProfileColumn = useMemo(() => {
    return [
      {
        title: (
          <SortableHeader
            headerName="Cliente"
            property="name"
            onSort={onSort}
            initialSortOrder={getSortOrder({
              attr: "client",
              name: "Razão Social",
              id: "name",
              format: "text",
            } as AttrOpts)}
          />
        ),
        dataIndex: "client",
        key: "client",
        render: (record: Client) => (
          <ClientProfileWithExclusionStatus client={record} />
        ),
      },
    ];
  }, [selectedListToEdit, selectedColumnsToSort]);

  const weeksPrefColumn = useMemo(() => {
    return [
      {
        title: <CellHeaderWithoutSort headerName="Semanas" />,
        dataIndex: "params",
        key: "weeks",
        render: (record: Params) => (
          <PreferedWeeks isBlocked={false} params={record} />
        ),
      },
    ];
  }, [selectedListToEdit, selectedColumnsToSort]);

  const preferedDaysColumns = useMemo(() => {
    return [
      {
        title: <CellHeaderWithoutSort headerName="Dias Preferidos" />,
        dataIndex: "params",
        key: "preferedDays",
        render: (record: Params) => (
          <ShowPreferedDays
            isBlocked
            daysWeekPrefReceived={record.days_week_pref}
          />
        ),
      },
    ];
  }, [selectedListToEdit, selectedColumnsToSort]);

  return [
    ...selectClientColumn,
    ...clientProfileColumn,
    ...selectedColumns,
    ...weeksPrefColumn,
    ...preferedDaysColumns,
  ];
};
