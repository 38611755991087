import { Flex } from "@gogeo-io/ui-library";
import RLSSkeleton from "react-loading-skeleton";

export function AdvancedFiltersSkeleton() {
  return (
    <Flex css={{ flexDirection: "column", gap: "$2" }}>
      <Flex css={{ flexDirection: "column", gap: "$2" }}>
        <RLSSkeleton width={332} height={23} />
        <RLSSkeleton width={420} height={47} />
      </Flex>

      <Flex css={{ flexDirection: "column", gap: "$2" }}>
        <RLSSkeleton width={332} height={23} />
        <RLSSkeleton width={420} height={47} />
      </Flex>

      <Flex css={{ flexDirection: "column", gap: "$2" }}>
        <RLSSkeleton width={332} height={23} />
        <RLSSkeleton width={420} height={47} />
      </Flex>

      <Flex css={{ flexDirection: "column", gap: "$2" }}>
        <RLSSkeleton width={332} height={23} />
        <RLSSkeleton width={420} height={47} />
      </Flex>
    </Flex>
  );
}
