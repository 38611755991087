import { MatchTypes, SearchFilters } from "@/core/models/listOfTags.model";
import { selectMatchTypes } from "@/features/clientInfo/store/matchTypesSlice";
import { useClientsMixpanel } from "@/features/clients/hooks/useClientsMixpanel";
import useAppDispatch from "@/hooks/useAppDispatch";
import { useByPlaceholder } from "@/hooks/useByPlaceholder";
import {
  Flex,
  Input,
  Select,
  SelectItem,
  SelectItemText,
} from "@gogeo-io/ui-library";
import { debounce } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ClientsTypeOfView } from "../pages/ClientsListPage";
import { emitEventToChangeClients } from "../store/emitEventToChangeClientsSlice";
import {
  selectSelectedSearchFilters,
  setSelectedSearchFilters,
} from "../store/selectedSearchFiltersSlice";
import { SelectAttrOptsPopover } from "./SelectAttrOptsPopover";
import { SelectClientsTypeOfView } from "./SelectClientsTypeOfView";
import { SelectFunnel } from "./SelectFunnel";
import { SelectedTagsBasedOnSelectedFilters } from "./SelectedTagsBasedOnSelectedFilters";

interface SearchClientsOnClientsListPageProps {
  clientsTypeOfView: ClientsTypeOfView;
  onClientsTypeOfViewChange: (option: ClientsTypeOfView) => void;
}

export function SearchClientsOnClientsListPage({
  clientsTypeOfView,
  onClientsTypeOfViewChange,
}: SearchClientsOnClientsListPageProps) {
  const searchInputRef = useRef<HTMLInputElement>(null);

  const selectedSearchFilters = useSelector(selectSelectedSearchFilters);

  const [filterOptionSelected, setFilterOptionSelected] = useState<MatchTypes>(
    selectedSearchFilters.match_type
  );

  const [searchTerm, setSearchTerm] = useState(
    selectedSearchFilters.match_value
  );

  const match_types = useSelector(selectMatchTypes);

  const { buildPlaceholder } = useByPlaceholder();
  const { searchClientEvent } = useClientsMixpanel();
  const dispatch = useAppDispatch();

  const handleSelectFilterOptionChange = async (value: MatchTypes) => {
    await dispatch(emitEventToChangeClients(false));
    setFilterOptionSelected(value);

    const searchFilters: SearchFilters = {
      match_type: value,
      match_value: searchTerm,
    };

    if (searchTerm !== "") {
      await dispatch(setSelectedSearchFilters(searchFilters));
      await dispatch(emitEventToChangeClients(true));
    }
  };

  const handleInputChange = async (value: string) => {
    await dispatch(emitEventToChangeClients(false));
    setSearchTerm(value);

    const searchFilters: SearchFilters = {
      match_type: filterOptionSelected,
      match_value: value,
    };

    await dispatch(setSelectedSearchFilters(searchFilters));
    await dispatch(emitEventToChangeClients(true));

    searchClientEvent(value, filterOptionSelected);
  };

  useEffect(() => {
    searchInputRef.current.value = selectedSearchFilters.match_value;
    setSearchTerm(selectedSearchFilters.match_value);
  }, [selectedSearchFilters]);

  const debounceOnChangeInput = debounce(handleInputChange, 500);

  return (
    <Flex css={{ flexDirection: "column", gap: "$2" }}>
      <Flex css={{ padding: "$1 0", alignItems: "center", gap: "$2" }}>
        <SelectClientsTypeOfView
          clientsTypeOfView={clientsTypeOfView}
          onClientsTypeOfViewChange={onClientsTypeOfViewChange}
        />

        {clientsTypeOfView === "kanban" && <SelectFunnel />}
      </Flex>
      <Flex css={{ alignItems: "center" }}>
        <Flex css={{ gap: "$4", alignItems: "center" }}>
          <Input
            ref={searchInputRef}
            placeholder={buildPlaceholder(filterOptionSelected)}
            style={{ width: "240px" }}
            defaultValue={searchTerm}
            onChange={(e) => debounceOnChangeInput(e.target.value)}
          />
          <Select
            defaultValue={filterOptionSelected}
            ariaLabel="Select filter"
            onValueChange={(e) =>
              handleSelectFilterOptionChange(e as MatchTypes)
            }
          >
            {match_types.map((match) => {
              return (
                <SelectItem key={match} value={match}>
                  <SelectItemText>{match}</SelectItemText>
                </SelectItem>
              );
            })}
          </Select>

          {clientsTypeOfView !== "kanban" && <SelectAttrOptsPopover />}

          <SelectedTagsBasedOnSelectedFilters />
        </Flex>
      </Flex>
    </Flex>
  );
}
