import { ClientProfile } from "@/components/CustomCells/ClientProfile";
import { Client } from "@/core/models/client.model";
import { useAttendanceMixpanel } from "@/features/attendance/hooks/useAttendanceMixpanel";
import { selectSelectedList } from "@/features/attendance/store/listSelectedSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

interface ClientItemOnKanbanProps {
  client: Client;
}

export function ClientItemOnKanban({ client }: ClientItemOnKanbanProps) {
  const selectedList = useSelector(selectSelectedList);

  const navigate = useNavigate();
  const { clientClickedOnListEvent } = useAttendanceMixpanel();

  const handleNavigateToClient = () => {
    clientClickedOnListEvent(selectedList.name, client.id);
    navigate(`/info/client/${client.id}/client-info`);
  };

  return (
    <Container onClick={handleNavigateToClient}>
      <ClientProfile client={client} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.space[2]};
  border-radius: ${(props) => props.theme.space[1]};
  padding: ${(props) => props.theme.space[2]};
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.gray900};
  cursor: pointer;
`;
