import { Flex } from "@gogeo-io/ui-library";
import RLSSkeleton from "react-loading-skeleton";

interface HistoryPageSkeletonProps {
  count?: number;
}

export function HistoryFiltersSkeleton({
  count = 3,
}: HistoryPageSkeletonProps) {
  const timesToRepeatSkeletonRow = Array.from(Array(count).keys());

  return (
    <Flex css={{ flexWrap: "wrap", alignItems: "center", gap: "$2" }}>
      {timesToRepeatSkeletonRow.map((t) => {
        return <RLSSkeleton width={210} height={28} key={t} />;
      })}
    </Flex>
  );
}
