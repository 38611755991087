import { EmptyContent } from "@/components/EmptyContent";
import { AdvancedFilter } from "@/core/models/advanced-filters.model";
import {
  addSelectedAdvancedFilter,
  selectAvailableAdvancedFilters,
  selectAvailableAdvancedFiltersStatus,
  selectSelectedAdvancedFilters,
} from "@/features/attendance/store/clientAdvancedFiltersSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
  Flex,
  Heading,
  IconButton,
  Text,
} from "@gogeo-io/ui-library";
import { Close } from "@mui/icons-material";
import { useEffect, useMemo, useState } from "react";
import { MdAdd } from "react-icons/md";
import { useSelector } from "react-redux";
import { AddNewAdvancedFiltersSkeleton } from "./AddNewAdvancedFiltersSkeleton";
import { FiltersThatTheUserCanSelect } from "./FiltersThatTheUserCanSelect";

interface AddNewAdvancedFilterToClientFilterProps {
  onClickAddNewFilter: () => void;
}

export function AddNewAdvancedFilterToClientFilter({
  onClickAddNewFilter,
}: AddNewAdvancedFilterToClientFilterProps) {
  const availableAdvancedFilters = useSelector(selectAvailableAdvancedFilters);
  const selectedAdvancedFilters = useSelector(selectSelectedAdvancedFilters);
  const availableAdvancedFiltersStatus = useSelector(
    selectAvailableAdvancedFiltersStatus
  );
  const [selectedClientFilterToAdd, setSelectedClientFilterToAdd] = useState<
    AdvancedFilter[]
  >([]);

  const dispatch = useAppDispatch();

  const handleSetSelectedClientFilter = async (advFilter: AdvancedFilter) => {
    setSelectedClientFilterToAdd((prevState) => [...prevState, advFilter]);
  };

  const handleRemoveSelectedClientFilter = async (
    filterToRemove: AdvancedFilter
  ) => {
    const filtersWithoutFilterToRemove = selectedClientFilterToAdd.filter(
      (filter) => filter.id !== filterToRemove.id
    );
    setSelectedClientFilterToAdd(filtersWithoutFilterToRemove);
  };

  const handleSetSelectedClientFilterToEmpty = () =>
    setSelectedClientFilterToAdd([]);

  const handleAddFilter = async () => {
    await dispatch(addSelectedAdvancedFilter(selectedClientFilterToAdd));
    handleSetSelectedClientFilterToEmpty();
  };

  const getFilterThatTheUserCanSelect = () => {
    const filters = availableAdvancedFilters.filter(
      (availableFilter) =>
        !selectedAdvancedFilters.some(
          (selectedFilter) => selectedFilter.id === availableFilter.id
        )
    );

    return filters;
  };

  useEffect(() => {
    if (selectedAdvancedFilters.length === 0) {
      handleSetSelectedClientFilterToEmpty();
    }
  }, [selectedAdvancedFilters]);

  const filtersThatTheUserCanSelect = useMemo(
    () => getFilterThatTheUserCanSelect(),
    [availableAdvancedFilters, selectedAdvancedFilters]
  );

  return (
    <Dialog>
      <DialogTrigger asChild onClick={() => onClickAddNewFilter()}>
        <Flex css={{ gap: "$3", alignItems: "center", cursor: "pointer" }}>
          <Flex
            css={{
              width: "42px",
              height: "42px",
              borderRadius: "21px",
              background: "$primary500",
              color: "$white",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "1.75rem",
            }}
          >
            <MdAdd color="inherit" />
          </Flex>
          <Text size="body2" css={{ color: "$gray900" }}>
            Adicionar Novo Filtro
          </Text>
        </Flex>
      </DialogTrigger>
      <DialogContent style={{ height: "80vh" }}>
        <DialogClose asChild={true}>
          <IconButton
            type="ghost"
            css={{ position: "absolute", top: "1rem", right: "1rem" }}
          >
            <Close />
          </IconButton>
        </DialogClose>
        <Flex
          css={{
            flexDirection: "column",
            gap: "$4",
            overflow: "hidden",
            height: "90%",
          }}
        >
          <Heading css={{ fontWeight: "bold" }}>Filtros Disponíveis</Heading>

          {availableAdvancedFiltersStatus === "success" ? (
            <Flex
              css={{
                overflowY: "scroll",
              }}
            >
              {availableAdvancedFilters.length === 0 ? (
                <EmptyContent size="small">
                  Não encontramos filtros disponíveis
                </EmptyContent>
              ) : (
                <FiltersThatTheUserCanSelect
                  filtersThatTheUserCanSelect={filtersThatTheUserCanSelect}
                  selectedFilterToAdd={selectedClientFilterToAdd}
                  onSetSelectedFilter={handleSetSelectedClientFilter}
                  onRemoveSelectedFilter={handleRemoveSelectedClientFilter}
                />
              )}
            </Flex>
          ) : (
            <AddNewAdvancedFiltersSkeleton count={5} />
          )}
        </Flex>

        <Flex
          css={{
            alignItems: "flex-end",
            justifyContent: "flex-end",
            height: "50px",
          }}
        >
          {filtersThatTheUserCanSelect.length > 0 && (
            <DialogClose asChild>
              <Button onClick={handleAddFilter}>Adicionar Filtro</Button>
            </DialogClose>
          )}
        </Flex>
      </DialogContent>
    </Dialog>
  );
}
