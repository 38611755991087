import { gvTheme } from "@gogeo-io/ui-library";
import { Settings } from "@mui/icons-material";
import styled from "styled-components";

export function FloatButtonToUpdateParams() {
  return (
    <FloatButtonContainer>
      <Settings />
    </FloatButtonContainer>
  );
}

export const FloatButtonContainer = styled.button`
  position: absolute;
  bottom: 20px;
  right: 25px;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 100;

  background: ${(props) => props.theme.colors.primary500};

  svg {
    color: ${(props) => props.theme.colors.white};
    font-size: 30px;
  }

  @media (max-width: ${gvTheme.mediaSizes.laptop}) {
    bottom: 10px;
    right: 40px;
  }
`;
