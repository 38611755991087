import { ReduxThunkError } from "@/core/errors/ReduxThunkError";
import { ListOfTagsFilter } from "@/core/models/listOfTags.model";
import { Params } from "@/core/models/params.model";
import { RootState } from "@/core/store/store";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";

interface ParamsState {
  params: Params;
  status: "loading" | "success" | "failed";
}

const initialState = {
  params: {},
  status: "loading",
} as ParamsState;

interface BodyProps {
  params: Params;
  client_ids?: string[];
  filters?: ListOfTagsFilter;
}
export const updateParams = createAsyncThunk(
  "params/updateParams",
  async (body: BodyProps, thunkAPI) => {
    try {
      const response = await axios.put("/clients/info", {
        ...body,
      });
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

export const paramsSlice = createSlice({
  name: "paramsSlice",
  initialState,
  reducers: {
    changeParams(state, action: PayloadAction<any>) {
      const propName = Object.keys(action.payload)[0];
      const value = action.payload[propName];

      if (value === "indeterminate") {
        delete state.params[propName];
      } else if (value === "cleanAllFilters") {
        state.params = {} as Params;
      } else if (value === "cleanFilter") {
        delete state.params[propName];
      } else if (propName === "days_week_pref") {
        Object.assign(state.params, { days_week_pref: value });
      } else {
        Object.assign(state.params, { [propName]: value });
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateParams.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(updateParams.fulfilled, (state, action) => {
      state.status = "success";
    });
    builder.addCase(
      updateParams.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = "failed";
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error updating the params of a client");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
  },
});

export const selectParams = (state: RootState) => state.paramsState.params;
export const selectParamsStatus = (state: RootState) =>
  state.paramsState.status;

export const { changeParams } = paramsSlice.actions;

export const paramsReducer = paramsSlice.reducer;
