import { FilterTrigger } from "@/components/advancedFilters/FilterTrigger";
import { AdvancedFilter } from "@/core/models/advanced-filters.model";
import { useTranslateDateFilter } from "@/hooks/useTranslateDateFilter";
import { useEffect, useState } from "react";

interface DateFilterTriggerProps {
  filter: AdvancedFilter;
  value: string;
  onRemoveFilter?: (filter: AdvancedFilter) => void;
  notShowCloseIcon?: boolean;
}

export function DateFilterTrigger({
  filter,
  value,
  onRemoveFilter,
  notShowCloseIcon,
}: DateFilterTriggerProps) {
  const [translatedDateFilter, setTranslatedDateFilter] = useState("");

  const translateDate = useTranslateDateFilter();

  useEffect(() => {
    setTranslatedDateFilter(translateDate(value));
  }, [value]);

  return (
    <FilterTrigger
      value={translatedDateFilter}
      filter={filter}
      onRemoveFilter={onRemoveFilter}
      notShowCloseIcon={notShowCloseIcon}
    />
  );
}
