import ClientAttendanceStatus from "@/components/ClientAttendanceStatus";
import { ClientProfile } from "@/components/CustomCells/ClientProfile";
import { CurrencyCell } from "@/components/CustomCells/CurrencyCell";
import { DateCell } from "@/components/CustomCells/DateCell";
import { SortableHeader } from "@/components/CustomCells/SortableHeader";
import { TextCell } from "@/components/CustomCells/TextCell";
import { Client } from "@/core/models/client.model";
import { AttrOpts } from "@/core/models/listOfTags.model";
import { selectSelectedList } from "@/features/attendance/store/listSelectedSlice";
import { selectAttrOptsToFilter } from "@/features/clientInfo/store/clientAttrOptsSlice";
import { selectColumnsToSort } from "@/features/clientInfo/store/clientColumnsToSortSlice";
import { useMemo } from "react";
import { useSelector } from "react-redux";

export const useClientsColumns = (
  onSort: (direction: "ASC" | "DESC" | "", property: string) => void
) => {
  const selectedList = useSelector(selectSelectedList);
  const selectedAttributesToFilter = useSelector(selectAttrOptsToFilter);
  const selectedColumnsToSort = useSelector(selectColumnsToSort);

  const getSortOrder = (column: AttrOpts) => {
    const findedColumn = selectedList.filters?.sort?.orders?.find(
      (order) => order.property === column.id
    );

    return findedColumn !== undefined ? findedColumn.direction : "";
  };

  const clientProfileColumn = useMemo(() => {
    return [
      {
        title: (
          <SortableHeader
            headerName="Cliente"
            property="name"
            onSort={onSort}
            initialSortOrder={getSortOrder({
              attr: "client",
              name: "Razão Social",
              id: "name",
              format: "text",
            } as AttrOpts)}
          />
        ),
        dataIndex: "client",
        key: "client",
        render: (record) => <ClientProfile client={record} />,
        width: "30%",
      },
    ];
  }, [selectedList, selectedColumnsToSort]);

  const attendanceSituationColumn = useMemo(() => {
    return [
      {
        title: (
          <SortableHeader
            headerName="Status"
            property="attendance_situation.status.description"
            onSort={onSort}
            initialSortOrder={getSortOrder({
              attr: "status_attendance",
              name: "Status",
              id: "attendance_situation.status.description",
              format: "text",
            } as AttrOpts)}
          />
        ),
        dataIndex: "client",
        key: "client.attendance_situation.status",
        render: (record: Client) => (
          <ClientAttendanceStatus status={record.attendance_situation.status} />
        ),
      },
    ];
  }, [selectedList, selectedColumnsToSort]);

  const selectedColumns = useMemo(() => {
    return selectedAttributesToFilter.map((column) => {
      return {
        title: (
          <SortableHeader
            headerName={column.name}
            property={column.id}
            onSort={onSort}
            initialSortOrder={getSortOrder(column)}
          />
        ),
        dataIndex: "client",
        key: `client.${column.id}`,
        render: (record: Client) => {
          const accessor = column.id;
          const value = accessor.split(".").reduce((a, b) => a[b], record);

          if (column.format === "date") {
            return <DateCell value={value as any} />;
          } else if (column.format === "currency") {
            return <CurrencyCell value={value as any} />;
          } else {
            return <TextCell value={value as any} />;
          }
        },
      };
    });
  }, [selectedList, selectedAttributesToFilter, selectedColumnsToSort]);

  return [
    ...clientProfileColumn,
    ...selectedColumns,
    ...attendanceSituationColumn,
  ];
};
