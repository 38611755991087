import { Logout } from "@/components/Logout";
import { NotASalesmanLogged } from "@/components/NotASalesmanLogged";
import { Page404 } from "@/components/Page404";
import { Page500 } from "@/components/Page500";
import { UnderConstruction } from "@/components/UnderConstruction";
import { UserIsNotInWorkingTime } from "@/components/UserIsNotInWorkingTime";
import { AttendancePage, RedirectFiltersPage } from "@/features/attendance";
import { ClientsListPage } from "@/features/attendance/pages/ClientsListPage";
import { ClientProfilePage } from "@/features/clientInfo";
import { ClientInfoTabs } from "@/features/clientInfo/components/ClientInfoTabs";
import { ClientInfoPage } from "@/features/clientInfo/pages/ClientInfoPage";
import { HistoryPage } from "@/features/history";
import { ParamsPage } from "@/features/params";
import { ParamsClientsPage } from "@/features/params/pages/ParamsClientsPage";
import { ProductsSuggestion } from "@/features/productsSuggestion";
import { UserProfilePage } from "@/features/profile";
import { ClientNoteCreate } from "@/features/profile/components/ClientNoteCreate";
import { ClientNoteListContainer } from "@/features/profile/components/ClientNoteListContainer";
import { ClientNoteUpdateContainer } from "@/features/profile/components/ClientNoteUpdateContainer";
import { ClientNotes } from "@/features/profile/components/ClientNotes";
import { ClientNotesPage } from "@/features/profile/pages/ClientNotesPage";
import { PreDefinedMessagesPage } from "@/features/profile/pages/PreDefinedMessagesPage";
import { UsersPreferenceTabs } from "@/features/profile/pages/UsersPreferenceTabs";
import { ReportsPage, SpecificReport } from "@/features/reports";
import { AnimatePresence } from "framer-motion";

import { Navigate, Route, Routes } from "react-router-dom";

export const RoutesProvider: React.FC = () => {
  return (
    <AnimatePresence
      exitBeforeEnter
      initial={true}
      onExitComplete={() => {
        if (typeof window !== "undefined") {
          window.scrollTo({ top: 0 });
        }
      }}
    >
      <Routes>
        <Route index element={<Navigate to="attendance" />} />

        <Route path="/attendance" element={<AttendancePage />}>
          <Route path=":list_slug" element={<ClientsListPage />} />

          <Route
            path=":list_slug/edit-list/filters"
            element={<ClientsListPage />}
          />
          <Route path="list/search/filters" element={<ClientsListPage />} />
          <Route
            path="list/shared-with-me/filters"
            element={<ClientsListPage />}
          />
          <Route path="filters" element={<RedirectFiltersPage />} />
        </Route>

        <Route path="/info" element={<ClientProfilePage />}>
          <Route
            path="client/:client_id"
            element={<Navigate to="client-info" />}
          />
          <Route
            path="client/:client_id/client-info"
            element={
              <ClientInfoTabs route="client-info">
                <ClientInfoPage />
              </ClientInfoTabs>
            }
          />
          <Route
            path="client/:client_id/history"
            element={
              <ClientInfoTabs route="history">
                <HistoryPage />
              </ClientInfoTabs>
            }
          />
          <Route
            path="client/:client_id/products-suggestion"
            element={
              <ClientInfoTabs route="products-suggestion">
                <ProductsSuggestion />
              </ClientInfoTabs>
            }
          />
          <Route
            path="client/:client_id/notes"
            element={
              <ClientInfoTabs route={"notes"}>
                <ClientNotesPage />
              </ClientInfoTabs>
            }
          >
            <Route path="" element={<ClientNotes />} />
            <Route path="create" element={<ClientNoteCreate />} />
            <Route path="list/:note_id" element={<ClientNoteListContainer />} />
            <Route
              path="edit/:note_id"
              element={<ClientNoteUpdateContainer />}
            />
          </Route>
        </Route>

        <Route path="/params" element={<ParamsPage />}>
          <Route path="clients" element={<ParamsClientsPage />} />
        </Route>

        <Route path="/reports" element={<ReportsPage />}>
          <Route path=":dashboard_name" element={<SpecificReport />} />
        </Route>

        <Route path="/profile" element={<UserProfilePage />}>
          <Route
            path="pre-defined-messages"
            element={
              <UsersPreferenceTabs>
                <PreDefinedMessagesPage />
              </UsersPreferenceTabs>
            }
          />
          <Route
            path="business-goals"
            element={
              <UsersPreferenceTabs>
                {/* <BusinessGoalsPage /> */}
                <UnderConstruction />
              </UsersPreferenceTabs>
            }
          />
          <Route
            path="attendance-justifications"
            element={
              <UsersPreferenceTabs>
                {/* <AttendanceJustificationsPage /> */}
                <UnderConstruction />
              </UsersPreferenceTabs>
            }
          />
          <Route
            path="change-password"
            element={
              <UsersPreferenceTabs>
                <UnderConstruction />
              </UsersPreferenceTabs>
            }
          />
        </Route>

        <Route path="/logout" element={<Logout />} />

        <Route path="/internal-server-error" element={<Page500 />} />
        <Route path="/not-a-salesman" element={<NotASalesmanLogged />} />
        <Route
          path="/user-is-not-in-working-time"
          element={<UserIsNotInWorkingTime />}
        />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </AnimatePresence>
  );
};
