import { selectUser } from "@/core/store/users/usersSlice";
import { selectSelectedList } from "@/features/attendance/store/listSelectedSlice";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Heading,
  IconButton,
  Text,
} from "@gogeo-io/ui-library";
import { isEmpty, isUndefined } from "lodash";
import { BiChevronLeft } from "react-icons/bi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

interface ClientAvatarProps {
  client: any;
  showGoBackButton?: boolean;
}

export function ClientProfileAvatar({
  client,
  showGoBackButton = true,
}: ClientAvatarProps) {
  const navigate = useNavigate();

  const selectedList = useSelector(selectSelectedList);
  const selectedUser = useSelector(selectUser);

  const handleNavigate = () => {
    navigate(
      `/attendance/${
        !isEmpty(selectedList.slug) && !isUndefined(selectedList.slug)
          ? selectedList.slug
          : `${selectedUser.account_id}_${selectedUser.id}_meus_clientes`
      }`
    );
  };

  return (
    <Info>
      {showGoBackButton && (
        <IconButton size="small" type="ghost" bordered onClick={handleNavigate}>
          <BiChevronLeft />
        </IconButton>
      )}

      {!isUndefined(client.client_photo) && !isEmpty(client.client_photo) ? (
        <Avatar size="medium">
          {client.client_photo.photo_sm_url ? (
            <AvatarImage src={client.client_photo.photo_sm_url} />
          ) : (
            <AvatarFallback>{client.client_photo.fallback}</AvatarFallback>
          )}
        </Avatar>
      ) : (
        <Avatar size="medium">
          <AvatarFallback>GO</AvatarFallback>
        </Avatar>
      )}

      <div className="infoContainer">
        <Heading
          size="subtitle1"
          css={{ color: "$gray900", textTransform: "capitalize" }}
        >
          {client.name.toLowerCase()}
        </Heading>
        <Text css={{ color: "$gray500", textTransform: "capitalize" }}>
          {client.fantasy_name}
        </Text>
      </div>
    </Info>
  );
}

const Info = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.space[1]};

  .infoContainer {
    display: flex;
    flex-direction: column;
    padding-left: ${(props) => props.theme.space[1]};
  }
`;
