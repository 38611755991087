import { TagCloudFeedbackMapping } from "@/features/attendance/components/feedback/TagCloudFeedbackMapping";
import { Grid } from "@gogeo-io/ui-library";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";
import {
  selectFeedbackMapping,
  selectFeedbackMappingStatus,
} from "../../store/feedbackMappingSlice";
import { Skeleton } from "./FeedbackMappingSkeleton";

interface FeedbackMappingProps {
  attendanceStatusSelected: string;
  onChange: (...event: any[]) => void;
  value: number;
}

export function FeedbackMapping({
  attendanceStatusSelected,
  onChange,
  value,
}: FeedbackMappingProps) {
  const feedbackMapping = useSelector(selectFeedbackMapping);
  const status = useSelector(selectFeedbackMappingStatus);

  const feedbackMappingFiltered = feedbackMapping.filter((feedback) => {
    const { feedback_id } = feedback;

    if (attendanceStatusSelected === "Vendi") {
      return feedback_id >= 100 && feedback_id < 200;
    } else if (attendanceStatusSelected === "Não Vendi") {
      return feedback_id >= 200;
    } else if (attendanceStatusSelected === "Em andamento") {
      return feedback_id >= 10 && feedback_id < 100;
    } else {
      return feedback;
    }
  });

  const handleClickChangeFeedbackStatus = async (feedback_id: number) => {
    onChange(feedback_id);
  };

  return (
    <Grid
      css={{
        gap: "$2",
        alignItems: "center",
        flexWrap: "wrap",
        overflowY: "auto",
      }}
    >
      {status !== "success" ? (
        <Skeleton />
      ) : (
        <TagCloudFeedbackMapping
          feedbackMapping={feedbackMappingFiltered}
          feedbackSelected={value}
          onFeedbackSelected={(item) => handleClickChangeFeedbackStatus(item)}
        />
      )}
    </Grid>
  );
}

export const CustomChip = styled.button<{
  isSelected: boolean;
}>`
  border-radius: 60px;
  color: ${({ theme }) => theme.colors.gray400};
  background: transparent;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.space[2]};
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  text-align: center;

  ${(props) =>
    props.isSelected &&
    css`
      background: ${({ theme }) => theme.colors.primary100};
      color: ${({ theme }) => theme.colors.primary500};
    `}
`;
