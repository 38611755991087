import { useByFeedback } from "@/hooks/useByFeedback";
import UI from "../ui";

interface TimelineVerticalMarkProps {
  feedback_id: number;
}

export function TimelineVerticalMark({
  feedback_id,
}: TimelineVerticalMarkProps) {
  const { getColorByFeedback } = useByFeedback();

  return (
    <>
      <UI.TimelineDot colorByFeedback={getColorByFeedback(feedback_id)} />

      <UI.TimelineSeparator />
    </>
  );
}
