import { Flex } from "@gogeo-io/ui-library";
import { useSelector } from "react-redux";
import { selectReminders } from "../store/reminderSlice";
import UI from "../ui";
import { ReminderItem } from "./ReminderItem";

export function ReminderList() {
  const reminders = useSelector(selectReminders);

  return (
    <Flex css={{ width: "100%", height: "100%", paddingTop: "20px" }}>
      <UI.ReminderList>
        {[...reminders]
          .sort((a, b) => Number(a.is_read) - Number(b.is_read))
          .map((reminder) => {
            return (
              <ReminderItem
                key={reminder.id}
                id={reminder.id}
                date={reminder.date}
                isRead={reminder.is_read}
                text={reminder.text}
                client_id={reminder.client_id}
              />
            );
          })}
      </UI.ReminderList>
    </Flex>
  );
}
