import { ListDivider } from "@/components/ListDivider";
import { selectUser } from "@/core/store/users/usersSlice";
import { ProfileInfo } from "@/features/attendance/components/ProfileInfo";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Flex,
  Heading,
} from "@gogeo-io/ui-library";
import { useSelector } from "react-redux";
import { useTranslateRole } from "../hooks/useTranslateRole";
import UI from "../ui";
import { UserProfileInfoPhone } from "./UserProfilePhoneField";

export function UserProfile() {
  const user = useSelector(selectUser);

  const translateRole = useTranslateRole();

  return (
    <UI.UserProfile>
      <Flex
        css={{
          gap: "0.75rem",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Avatar size="large">
          {user.user_photo.photo_lg_url ? (
            <AvatarImage src={user.user_photo.photo_lg_url} />
          ) : (
            <AvatarFallback>{user.user_photo.fallback}</AvatarFallback>
          )}
        </Avatar>
        <Flex
          css={{
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "0.5rem",
          }}
        >
          <Heading size="subtitle2">{user.name}</Heading>

          <UI.UserRole>{translateRole(user.role)}</UI.UserRole>
        </Flex>
      </Flex>

      <ListDivider />

      <UI.UserInfoWrapper>
        <Heading size="subtitle1">Informações do Usuário</Heading>

        <ProfileInfo label="ID do usuário" value={user.salesman_id} />
        <ProfileInfo label="Nome" value={user.name} />
        <ProfileInfo
          label="Email"
          value={user.email}
          href={user.email}
          asOuterLink
        />
        <ProfileInfo label="Empresa" value={user.account_name} />
        <UserProfileInfoPhone label="Telefone" value={user.phone} />
      </UI.UserInfoWrapper>

      <ListDivider />

      <UI.UserInfoWrapper>
        <Heading size="subtitle1">Permissões</Heading>

        <ProfileInfo
          label="Notificações: "
          value={user.permissions.notification_enabled ? "SIM" : "NÃO"}
        />
        <ProfileInfo
          label="Acessar todos clientes da empresa:"
          value={user.permissions.access_all_clients ? "SIM" : "NÃO"}
        />
        <ProfileInfo
          label="Alterar parâmetros:"
          value={user.permissions.change_params ? "SIM" : "NÃO"}
        />
        <ProfileInfo
          label="Observações incrementais:"
          value={user.permissions.incremental_observations ? "SIM" : "NÃO"}
        />
      </UI.UserInfoWrapper>
    </UI.UserProfile>
  );
}
