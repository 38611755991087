import { gvTheme } from "@gogeo-io/ui-library";
import { ReactNode } from "react";
import styled from "styled-components";

interface PageProps {
  children: ReactNode;
}

export function Page({ children }: PageProps) {
  return <Container>{children}</Container>;
}

const Container = styled.div`
  transform: scale(1);
  position: absolute;
  margin: 0 auto;
  top: 10px;
  bottom: 10px;
  left: 10px;
  right: 10px;
  height: calc(100% - 20px);
  width: calc(100% - 20px);
  max-width: 1600px;
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0 17px 50px 0 rgba(11, 20, 26, 0.19),
    0 0 15px 0 rgba(11, 20, 26, 0.24);

  @media (max-width: ${gvTheme.mediaSizes.laptop}) {
    transform: scale(0.98);
  }

  @media (max-width: ${gvTheme.mediaSizes.smallLaptop}) {
    transform: scale(0.95);
  }

  @media (max-width: ${gvTheme.mediaSizes.tablet}) {
    transform: scale(0.93);
  }
`;
