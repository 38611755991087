import styled, { css } from "styled-components";

interface MainProps {
  children?: React.ReactNode;
  title?: string;
  isBackgroundGray?: boolean;
  zeroContentPadding?: boolean;
}

export function Main({
  children,
  isBackgroundGray = false,
  zeroContentPadding = false,
}: MainProps) {
  return (
    <Wrapper
      isBackgroundGray={isBackgroundGray}
      zeroContentPadding={zeroContentPadding}
    >
      {children}
    </Wrapper>
  );
}

interface WrapperProps {
  isBackgroundGray: boolean;
  zeroContentPadding: boolean;
}

const Wrapper = styled.main<WrapperProps>`
  height: 100%;
  width: 100%;
  padding: ${(props) => (!props.zeroContentPadding ? "1rem" : "0")};
  width: 100%;
  height: calc(100% - var(--header-height));
  overflow: auto;
  ${({ isBackgroundGray, theme }) =>
    isBackgroundGray
      ? css`
          background: ${theme.colors.gray50};
        `
      : css`
          background: ${theme.colors.white};
        `}
`;
