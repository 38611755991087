import { User } from "@/core/models/user.model";
import { differenceInMinutes, format, isWithinInterval } from "date-fns";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

interface CheckIfUserIsInWorkingTimeProps {
  user: User;
}

export const useCheckIfUserIsInWorkingTime = () => {
  const navigate = useNavigate();

  const checkIfUserIsInWorkingTime = ({
    user,
  }: CheckIfUserIsInWorkingTimeProps) => {
    const workingTime = user.meta_parameters.working_time;

    if (workingTime.length === 0 || workingTime === undefined) {
      return true;
    }

    /////////

    const getToday = format(new Date(), "E").toLowerCase();

    const workingTimeThatHasTheDayOfWork = workingTime.find((wt) =>
      wt.days.includes(getToday)
    );

    if (workingTimeThatHasTheDayOfWork === undefined) {
      return true;
    }

    /////////

    const periodTime = workingTimeThatHasTheDayOfWork.period_time;

    const workingTimeOfUser = periodTime.map((period) => {
      const start = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        Number(period.start.split(":")[0]),
        Number(period.start.split(":")[1])
      );

      const end = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        Number(period.end.split(":")[0]),
        Number(period.end.split(":")[1])
      );

      const userIsWithinWorkingTime = isWithinInterval(new Date(), {
        start,
        end,
      });

      const remainingTimeOfWork = differenceInMinutes(end, new Date());

      return {
        userIsWithinWorkingTime,
        remainingTimeOfWork,
      };
    });

    ////////////

    const userIsWithinAnyWorkingTime = workingTimeOfUser.find(
      (workingTime) => workingTime.userIsWithinWorkingTime
    );

    if (userIsWithinAnyWorkingTime === undefined) {
      return false;
    }

    ////////////

    const { remainingTimeOfWork } = userIsWithinAnyWorkingTime;

    const remainingTime = remainingTimeOfWork + 1;

    if (remainingTime <= 15) {
      toast.warning(
        `Faltam aproximadamente ${remainingTime} minutos para encerrar seu horário de trabalho, após esse período  você não poderá mais acessar a aplicação`
      );

      setInterval(() => {
        navigate("/user-is-not-in-working-time");
      }, 1000 * 60 * remainingTime + 5);
    }

    return true;
  };

  return { checkIfUserIsInWorkingTime };
};
