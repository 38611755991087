import { EmptyContent } from "@/components/EmptyContent";
import { TrackingEvents } from "@/config/mixpanel";
import { Breadcrumb } from "@/core/models/breadcrumb";
import {
  ProductListSugestionPaginated,
  ProductsFilter,
  ProductsListRecommendation,
} from "@/core/models/productsSugestion.model";
import { setBreadcrumbs } from "@/core/store/breadcrumbs/breadcrumbsSlice";
import { selectUser } from "@/core/store/users/usersSlice";
import { selectSelectedList } from "@/features/attendance/store/listSelectedSlice";
import { selectClient } from "@/features/clientInfo/store/clientSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { useFormatDate } from "@/hooks/useFormatDate";
import { useMixpanel } from "@/hooks/useMixpanel";
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  Flex,
  Heading,
  IconButton,
  Input,
  Spinner,
} from "@gogeo-io/ui-library";
import { Close, FilterList, Search } from "@mui/icons-material";
import G_UI from "@ui/index";
import { debounce, isEmpty, isUndefined } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ProductsFilterActions } from "../components/ProductsFilterActions";
import { ProductsFilterIsTrending } from "../components/ProductsFilterIsTrending";
import { ProductsFilterListType } from "../components/ProductsFilterListType";
import { ProductsFilterPrice } from "../components/ProductsFilterPrice";
import { ProductsFiltersSkeleton } from "../components/ProductsFiltersSkeleton";
import { ProductsList } from "../components/ProductsList";
import { ProductsSuggestionSkeleton } from "../components/ProductsSuggestionSkeleton";
import {
  getProductsListType,
  selectProductsListTypeStatus,
} from "../store/productsListTypeSlice";
import {
  addProductsOnState,
  getProductsSugestion,
  selectProductsStatus,
  selectProductsSugestion,
  selectProductsTotal,
  setProductsSuggestionToFulfilled,
  setTotalOfProducts,
} from "../store/productsSuggestionSlice";
import UI from "../ui";

export function ProductsSuggestion() {
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [productsFilters, setProductsFilters] = useState<ProductsFilter>(
    {} as ProductsFilter
  );

  const productsSuggestion = useSelector(selectProductsSugestion);
  const productsSuggestionStatus = useSelector(selectProductsStatus);
  const productsListTypeStatus = useSelector(selectProductsListTypeStatus);
  const productsSuggestionTotal = useSelector(selectProductsTotal);
  const selectedClient = useSelector(selectClient);
  const selectedList = useSelector(selectSelectedList);
  const selectedUser = useSelector(selectUser);

  const dispatch = useAppDispatch();
  const { pageViewed } = useMixpanel();
  const { formatBasicDate } = useFormatDate();

  const handleIncreasePageNumber = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handleSetFilters = (filters: ProductsFilter) => {
    setProductsFilters(filters);
  };

  const handleInputChange = async (value: string) => {
    setCurrentPage(0);
    setIsLoading(true);
    const filters = { ...productsFilters, product_name: value };
    setProductsFilters(filters);

    await dispatch(
      getProductsSugestion({
        client_id: selectedClient.id,
        visit_date: formatBasicDate(new Date()),
        page: 0,
        size: import.meta.env.VITE_PAGE_SIZE,
        filters: {
          ...filters,
        },
      })
    ).then(async (res) => {
      if (res.meta.requestStatus === "fulfilled") {
        const productsPaginated: ProductListSugestionPaginated = res.payload;
        const lists: ProductsListRecommendation[] = [
          ...productsPaginated.lists,
        ];

        await dispatch(addProductsOnState(lists));
        await dispatch(setTotalOfProducts(res.payload.total));
        await dispatch(setProductsSuggestionToFulfilled());
        setIsLoading(false);
      }
    });
  };

  const handleFilter = async () => {
    setCurrentPage(0);
    setIsLoading(true);

    await dispatch(
      getProductsSugestion({
        client_id: selectedClient.id,
        visit_date: formatBasicDate(new Date()),
        page: 0,
        size: import.meta.env.VITE_PAGE_SIZE,
        filters: {
          ...productsFilters,
        },
      })
    ).then(async (res) => {
      if (res.meta.requestStatus === "fulfilled") {
        const productsPaginated: ProductListSugestionPaginated = res.payload;
        const lists: ProductsListRecommendation[] = [
          ...productsPaginated.lists,
        ];

        await dispatch(addProductsOnState(lists));
        await dispatch(setTotalOfProducts(res.payload.total));
        await dispatch(setProductsSuggestionToFulfilled());
        setIsLoading(false);
      }
    });
  };

  const debounceOnChangeInput = debounce(handleInputChange, 500);

  const getPaginatedProducts = async (currentPage: number) => {
    await dispatch(
      getProductsSugestion({
        client_id: selectedClient.id,
        visit_date: formatBasicDate(new Date()),
        page: currentPage,
        size: import.meta.env.VITE_PAGE_SIZE,
        filters: {
          ...productsFilters,
        },
      })
    ).then(async (res) => {
      if (res.meta.requestStatus === "fulfilled") {
        const productsPaginated: ProductListSugestionPaginated = res.payload;
        if (!isEmpty(productsPaginated.lists)) {
          const lists: ProductsListRecommendation[] = [
            ...productsSuggestion,
            ...productsPaginated.lists,
          ];

          await dispatch(addProductsOnState(lists));
        }
      }
    });
  };

  useEffect(() => {
    if (currentPage !== 0) {
      if (productsSuggestion.length < productsSuggestionTotal) {
        getPaginatedProducts(currentPage);
      }
    }
  }, [currentPage]);

  useEffect(() => {
    const setTitle = async () => {
      const breadcrumbs: Breadcrumb[] = [
        { item: "Atendimento", url: "/attendance" },
        {
          item: `${
            !isEmpty(selectedList.name) && !isUndefined(selectedList.name)
              ? selectedList.name
              : "Meus clientes"
          }`,
          url: `/attendance/${
            !isEmpty(selectedList.slug) && !isUndefined(selectedList.slug)
              ? selectedList.slug
              : `${selectedUser.account_id}_${selectedUser.id}_meus_clientes`
          }`,
        },
        {
          item: selectedClient.name,
          url: `/info/client/${selectedClient.id}/client-info`,
        },
        { item: "Sugestão de produtos", url: `` },
      ];
      await dispatch(setBreadcrumbs(breadcrumbs));
    };

    setTitle();
  }, [selectedList, selectedClient]);

  useEffect(() => {
    pageViewed(TrackingEvents.PRODUCTS_SUGGESTION_PAGE);

    async function loadProducts() {
      await dispatch(
        getProductsSugestion({
          client_id: selectedClient.id,
          visit_date: formatBasicDate(new Date()),
          page: 0,
          size: import.meta.env.VITE_PAGE_SIZE,
          filters: {
            ...productsFilters,
          },
        })
      ).then(async (res) => {
        if (res.meta.requestStatus === "fulfilled") {
          const productsPaginated: ProductListSugestionPaginated = res.payload;
          const lists: ProductsListRecommendation[] = [
            ...productsPaginated.lists,
          ];

          await dispatch(addProductsOnState(lists));
          await dispatch(setTotalOfProducts(res.payload.total));
          await dispatch(setProductsSuggestionToFulfilled());
        }
      });
    }

    loadProducts();
  }, []);

  useEffect(() => {
    if (productsSuggestionStatus === "success") {
      const intersectionObserver = new IntersectionObserver((entries) => {
        if (entries.some((entry) => entry.isIntersecting)) {
          if (
            productsSuggestion !== undefined &&
            productsSuggestion[0] !== undefined &&
            productsSuggestion[0].recommendations !== undefined &&
            productsSuggestion[0].recommendations.length > 0 &&
            productsSuggestion[0].recommendations.length <
              productsSuggestionTotal
          ) {
            handleIncreasePageNumber();
          }
        }
      });

      intersectionObserver.observe(document.querySelector("#sentry"));

      return () => intersectionObserver.disconnect();
    }
  }, [productsSuggestionStatus]);

  return (
    <>
      <G_UI.ClientInfoFilterWrapper>
        <Input
          placeholder="Busque um produto"
          preffix={isLoading ? <Spinner /> : <Search fontSize="inherit" />}
          onChange={(e) => debounceOnChangeInput(e.target.value)}
        />

        <Dialog>
          <DialogTrigger asChild>
            <Button
              color="gray"
              onClick={async () => await dispatch(getProductsListType())}
            >
              Filtros
              <FilterList style={{ marginLeft: "5px" }} />
            </Button>
          </DialogTrigger>

          <DialogContent style={{ width: "600px" }}>
            {productsListTypeStatus === "success" ? (
              <>
                <DialogTitle css={{ marginTop: "1rem" }}>
                  Filtros - Sugestão de Produtos
                </DialogTitle>

                <Flex css={{ flexDirection: "column", gap: "$4", mt: "$4" }}>
                  <UI.ProductFilterInfoItem>
                    <Heading>Preço</Heading>
                    <ProductsFilterPrice
                      filters={productsFilters}
                      onFiltersChange={handleSetFilters}
                    />
                  </UI.ProductFilterInfoItem>

                  <UI.ProductFilterInfoItem>
                    <ProductsFilterIsTrending
                      filters={productsFilters}
                      onFiltersChange={handleSetFilters}
                    />
                  </UI.ProductFilterInfoItem>

                  <UI.ProductFilterInfoItem>
                    <Heading>Categoria do Produto</Heading>
                    <ProductsFilterListType
                      filters={productsFilters}
                      onFiltersChange={handleSetFilters}
                    />
                  </UI.ProductFilterInfoItem>
                </Flex>

                <ProductsFilterActions onFilter={handleFilter} />

                <DialogClose asChild>
                  <IconButton
                    type="ghost"
                    css={{ position: "absolute", top: "0.5rem", right: "1rem" }}
                  >
                    <Close />
                  </IconButton>
                </DialogClose>
              </>
            ) : (
              <ProductsFiltersSkeleton />
            )}
          </DialogContent>
        </Dialog>
      </G_UI.ClientInfoFilterWrapper>
      <G_UI.ClientInfoContent>
        {productsSuggestionStatus === "success" ? (
          <>
            {!isEmpty(productsSuggestion) ? (
              <ProductsList />
            ) : (
              <EmptyContent>
                Não temos uma sugestão de produtos disponível para você
              </EmptyContent>
            )}
          </>
        ) : (
          <ProductsSuggestionSkeleton timesToRepet={5} />
        )}
        {productsSuggestionStatus === "success" && (
          <div id="sentry">
            {productsSuggestion !== undefined &&
            productsSuggestion[0] !== undefined &&
            productsSuggestion[0].recommendations !== undefined &&
            productsSuggestion[0].recommendations.length > 0 &&
            productsSuggestion[0].recommendations.length <
              productsSuggestionTotal ? (
              <Flex css={{ mt: "$1" }}>
                <ProductsSuggestionSkeleton timesToRepet={1} />
              </Flex>
            ) : null}
          </div>
        )}
      </G_UI.ClientInfoContent>
    </>
  );
}
