import OuterLink from "@/components/OuterLink";
import { Caption, Text } from "@gogeo-io/ui-library";

interface ClientInfoProps {
  label: string;
  value: string;
  asOuterLink?: boolean;
  href?: string;
}

export function ProfileInfo({
  label,
  value,
  href,
  asOuterLink,
}: ClientInfoProps) {
  return (
    <div style={{ display: "block" }}>
      <Caption style={{ display: "block" }}>{label}</Caption>
      {value ? (
        <>
          {asOuterLink ? (
            <OuterLink href={href}>{value}</OuterLink>
          ) : (
            <Text size="body2" css={{ textTransform: "capitalize" }}>
              {value.toLowerCase()}
            </Text>
          )}
        </>
      ) : (
        <Text size="body2" css={{ textTransform: "capitalize" }}>
          Sem Telefone
        </Text>
      )}
    </div>
  );
}
