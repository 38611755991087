import { ListDivider } from "@/components/ListDivider";
import { Flex } from "@gogeo-io/ui-library";
import RLSSkeleton from "react-loading-skeleton";

export function CreatePreDefinedMessageSkeleton() {
  return (
    <Flex css={{ gap: "1.5rem", flexDirection: "column" }}>
      <RLSSkeleton width={260} height={27} />

      <ListDivider isSkeleton />

      <Flex css={{ gap: "1.5rem", padding: "1rem 0" }}>
        <Flex css={{ flexDirection: "column", gap: "$2", width: "200px" }}>
          <RLSSkeleton width={150} height={20} />
          <RLSSkeleton width={210} height={37} />
        </Flex>

        <Flex css={{ flexDirection: "column", gap: "$2" }}>
          <Flex
            css={{
              alignItems: "center",
              justifyContent: "space-between",
              gap: "$2",
            }}
          >
            <RLSSkeleton width={82} height={30} />
            <RLSSkeleton width={89} height={24} />
          </Flex>
          <RLSSkeleton width={350} height={120} />
        </Flex>
      </Flex>

      <ListDivider isSkeleton />

      <Flex
        css={{
          alignItems: "flex-end",
          justifyContent: "flex-end",
          marginTop: "15px",
        }}
      >
        <RLSSkeleton width={142} height={32} />
      </Flex>
    </Flex>
  );
}
