import { selectUserStatus } from "@/core/store/users/usersSlice";
import { ClientsPageSkeleton } from "@/features/clientInfo/components/ClientsPageSkeleton";
import { PageLayout } from "@/layouts/PageLayout";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { UserProfile } from "../components/UserProfile";

export function UserProfilePage() {
  const userStatus = useSelector(selectUserStatus);

  return (
    <PageLayout
      leftSidebar={
        userStatus !== "success" ? <ClientsPageSkeleton /> : <UserProfile />
      }
      zeroContentPadding
      content={<Outlet />}
    />
  );
}
