import { Flex } from "@gogeo-io/ui-library";
import RLSSkeleton from "react-loading-skeleton";
import styled from "styled-components";

interface AddNewAdvancedFiltersSkeletonProps {
  count?: number;
}

export function AddNewAdvancedFiltersSkeleton({
  count = 3,
}: AddNewAdvancedFiltersSkeletonProps) {
  const timesToRepeat = Array.from(Array(count).keys());

  return (
    <>
      {timesToRepeat.map((c, idx) => {
        return (
          <Flex css={{ flexDirection: "column", gap: "20px" }} key={c}>
            <RLSSkeleton height={50} />
          </Flex>
        );
      })}
    </>
  );
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 5px;
  gap: ${(props) => props.theme.space[2]};
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.space[2]};
`;
