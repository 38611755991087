import { AdvancedFilter } from "@/core/models/advanced-filters.model";
import { Caption, Flex } from "@gogeo-io/ui-library";
import { Close as CloseIcon } from "@mui/icons-material";
import styled from "styled-components";

interface FilterTriggerProps {
  value: string;
  filter: AdvancedFilter;
  onRemoveFilter?: (filter: AdvancedFilter) => void;
  notShowCloseIcon?: boolean;
}

export function FilterTrigger({
  onRemoveFilter,
  value,
  filter,
  notShowCloseIcon = false,
}: FilterTriggerProps) {
  return (
    <Container>
      <Flex
        css={{
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <Caption css={{ color: "$gray500" }}>{filter.label}</Caption>
        {value && <Caption css={{ color: "$gray500" }}>:</Caption>}
        {value && (
          <Caption css={{ color: "$gray700", ml: "4px" }}>{value}</Caption>
        )}
      </Flex>
      {!notShowCloseIcon && (
        <CloseIcon onClick={() => onRemoveFilter(filter)} />
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.space[1]};
  padding: ${(props) => props.theme.space[1]} 0.5rem;
  border-radius: ${(props) => props.theme.space[2]};
  border: 1px solid ${(props) => props.theme.colors.gray300};
  background: ${(props) => props.theme.colors.gray100};

  svg {
    color: ${(props) => props.theme.colors.gray500};
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
`;
