import {
  PreDefinedMessage,
  PreDefinedMessageSubject,
} from "@/core/models/preDefinedMessage.model";
import { useMemo } from "react";
import { MessageCell } from "../components/CustomCells/MessageCell";
import { PreDefinedMessagesActionsCell } from "../components/CustomCells/PreDefinedMessagesActionsCell";
import { SubjectMessageCell } from "../components/CustomCells/SubjectMessageCell";
import { TitleCell } from "../components/CustomCells/TitleCell";

export const usePreDefinedMessagesColumns = (
  onDeletePreDefinedMessage: (id: number) => void,
  onUpdatePreDefinedMessage: (
    id: number,
    subject: PreDefinedMessageSubject,
    title: string,
    message: string
  ) => Promise<void>
) => {
  const columns = useMemo(() => {
    return [
      {
        title: "Sujeito",
        dataIndex: "subject",
        key: "subject",
        render: (text) => <SubjectMessageCell value={text} />,
      },
      {
        title: "Título",
        dataIndex: "title",
        key: "title",
        render: (text) => <TitleCell value={text} />,
      },
      {
        title: "Mensagem",
        dataIndex: "message",
        key: "message",
        render: (text) => <MessageCell value={text} />,
      },
      {
        title: "Ações",
        dataIndex: "message",
        key: "message",
        render: (record: PreDefinedMessage) => (
          <PreDefinedMessagesActionsCell
            preDefinedMessage={record}
            onDeletePreDefinedMessage={onDeletePreDefinedMessage}
            onUpdatePreDefinedMessage={onUpdatePreDefinedMessage}
          />
        ),
      },
    ];
  }, []);

  return columns;
};
