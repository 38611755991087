import { ReduxThunkError } from "@/core/errors/ReduxThunkError";
import { Report } from "@/core/models/reports.model";
import { RootState } from "@/core/store/store";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";

interface ReportsState {
  reports: Report[];
  selectedReport: Report;
  status: "loading" | "success" | "failed";
}

const initialState: ReportsState = {
  reports: [],
  selectedReport: {} as Report,
  status: "loading",
};

export const getReports = createAsyncThunk(
  "reports/getReports",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(`/dashboards`);
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

export const reportsSlice = createSlice({
  name: "reportsSlice",
  initialState,
  reducers: {
    setSelectedReport(state, action: PayloadAction<Report>) {
      state.selectedReport = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getReports.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getReports.fulfilled, (state, action) => {
      state.status = "success";
      state.reports = action.payload;
    });
    builder.addCase(
      getReports.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = "failed";
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error getting the reports");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
  },
});

export const selectReports = (state: RootState) => state.reportsState.reports;
export const selectSelectedReport = (state: RootState) =>
  state.reportsState.selectedReport;
export const selectReportsStatus = (state: RootState) =>
  state.reportsState.status;

export const { setSelectedReport } = reportsSlice.actions;

export const reportsReducer = reportsSlice.reducer;
