import { Flex } from "@gogeo-io/ui-library";
import RLSSkeleton from "react-loading-skeleton";

export const ClientOnKanbanSkeletonColumn = () => {
  return (
    <Flex css={{ flexDirection: "column", gap: "$1" }}>
      <RLSSkeleton width={184} height={23} borderRadius={4} />
      <RLSSkeleton width={100} height={16} borderRadius={4} />
      <RLSSkeleton width={100} height={16} borderRadius={4} />
    </Flex>
  );
};
