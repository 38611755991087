import { gvTheme } from "@gogeo-io/ui-library";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { createRoot } from "react-dom/client";
import "react-loading-skeleton/dist/skeleton.css";
import { AuthProvider } from "react-oidc-context";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "styled-components";
import { ProdMode } from "./ProdMode";
import { oidcConfig } from "./config/keycloak";
import { store } from "./core/store/store";
import { GlobalStyle } from "./global.styles";
import { removeUnwantedErrorsFromConsole } from "./utils/removeUnwantedErrorsFromConsole";

Sentry.init({
  dsn: "https://3a16d2aa42e0240ac3fb82a5e883cee7@o4505782965829632.ingest.sentry.io/4505782974939136",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["https://web.govendas.com/"],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.5, // Capture transactions, reduce in production! Default value: 1.0.
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

removeUnwantedErrorsFromConsole();

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <BrowserRouter>
    <ThemeProvider theme={gvTheme}>
      <GlobalStyle />
      <AuthProvider {...oidcConfig}>
        <Provider store={store}>
          <ProdMode />
        </Provider>
        <ToastContainer theme="colored" draggable newestOnTop />
      </AuthProvider>
    </ThemeProvider>
  </BrowserRouter>
);
