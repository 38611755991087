import { ProfileInfo } from "@/features/attendance/components/ProfileInfo";
import { selectClient } from "@/features/clientInfo/store/clientSlice";
import { useFormatPrice } from "@/hooks/useFormatPrice";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Text,
} from "@gogeo-io/ui-library";
import { isEmpty, isUndefined } from "lodash";
import { useSelector } from "react-redux";
import { useClientInfoMixpanel } from "../../hooks/useClientInfoMixpanel";
import UI from "../../ui";

export function ClientProfileFinancialData() {
  const selectedClient = useSelector(selectClient);

  const { formatBRLPrice } = useFormatPrice();
  const { clientProfileFinancialDataClickedEvent } = useClientInfoMixpanel();

  return (
    <Accordion type="single" collapsible width="full">
      <AccordionItem value="item-1">
        <AccordionTrigger
          onClick={() => clientProfileFinancialDataClickedEvent()}
        >
          <Text>Dados Financeiros</Text>
        </AccordionTrigger>
        <AccordionContent>
          <UI.ClientInfoWrapper>
            {selectedClient.financial_situation.defaulting ? (
              <ProfileInfo label="Inadimplente" value="Sim" />
            ) : (
              <ProfileInfo label="Inadimplente" value="Não" />
            )}
            {!isUndefined(selectedClient.financial_situation.credit_total) ? (
              <ProfileInfo
                label="Crédito total"
                value={formatBRLPrice(
                  selectedClient.financial_situation.credit_total
                )}
              />
            ) : (
              <ProfileInfo label="Crédito total" value="Não consta" />
            )}
            {!isUndefined(
              selectedClient.financial_situation.credit_available
            ) ? (
              <ProfileInfo
                label="Crédito disponível"
                value={formatBRLPrice(
                  selectedClient.financial_situation.credit_available
                )}
              />
            ) : (
              <ProfileInfo label="Crédito total" value="Não consta" />
            )}
            {!isEmpty(selectedClient.financial_situation.billing_plan.name) &&
            !isUndefined(
              selectedClient.financial_situation.billing_plan.name
            ) ? (
              <ProfileInfo
                label="Tipo de cobrança"
                value={selectedClient.financial_situation.billing_plan.name}
              />
            ) : (
              <ProfileInfo label="Tipo de cobrança" value="Não consta" />
            )}
            {!isEmpty(selectedClient.financial_situation.payment_plan.name) &&
            !isUndefined(
              selectedClient.financial_situation.payment_plan.name
            ) ? (
              <ProfileInfo
                label="Tipo de pagamento"
                value={selectedClient.financial_situation.payment_plan.name}
              />
            ) : (
              <ProfileInfo label="Tipo de pagamento" value="Não consta" />
            )}
          </UI.ClientInfoWrapper>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}
