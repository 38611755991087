import { Client } from "@/core/models/client.model";
import { useFormatPrice } from "@/hooks/useFormatPrice";
import { useMask } from "@/hooks/useMask";

export const useClientFields = (client: Client) => {
  const { formatBRLPrice } = useFormatPrice();
  const { usePhoneMask } = useMask();

  const fieldsToTranslate = [
    {
      user_field: "Nome do cliente",
      translated_field: client.name,
    },
    {
      user_field: "ID do cliente",
      translated_field: client.id,
    },
    {
      user_field: "Email do cliente",
      translated_field: client.email,
    },
    {
      user_field: "Telefone principal do cliente",
      translated_field: usePhoneMask(client.main_phone),
    },
    {
      user_field: "Nome fantansia do cliente",
      translated_field: client.fantasy_name,
    },
    {
      user_field: "Cidade que mora o cliente",
      translated_field: client.address.city,
    },
    {
      user_field: "Data de última compra do cliente",
      translated_field: client.financial_situation.last_buy_date,
    },
    {
      user_field: "Ticket médio do cliente",
      translated_field: formatBRLPrice(
        client.financial_situation.average_ticket
      ),
    },
    {
      user_field: "Tempo sem compra do cliente",
      translated_field: client.financial_situation.time_without_buy,
    },
  ];

  return fieldsToTranslate;
};
