import { registerAttendanceSchema } from "@/config/registerAttendanceSchema";
import { AttendanceStatus } from "@/core/models/business-goal.model";
import { Feedback } from "@/core/models/feedback.model";
import { uploadFileToRegisterAttendance } from "@/core/store/uploadFile/uploadFileSlice";
import { useClientInfoMixpanel } from "@/features/clientInfo/hooks/useClientInfoMixpanel";
import { selectClient } from "@/features/clientInfo/store/clientSlice";
import { selectSelectedHistoryFilters } from "@/features/history/store/historyFiltersSlice";
import { getHistory } from "@/features/history/store/historySlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { useFormatDate } from "@/hooks/useFormatDate";
import {
  Button,
  Flex,
  Heading,
  Text,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@gogeo-io/ui-library";
import { yupResolver } from "@hookform/resolvers/yup";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { selectFeedbackMappingHasError500 } from "../../store/feedbackMappingSlice";
import { createFeedback } from "../../store/feedbackSlice";
import UI from "../../ui";
import { ProfileInfo } from "../ProfileInfo";
import { RegisterAttendanceStatus } from "../RegisterAttendanceStatus";
import { FeedbackMapping } from "../feedback/FeedbackMapping";
import { FeedbackObservations } from "../feedback/FeedbackObservations";
import { AttachFileToRegister } from "./AttachFileToRegister";
import { AttendanceFeedbackChannels } from "./AttendanceCommunicationChannel";
import DateActions from "./RegisterAttendanceDateActions";
import { TryAgainUploadFileToRegisterAttendance } from "./TryAgainUploadFileToRegisterAttendance";

interface RegisterAttendanceProps {
  statusOfAttendance?: AttendanceStatus;
}

export function RegisterAttendance({
  statusOfAttendance = "Vendi",
}: RegisterAttendanceProps) {
  const selectedClient = useSelector(selectClient);
  const selectedHistoryFilters = useSelector(selectSelectedHistoryFilters);
  const feedbackMappingHasError500 = useSelector(
    selectFeedbackMappingHasError500
  );

  const [attendanceStatusSelected, setAttendanceStatusSelected] =
    useState<AttendanceStatus>(statusOfAttendance);

  const [registerAttendanceStatus, setRegisterAttendanceStatus] =
    useState("success");
  const [uploadFileErrorMessage, setUploadFileErrorMessage] = useState("");
  const [uploadFileHasError, setUploadFileHasError] = useState(false);
  const [filesToSend, setFilesToSend] = useState<File[]>([]);
  const [feedbackCreated, setFeedbackCreated] = useState<Feedback>(
    {} as Feedback
  );
  const { formatLongDatePtBr } = useFormatDate();
  const { registeredFeedbackEvent, registeredFeedbackOpenedEvent } =
    useClientInfoMixpanel();
  const dispatch = useAppDispatch();

  const formData = new FormData();

  const { handleSubmit, control, setValue, reset } = useForm<Feedback>({
    mode: "onBlur",
    resolver: yupResolver(registerAttendanceSchema),
    defaultValues: {
      observations: "",
      files: [],
      files_to_send: [],
      origin: "GoVendas",
      channel: "Whatsapp",
    },
  });

  const onSubmit: SubmitHandler<Feedback> = async (
    data: Feedback
  ): Promise<void> => {
    setRegisterAttendanceStatus("loading");

    handleRegisterAttendance(data);
  };

  const handleRegisterAttendance = async (data: Feedback) => {
    setUploadFileHasError(false);

    await dispatch(
      createFeedback({ feedback: data, client_id: selectedClient.id })
    ).then(async (res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setFeedbackCreated(res.payload);
        setFilesToSend(data.files_to_send);

        if (data.files_to_send && data.files_to_send.length > 0) {
          for (let i = 0; i < data.files_to_send.length; i++) {
            formData.append(`files`, data.files_to_send[i]);
          }

          await dispatch(
            uploadFileToRegisterAttendance({
              feedback_id: res.payload.id,
              data: formData,
            })
          ).then(async (res) => {
            if (res.meta.requestStatus === "fulfilled") {
              registeredFeedbackEvent(data);
              onRegisterAttendanceSuccess();
            } else {
              setRegisterAttendanceStatus("fail");
              setUploadFileHasError(true);
              setUploadFileErrorMessage(res.payload.errorMessage);
            }
          });
        } else {
          registeredFeedbackEvent(data);
          onRegisterAttendanceSuccess();
        }
      } else {
        setRegisterAttendanceStatus("fail");
      }
    });
  };

  const onRegisterAttendanceSuccess = async () => {
    toast.success("Registro de atendimento concluído");
    reset();
    reloadHistory();
  };

  const reloadHistory = async () => {
    await dispatch(
      getHistory({
        client_id: selectedClient.id,
        page: 0,
        size: import.meta.env.VITE_PAGE_SIZE,
        filters: selectedHistoryFilters,
      })
    ).then(() => {
      setRegisterAttendanceStatus("success");
    });
  };

  const onError = (errors) => {
    if (!isEmpty(errors)) {
      toast.warning(
        `Não foi possível registrar atendimento! ${errors.feedback_id.message}`
      );
    }
  };

  const handleAttendanceStatusChange = (option: AttendanceStatus) => {
    setAttendanceStatusSelected(option);
    setValue("feedback_id", undefined);
  };

  useEffect(() => {
    setValue("origin", "GoVendas");
    registeredFeedbackOpenedEvent();
  }, []);

  return (
    <>
      <Heading size="subtitle1" css={{ color: "$gray900", fontWeight: "bold" }}>
        Registrar Atendimento
      </Heading>
      <UI.RegisterAttendanceForm noValidate>
        <Text>Selecione o canal de atendimento:</Text>
        <Controller
          control={control}
          name="channel"
          render={({ field: { onChange, value } }) => {
            return (
              <AttendanceFeedbackChannels onChange={onChange} value={value!} />
            );
          }}
        />

        <Text>Selecione o status do atendimento:</Text>
        <RegisterAttendanceStatus
          attendanceStatusSelected={attendanceStatusSelected}
          setAttendanceStatusSelected={handleAttendanceStatusChange}
        />

        {!feedbackMappingHasError500 ? (
          <Controller
            control={control}
            name="feedback_id"
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Flex css={{ flexDirection: "column", gap: "$2" }}>
                  <Tooltip>
                    <TooltipTrigger
                      style={{
                        background: "transparent",
                        width: "fit-content",
                      }}
                    >
                      <Flex css={{ gap: "$1" }}>
                        <Text>O que aconteceu?</Text>
                        <Flex css={{ color: "$red500" }}>*</Flex>
                      </Flex>
                    </TooltipTrigger>
                    <TooltipContent aria-label="O que aconteceu é obrigatório">
                      O preenchimento desse campo é obrigatório
                    </TooltipContent>
                  </Tooltip>

                  {!!error && (
                    <Flex
                      css={{
                        background: "$red100",
                        color: "$red600",
                        padding: "$1 $3",
                        borderRadius: "2px",
                      }}
                    >
                      <Text css={{ color: "inherit" }}>{error.message}</Text>
                    </Flex>
                  )}
                  <FeedbackMapping
                    attendanceStatusSelected={attendanceStatusSelected}
                    onChange={onChange}
                    value={value}
                  />
                </Flex>
              );
            }}
          />
        ) : (
          <>
            <Tooltip>
              <TooltipTrigger
                style={{
                  background: "transparent",
                  width: "fit-content",
                }}
              >
                <Flex css={{ gap: "$1" }}>
                  <Text>O que aconteceu?</Text>
                  <Flex css={{ color: "$red500" }}>*</Flex>
                </Flex>
              </TooltipTrigger>
              <TooltipContent aria-label="O que aconteceu é obrigatório">
                O preenchimento desse campo é obrigatório
              </TooltipContent>
            </Tooltip>
            <Flex
              css={{
                borderRadius: "4px",
                padding: "0.5rem 1rem",
                background: "$red500",
                color: "$white",
              }}
            >
              <Text size="body2" css={{ color: "$white" }}>
                Não conseguimos buscar as opções de atendimento, tente novamente
                mais tarde ou atualize a página
              </Text>
            </Flex>
          </>
        )}

        <Controller
          control={control}
          name="observations"
          render={({ field: { onChange, value } }) => {
            return <FeedbackObservations onChange={onChange} value={value!} />;
          }}
        />

        <Controller
          control={control}
          name="files_to_send"
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <AttachFileToRegister
                onChange={onChange}
                files={value}
                titleOfDialog="Anexar Arquivos"
              />
            );
          }}
        />

        {selectedClient.attendance_situation &&
        selectedClient.attendance_situation.next_attendance ? (
          <ProfileInfo
            label="Data prevista para próximo contato:"
            value={formatLongDatePtBr(
              selectedClient.attendance_situation.next_attendance,
              true
            )}
          />
        ) : (
          <ProfileInfo
            label="Data prevista para o próximo contato:"
            value="Sem previsão"
          />
        )}

        <Controller
          control={control}
          name="rescheduled_date"
          render={({ field: { onChange, value } }) => {
            return <DateActions onChange={onChange} value={value!} />;
          }}
        />

        <Flex css={{ gap: "$3" }}>
          <Button color="primary" bordered fullWidth onClick={() => reset()}>
            Limpar Registro
          </Button>
          <Button
            fullWidth
            disabled={feedbackMappingHasError500}
            isLoading={registerAttendanceStatus === "loading"}
            onClick={() => handleSubmit(onSubmit, onError)()}
          >
            Registrar
          </Button>
        </Flex>
      </UI.RegisterAttendanceForm>

      {uploadFileHasError && (
        <TryAgainUploadFileToRegisterAttendance
          feedbackId={feedbackCreated.id}
          triedToUploadFiles={filesToSend}
          onRegisterAttendanceSuccess={onRegisterAttendanceSuccess}
          errorMessage={uploadFileErrorMessage}
          onResetForm={reset}
          onReloadHistory={reloadHistory}
        />
      )}
    </>
  );
}
