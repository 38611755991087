import { ClientsKanbanSkeletonPage } from "@/components/Skeleton/ClientsKanbanSkeletonPage";
import { ListOfTagsFilter } from "@/core/models/listOfTags.model";
import { useGetClientsByFiltersParams } from "@/features/clients/hooks/useGetClientsByFiltersParams";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Flex, gvTheme } from "@gogeo-io/ui-library";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { SearchClientsOnClientsListPage } from "../components/SearchClientsOnClientsListPage";
import {
  getFunnel,
  getFunnelCountOfClients,
  selectAvailableFunnels,
} from "../store/funnelsSlice";
import { selectSelectedListToEdit } from "../store/listSelectedSlice";
import { selectSelectedClientsTypeOfView } from "../store/selectedClientsTypeOfViewSlice";
import { ClientsTypeOfView } from "./ClientsListPage";
import { KanbanViewOfClients } from "./KanbanViewOfClients";
import { ListViewOfClients } from "./ListViewOfClients";

interface ClientsListContainerProps {
  onClientsTypeOfViewChange: (option: ClientsTypeOfView) => void;
}

export function ClientsListContainer({
  onClientsTypeOfViewChange,
}: ClientsListContainerProps) {
  const [funnelsStatus, setFunnelsStatus] = useState("loading");

  const dispatch = useAppDispatch();
  const { paramsOnListView, paramsOnEditListView } =
    useGetClientsByFiltersParams();

  const selectedClientsTypeOfView = useSelector(
    selectSelectedClientsTypeOfView
  );
  const availableFunnels = useSelector(selectAvailableFunnels);
  const selectedListToEdit = useSelector(selectSelectedListToEdit);

  useEffect(() => {
    setFunnelsStatus("loading");

    let params: ListOfTagsFilter = {};

    if (isEmpty(selectedListToEdit)) {
      params = paramsOnListView();
    } else {
      params = paramsOnEditListView();
    }

    const loadFunnel = async () => {
      await dispatch(getFunnel({ id: availableFunnels[0].id, params })).then(
        async (res) => {
          if (res.meta.requestStatus === "fulfilled") {
            await dispatch(
              getFunnelCountOfClients({ id: res.payload.id, params })
            );

            setFunnelsStatus("fulfilled");
          }
        }
      );
    };

    loadFunnel();
  }, []);

  return (
    <Container>
      {funnelsStatus === "fulfilled" ? (
        <Flex css={{ flexDirection: "column", height: "100%" }}>
          <SearchClientsOnClientsListPage
            clientsTypeOfView={selectedClientsTypeOfView}
            onClientsTypeOfViewChange={onClientsTypeOfViewChange}
          />
          {selectedClientsTypeOfView === "list" ? (
            <ListViewOfClients />
          ) : (
            <KanbanViewOfClients />
          )}
        </Flex>
      ) : (
        <ClientsKanbanSkeletonPage />
      )}
    </Container>
  );
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: ${({ theme }) => theme.space[2]};

  @media (max-width: ${gvTheme.mediaSizes.laptop}) {
    margin-right: 20px;
  }
`;
