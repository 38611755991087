import {
  Box,
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
} from "@gogeo-io/ui-library";
import { ReactNode } from "react";
import { MdClose } from "react-icons/md";

interface GenericFilterPopoverProps {
  trigger: ReactNode;
  children: ReactNode;
  popoverIsOpen: boolean;
  onOpenPopover: () => void;
  onPopoverOpenChange: (value: boolean) => void;
}

export function GenericFilterPopover({
  trigger,
  children,
  popoverIsOpen,
  onOpenPopover,
  onPopoverOpenChange,
}: GenericFilterPopoverProps) {
  return (
    <Popover open={popoverIsOpen} onOpenChange={onPopoverOpenChange}>
      <PopoverTrigger
        style={{ background: "transparent" }}
        onClick={onOpenPopover}
      >
        {trigger}
      </PopoverTrigger>
      <PopoverContent
        align="center"
        css={{
          maxHeight: "450px",
          maxWidth: "600px",
          width: "100%",
          borderRadius: "6px",
          boxShadow: "$md",
          overflowY: "scroll",
        }}
      >
        <PopoverClose aria-label="Close">
          <MdClose />
        </PopoverClose>

        <Box css={{ paddingTop: "$2" }}>{children}</Box>
      </PopoverContent>
    </Popover>
  );
}
