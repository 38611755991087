import { ProfileInfo } from "@/features/attendance/components/ProfileInfo";
import { useMask } from "@/hooks/useMask";
import { IconButton } from "@gogeo-io/ui-library";
import { useWhatsapp } from "@hooks/useWhatsapp";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import styled from "styled-components";

interface UserInfoProps {
  label: string;
  value: string;
}

export function UserProfileInfoPhone({ label, value }: UserInfoProps) {
  const [isVisible, setIsVisible] = useState(false);

  const { sendWhatsappToUser } = useWhatsapp();
  const { usePhoneMask } = useMask();

  const handleOpenWhatsapp = () => {
    sendWhatsappToUser(value, "");
  };

  const showButtons = () => setIsVisible(true);
  const hideButtons = () => setIsVisible(false);

  return (
    <Wrapper onMouseEnter={showButtons} onMouseLeave={hideButtons}>
      <ProfileInfo label={label} value={usePhoneMask(value)} />

      <AnimatePresence>
        {isVisible && value !== "" && value !== undefined && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.15 } }}
            exit={{ opacity: 0 }}
          >
            <IconButton size="medium" color="primary" type="ghost">
              <LocalPhoneIcon fontSize="inherit" />
            </IconButton>
            <IconButton
              size="medium"
              color="primary"
              type="ghost"
              onClick={handleOpenWhatsapp}
            >
              <WhatsAppIcon fontSize="inherit" />
            </IconButton>
          </motion.div>
        )}
      </AnimatePresence>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 35px;
  margin-top: -5px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;
