import { Breadcrumb } from "@/core/models/breadcrumb";
import { setBreadcrumbs } from "@/core/store/breadcrumbs/breadcrumbsSlice";
import { selectUser } from "@/core/store/users/usersSlice";
import { selectSelectedList } from "@/features/attendance/store/listSelectedSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Flex } from "@gogeo-io/ui-library";
import { isEmpty, isUndefined } from "lodash";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { ClientsPageSkeleton } from "../components/ClientsPageSkeleton";
import { ClientProfileActivityAreaData } from "../components/info-page/ClientProfileActivityAreaData";
import { ClientProfileContactData } from "../components/info-page/ClientProfileContactData";
import { ClientProfileFinancialData } from "../components/info-page/ClientProfileFinancialData";
import { ClientProfileGeneralInfo } from "../components/info-page/ClientProfileGeneralInfo";
import { ClientProfileParameters } from "../components/info-page/ClientProfileParameters";
import { selectClient, selectClientStatus } from "../store/clientSlice";

export function ClientInfoPage() {
  const clientStatus = useSelector(selectClientStatus);
  const selectedList = useSelector(selectSelectedList);
  const selectedClient = useSelector(selectClient);
  const selectedUser = useSelector(selectUser);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const setTitle = async () => {
      const breadcrumbs: Breadcrumb[] = [
        { item: "Atendimento", url: "/attendance" },
        {
          item: `${
            !isEmpty(selectedList.name) && !isUndefined(selectedList.name)
              ? selectedList.name
              : "Meus clientes"
          }`,
          url: `/attendance/${
            !isEmpty(selectedList.slug) && !isUndefined(selectedList.slug)
              ? selectedList.slug
              : `${selectedUser.account_id}_${selectedUser.id}_meus_clientes`
          }`,
        },
        {
          item: selectedClient.name,
          url: `/info/client/${selectedClient.id}/client-info`,
        },
      ];
      await dispatch(setBreadcrumbs(breadcrumbs));
    };

    setTitle();
  }, [selectedList, selectedClient]);

  return (
    <>
      {clientStatus !== "success" ? (
        <ClientsPageSkeleton />
      ) : (
        <Flex css={{ gap: "$4" }}>
          <Flex css={{ flexDirection: "column", gap: "2.5rem", flex: 1 }}>
            <ClientProfileGeneralInfo />

            <ClientProfileContactData />
          </Flex>
          <Flex css={{ flexDirection: "column", gap: "2.5rem", flex: 1 }}>
            <ClientProfileParameters />
            <ClientProfileFinancialData />
            <ClientProfileActivityAreaData />
          </Flex>
        </Flex>
      )}
    </>
  );
}
