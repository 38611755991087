import { User } from "@/core/models/user.model";
import mixpanel from "mixpanel-browser";
import packageJson from "../../package.json";

export enum TrackingEvents {
  CLIENTS_SEARCH_PAGE = "Página vista: Busca de clientes",
  CLIENTS_PAGE = "Página vista: Página de clientes",
  CLIENT_PAGE = "Página vista: Página de cliente",
  PARAMS_PAGE = "Página vista: Página de parâmetros de atendimento",
  REMINDERS_PAGE = "Página vista: Lembretes",
  REPORTS_PAGE = "Página vista: Relatórios",
  REPORT_PAGE = "Página vista: Relatório",
  NOTIFICATIONS_PAGE = "Página vista: Notificações",
  PRODUCTS_SUGGESTION_PAGE = "Página vista: Sugestão de produtos",

  CLIENTS_SEARCH_FOUND = "Clientes: Clientes encontrados",
  CLIENTS_SEARCH = "Clientes: Busca de clientes",
  CLIENTS_SORT_CHANGE = "Clientes: Ordem de busca de clientes mudada",
  MY_CLIENTS_SELECTED_LIST = "Listas: Lista de meus clientes selecionada",
  SELECTED_LIST = "Listas: Lista selecionada",
  FIXED_LIST = "Listas: Lista fixada",
  UNFIXED_LIST = "Listas: Lista desfixada",
  TAG_REMOVED = "Listas: Tag removida",
  TAG_ADDED = "Listas: Tag Adicionada",
  ATTRIBUTE_REMOVED = "Listas: Coluna removida",
  ATTRIBUTE_ADDED = "Listas: Coluna Adicionada",
  LIST_OF_TAGS_VIEWED = "Listas: Lista de tags visualizada",
  LIST_OF_TAGS_WITH_SEARCH_VIEWED = "Listas: Lista de tags com filtros de busca visualizada",
  LIST_OF_TAGS_CLICKED_TO_EDIT = "Listas: Lista de tags selecionada para edição",
  ADVANCED_FILTERS = "Lista: Filtros aplicados",
  UPDATE_LIST = "Listas: Lista de tags atualizada",
  DELETE_LIST = "Listas: Lista de tags apagada",
  SEARCH_LIST = "Listas: Busca de listas",
  CREATE_LIST = "Listas: Lista de tags criada",
  DUPLICATE_LIST = "Listas: Lista de tags duplicada",
  CLICKED_ON_LIST = "Clientes: Cliente selecionado na lista",
  CLIENT_FEEDBACK_CREATED = "Cliente: Feedback Registrado",
  CLIENT_RESCHEDULED = "Cliente: Cliente reagendado",
  CLIENT_OPENED_MAIL = "Cliente: Email do cliente aberto",
  CLIENT_OPENED_PARAMS = "Cliente: Parâmetros de cliente abertos",
  CLIENT_OPENED_CONTACT_DATA = "Cliente: Dados de contato abertos",
  CLIENT_OPENED_REGISTER_ATTENDANCE = "Cliente: Registro de atendimento aberto",
  CLIENT_OPENED_FINANCIAL_DATA = "Cliente: Dados financeiros abertos",
  CLIENT_OPENED_ACTIVITY_AREA_DATA = "Cliente: Dados de area de atividade abertos",
  CLIENT_SENDED_MESSAGE = "Cliente: Mensagem no whatsapp enviada",
  REMINDER_CREATE_MODAL_OPENED = "Lembretes: Modal de criação de lembrete aberto",
  CREATED_REMINDER = "Lembretes: Lembrete criado",
  CREATED_REMINDER_FAIL = "Lembretes: Lembrete não foi criado",
  REMINDER_UPDATED = "Lembretes: Status do lembrete foi alterado",
  REMINDER_DELETED = "Lembretes: lembrete deletado",
  NOTIFICATION_READ = "Notificações: Notificação visualizada",
  PRODUCTS_SUGGESTION_SENDED_MESSAGE_WITH_PRODUCT = "Sugestão de Produtos: Mensagem com produto enviada para cliente",
  PRODUCTS_SUGGESTION_LIKED = "Sugestão de Produtos: Vendedor gostou do produto",
  PRODUCTS_SUGGESTION_DISLIKED = "Sugestão de Produtos: Vendedor não gostou do produto",
  PRODUCTS_SUGGESTION_FILTERED = "Sugestão de Produtos: Filtros Aplicados",
  CHANGED_PARAMS = "Parâmetros: Parâmetros de atendimento alterados",
  REPORT_FILTER = "Relatório: Filtrar relatório",
  TICKET_CREATED = "Suporte: Ticket de suporte criado",
  PRE_DEFINED_MESSAGE_CREATED = "Mensagem pré definida: Mensagem criada",
  PRE_DEFINED_MESSAGE_UPDATED = "Mensagem pré definida: Mensagem editada",
  PRE_DEFINED_MESSAGE_DELETED = "Mensagem pré definida: Mensagem deletada",
  PRE_DEFINED_MESSAGE_GET = "Mensagem pré definida: Mensagens buscada",
  BUSINESS_GOAL_CREATED = "Objetivo de negócio: Objetivo de negócio criado",
  BUSINESS_GOAL_UPDATED = "Objetivo de negócio: Objetivo de negócio editado",
  BUSINESS_GOAL_DELETED = "Objetivo de negócio: Objetivo de negócio deletado",
  BUSINESS_GOAL_GET = "Objetivo de negócio: Objetivos de negócios buscado",
  ATTENDANCE_JUSTIFICATION_CREATED = "Justicativas de atendimento: Justificativa e atendimento criada",
  ATTENDANCE_JUSTIFICATION_UPDATED = "Justicativas de atendimento: Justicativa de atendimento editada",
  ATTENDANCE_JUSTIFICATION_DELETED = "Justicativas de atendimento: Justicativa de atendimento deletada",
  ATTENDANCE_JUSTIFICATION_GET = "Justicativas de atendimento: Justicativas de atendimento buscada",
}

export class MixpanelTracking {
  private static _instance: MixpanelTracking;

  public static getInstance(): MixpanelTracking {
    if (MixpanelTracking._instance == null) {
      return (MixpanelTracking._instance = new MixpanelTracking());
    }

    return this._instance;
  }

  public constructor() {
    if (MixpanelTracking._instance)
      throw new Error(
        "Error: Instance creation of MixpanelTracking not alowed. Use Mixpanel.getInstance() instead."
      );

    mixpanel.init(import.meta.env.VITE_MIXPANEL_GOGEO_TOKEN || "", {
      debug: true,
      ignore_dnt: true,
    });
  }

  public setPeople(user: User): void {
    mixpanel.people.set({
      ...user,
      govendas_version: packageJson.version,
      screen_size: `${screen.availWidth}x${screen.availHeight}`,
    });

    mixpanel.identify(`${user.account_id}-${user.id}`);
  }

  public track(name: string, data: object = {}): void {
    mixpanel.track(name, data);
  }
}
