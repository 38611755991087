import { UpdateAttendance } from "@/features/attendance/components/register-attendance/UpdateAttendance";
import { useByFeedback } from "@/hooks/useByFeedback";
import { useFormatDate } from "@/hooks/useFormatDate";
import { AttendanceRecord } from "@core/models/history.model";
import { Flex } from "@gogeo-io/ui-library";
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
} from "@mui/icons-material";
import { isToday } from "date-fns";
import { useMemo, useState } from "react";
import UI from "../ui";
import { TimelineHeaderInfoOrderTotalAmount } from "./TimelineHeaderInfo/TimelineHeaderInfoOrderTotalAmount";
import { TimelineHeaderInfoRescheduleCreationDate } from "./TimelineHeaderInfo/TimelineHeaderInfoRescheduleCreationDate";
import { TimelineHeaderInfoRescheduledDate } from "./TimelineHeaderInfo/TimelineHeaderInfoRescheduledDate";
import { TimelineHeaderInfoSalesman } from "./TimelineHeaderInfo/TimelineHeaderInfoSalesman";
import { TimelineHeaderInfoStatus } from "./TimelineHeaderInfo/TimelineHeaderInfoStatus";
import { TimelineHeaderInfoTimestamp } from "./TimelineHeaderInfo/TimelineHeaderInfoTimestamp";

export interface TimelineAttendanceRecordHeaderProps {
  lastAttendance: AttendanceRecord;
  hasObservations: boolean;
  hasAttendanceInfo: boolean;
  hasMoreAttendanceRecord: boolean;
  canShowMoreInfo: boolean;
  onShowMoreInfoAboutAttendance: () => void;
  order_total_amount: number;
  salesman: string;
}

export function TimelineAttendanceRecordHeader({
  lastAttendance,
  canShowMoreInfo,
  hasObservations,
  hasAttendanceInfo,
  hasMoreAttendanceRecord,
  onShowMoreInfoAboutAttendance,
  order_total_amount,
  salesman,
}: TimelineAttendanceRecordHeaderProps) {
  const [canUpdateRegister, setCanUpdateRegister] = useState(false);

  const {
    feedback,
    feedback_id,
    feedback_timestamp,
    rescheduled_date,
    rescheduled_creation_date,
  } = lastAttendance;

  const { newDateBasedOnDateWithoutTimezone } = useFormatDate();
  const { hasAttendanceRegister } = useByFeedback();

  useMemo(() => {
    if (isToday(newDateBasedOnDateWithoutTimezone(feedback_timestamp))) {
      setCanUpdateRegister(true);
    }
  }, [feedback_timestamp]);

  return (
    <UI.TimelineAttendanceRecordHeader>
      <div className="top">
        <TimelineHeaderInfoStatus
          feedback={feedback}
          feedback_id={feedback_id}
        />

        {order_total_amount !== 0 && order_total_amount && (
          <TimelineHeaderInfoOrderTotalAmount
            order_total_amount={order_total_amount}
          />
        )}

        {hasAttendanceRegister && canUpdateRegister && (
          <UpdateAttendance attendance_record={lastAttendance} />
        )}
      </div>
      <Flex className="bottom" onClick={onShowMoreInfoAboutAttendance}>
        <div className="info">
          <TimelineHeaderInfoTimestamp
            feedback_timestamp={feedback_timestamp}
          />

          {salesman && <TimelineHeaderInfoSalesman salesman={salesman} />}

          <Flex>
            {rescheduled_creation_date && rescheduled_creation_date !== "" && (
              <TimelineHeaderInfoRescheduleCreationDate
                rescheduled_creation_date={rescheduled_creation_date}
              />
            )}

            {rescheduled_date && rescheduled_date !== "" && (
              <TimelineHeaderInfoRescheduledDate
                rescheduled_date={rescheduled_date}
              />
            )}
          </Flex>
        </div>

        {(hasObservations || hasAttendanceInfo || hasMoreAttendanceRecord) && (
          <>{canShowMoreInfo ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}</>
        )}
      </Flex>
    </UI.TimelineAttendanceRecordHeader>
  );
}
