import { gvTheme } from "@gogeo-io/ui-library";
import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { SkeletonTheme } from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { version } from "../package.json";
import { Error500Interceptor } from "./Error500Interceptor";
import { TokenHasExpiredDialog } from "./components/TokenHasExpiredDialog";
import { TokenIsExpiringDialog } from "./components/TokenIsExpiringDialog";
import { MixpanelTracking } from "./config/mixpanel";
import { selectUser, selectUserStatus } from "./core/store/users/usersSlice";
import { getNotifications } from "./features/notifications/store/notificationsSlice";
import { useShowRemindersToNow } from "./features/reminder/hooks/useShowRemindersToNow";
import { getReminders } from "./features/reminder/store/reminderSlice";
import useAppDispatch from "./hooks/useAppDispatch";
import { useCheckIfUserIsInWorkingTime } from "./hooks/useCheckIfUserIsInWorkingTime";
import { useInterval } from "./hooks/useInterval";
import AppWrapper from "./layouts/AppWrapper";
import { Topbar } from "./layouts/Topbar";
import { RoutesProvider } from "./routes/routes";

interface AppProps {
  tokenIsExpiring: boolean;
  tokenHasExpired: boolean;
}

export function App({ tokenIsExpiring, tokenHasExpired }: AppProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { checkIfUserIsInWorkingTime } = useCheckIfUserIsInWorkingTime();
  const {
    handleRemindersNotReadedCount,
    handleListenerToSearchForRemindersToPopupOnScreen,
  } = useShowRemindersToNow();

  const user = useSelector(selectUser);
  const userStatus = useSelector(selectUserStatus);

  useEffect(() => {
    async function loadNotifications() {
      await dispatch(getNotifications());
    }

    async function loadReminders() {
      await dispatch(getReminders()).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          handleRemindersNotReadedCount();
        }
      });
    }

    function setMixpanelInfo() {
      MixpanelTracking.getInstance().setPeople(user);
    }

    function setSentryUser() {
      Sentry.setUser({
        id: String(user.id),
        email: user.email,
        username: user.name,
      });
      Sentry.setTag("account_id", user.account_id);
      Sentry.setTag("account_name", user.account_name);
      Sentry.setTag("app_version", version);
    }

    setMixpanelInfo();
    setSentryUser();
    loadNotifications();
    loadReminders();
  }, []);

  useInterval(async () => {
    if (!checkIfUserIsInWorkingTime({ user })) {
      navigate("/user-is-not-in-working-time");
    }
  }, 1000 * 60 * 15);

  useInterval(async () => {
    if (!tokenHasExpired) {
      await dispatch(getNotifications());
    }
  }, 1000 * 60 * 1);

  useInterval(() => {
    if (!tokenHasExpired) {
      handleListenerToSearchForRemindersToPopupOnScreen();
    }
  }, 1000 * 60 * 1);

  return (
    <SkeletonTheme
      baseColor={gvTheme.colors.gray100}
      highlightColor={gvTheme.colors.gray50}
    >
      {userStatus === "success" && (
        <AppWrapper>
          <Topbar />

          {tokenIsExpiring && <TokenIsExpiringDialog />}
          {tokenHasExpired && <TokenHasExpiredDialog />}

          <Error500Interceptor>
            <RoutesProvider />
          </Error500Interceptor>
        </AppWrapper>
      )}

      {/* <Support /> */}
    </SkeletonTheme>
  );
}
