import {
  setAttributesToFilter,
  setDefaultAttributesToFilter,
} from "@/features/clientInfo/store/clientAttrOptsSlice";
import {
  cleanColumnsToSort,
  setColumnsToSort,
} from "@/features/clientInfo/store/clientColumnsToSortSlice";
import { setClientsHasError500 } from "@/features/clients/store/clientsSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { useFormatPrice } from "@/hooks/useFormatPrice";
import { ListOfTags, SearchFilters } from "@core/models/listOfTags.model";
import {
  Caption,
  Dialog,
  DialogClose,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Flex,
  Heading,
  IconButton,
  Progress,
  RightSlot,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@gogeo-io/ui-library";
import {
  AttachMoney,
  Close,
  ContentCopy,
  EventNoteOutlined,
} from "@mui/icons-material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAttendanceMixpanel } from "../hooks/useAttendanceMixpanel";
import {
  addTagsOnSelectedClientFilter,
  setSelectedAdvancedFilters,
} from "../store/clientAdvancedFiltersSlice";
import { emitEventToChangeClients } from "../store/emitEventToChangeClientsSlice";
import { addListOnState, createListOfTags } from "../store/listOfTagsSlice";
import { selectList, selectListToEdit } from "../store/listSelectedSlice";
import { setSelectedSearchFilters } from "../store/selectedSearchFiltersSlice";
import UI from "../ui";
import { DuplicateListConfirmation } from "./attendanceListItem/DuplicateListConfirmation";
export interface AttendanceListItemProps {
  listSelected: ListOfTags;
}

export function AttendanceAllClientsListItem({
  listSelected,
}: AttendanceListItemProps) {
  const [duplicateListDialogIsOpen, setDuplicateDialogIsOpen] = useState(false);

  const dispatch = useAppDispatch();
  const { myClientsListClickedEvent, duplicateListEvent } =
    useAttendanceMixpanel();
  const { list_slug } = useParams();
  const { formatNumberToDecimal } = useFormatPrice();
  const navigate = useNavigate();

  const handleClickCard = async () => {
    await dispatch(emitEventToChangeClients(false));

    myClientsListClickedEvent(listSelected);

    const columnsToSort = listSelected.filters?.sort?.orders;
    if (columnsToSort !== undefined && columnsToSort.length > 0) {
      await dispatch(setColumnsToSort(columnsToSort));
    } else {
      await dispatch(cleanColumnsToSort());
    }

    const advancedFilters = listSelected.filters?.advanced_filters;
    if (advancedFilters !== undefined && advancedFilters.length > 0) {
      await dispatch(setSelectedAdvancedFilters(advancedFilters));
    } else {
      await dispatch(setSelectedAdvancedFilters([]));
    }

    const tags = listSelected.filters?.tags;
    if (tags !== undefined && tags.length > 0) {
      await dispatch(addTagsOnSelectedClientFilter(tags));
    } else {
      await dispatch(addTagsOnSelectedClientFilter([]));
    }

    const attrOpts = listSelected.filters?.attr_opts;
    if (attrOpts !== undefined) {
      await dispatch(setAttributesToFilter(attrOpts));
    } else {
      await dispatch(setDefaultAttributesToFilter());
    }

    const searchFilters: SearchFilters = {
      match_type: "Geral",
      match_value: "",
    };

    await dispatch(setSelectedSearchFilters(searchFilters));

    await dispatch(selectList(listSelected));
    await dispatch(selectListToEdit({} as ListOfTags));
    await dispatch(setClientsHasError500(false));

    await dispatch(emitEventToChangeClients(true));

    navigate(`/attendance/${listSelected.slug}`);
  };

  const handleDuplicateList = async (listName: string) => {
    const args = {
      name: listName,
      filters: listSelected.filters,
    };

    await dispatch(createListOfTags(args)).then(async (value) => {
      if (value.payload.status === 409) {
        return;
      }

      if (value.meta.requestStatus === "fulfilled") {
        const listDuplicated: ListOfTags = value.payload;
        duplicateListEvent(listSelected, listDuplicated);
        await dispatch(addListOnState(listDuplicated));
        await dispatch(selectList(listDuplicated));

        navigate(`/attendance/${value.payload.slug}`);
      }
    });
  };

  const listMatchSelectedList = listSelected.slug === list_slug ? "active" : "";

  const realizedProgress = (
    listSelected.infos?.total > 0
      ? (listSelected.infos?.realized / listSelected.infos?.total) * 100
      : 0
  ).toFixed(2);

  const positivedProgress = (
    listSelected.infos?.total > 0
      ? (listSelected.infos?.positived / listSelected.infos?.total) * 100
      : 0
  ).toFixed(2);

  return (
    <>
      <UI.AttendanceListItem
        className={listMatchSelectedList}
        onClick={handleClickCard}
      >
        <UI.AttendanceInfoWrapper>
          <Flex css={{ alignItems: "center", justifyContent: "space-between" }}>
            <Heading
              size="subtitle2"
              css={{
                color: "$gray900",
                display: "flex",
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {listSelected.name}
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <IconButton color="gray" type="ghost" size="small">
                    <ArrowDropDownIcon fontSize="inherit" />
                  </IconButton>
                </DropdownMenuTrigger>

                <DropdownMenuContent sideOffset={5}>
                  <DropdownMenuItem
                    onClick={async (event) => {
                      event.stopPropagation();
                      setDuplicateDialogIsOpen(true);
                    }}
                  >
                    Duplicar
                    <RightSlot>
                      <ContentCopy fontSize="small" color="inherit" />
                    </RightSlot>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>

              <Dialog
                open={duplicateListDialogIsOpen}
                onOpenChange={(e) => setDuplicateDialogIsOpen(e)}
              >
                <DialogTrigger />
                <DialogContent style={{ width: "450px" }}>
                  <DialogTitle>Duplicar Lista</DialogTitle>

                  <DialogClose asChild>
                    <IconButton
                      type="ghost"
                      css={{ position: "absolute", top: "1rem", right: "1rem" }}
                      onClick={(event) => event.stopPropagation()}
                    >
                      <Close />
                    </IconButton>
                  </DialogClose>

                  <DuplicateListConfirmation
                    onDuplicateList={handleDuplicateList}
                    selectedList={listSelected}
                  />
                </DialogContent>
              </Dialog>
            </Heading>
          </Flex>
          <UI.AttendanceIndicatorsWrapper>
            <Flex css={{ gap: "$2" }}>
              <UI.AttendanceIndicator
                className={
                  listSelected.infos?.realized >= listSelected.infos?.total
                    ? "complete"
                    : ""
                }
              >
                <EventNoteOutlined fontSize="inherit" />
                <Tooltip>
                  <TooltipTrigger
                    style={{
                      background: "transparent",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Caption
                      bold
                      css={{
                        color: "$gray900",
                        marginRight: "5px",
                      }}
                    >
                      {listSelected.infos?.realized === undefined ? (
                        <> - </>
                      ) : (
                        <b>{listSelected.infos.realized}</b>
                      )}
                    </Caption>
                    <Caption css={{ color: "$gray700" }}>realizados</Caption>
                  </TooltipTrigger>
                  <TooltipContent aria-label="Progresso realizado">
                    Você realizou {realizedProgress}% dos seus atendimentos
                  </TooltipContent>
                </Tooltip>
              </UI.AttendanceIndicator>

              <UI.AttendanceIndicator
                className={
                  listSelected.infos?.positived === listSelected.infos?.realized
                    ? "complete"
                    : ""
                }
              >
                <AttachMoney fontSize="inherit" />
                <Tooltip>
                  <TooltipTrigger
                    style={{
                      background: "transparent",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Caption
                      bold
                      css={{
                        color: "$gray900",
                        marginRight: "5px",
                      }}
                    >
                      {listSelected.infos?.positived === undefined ? (
                        <> - </>
                      ) : (
                        <b>{listSelected.infos.positived}</b>
                      )}
                    </Caption>
                    <Caption css={{ color: "$gray700" }}>efetivados</Caption>
                  </TooltipTrigger>
                  <TooltipContent aria-label="Progresso efetivado">
                    Você efetivou {positivedProgress}% dos seus atendimentos
                  </TooltipContent>
                </Tooltip>
              </UI.AttendanceIndicator>
            </Flex>

            <Tooltip>
              <TooltipTrigger
                style={{
                  background: "transparent",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Caption
                  bold
                  css={{
                    color: "$gray600",
                  }}
                >
                  {listSelected.infos?.total === undefined ? (
                    <> - </>
                  ) : (
                    <>{formatNumberToDecimal(listSelected.infos.total)}</>
                  )}
                </Caption>
              </TooltipTrigger>
              <TooltipContent aria-label="Progresso realizado">
                Quantidade total de cliente **nesta** lista
              </TooltipContent>
            </Tooltip>
          </UI.AttendanceIndicatorsWrapper>
          <Flex css={{ flexDirection: "column", gap: "$1", mt: "$1" }}>
            <Tooltip>
              <TooltipTrigger style={{ background: "transparent" }}>
                <Progress size="medium" value={Number(realizedProgress)} />
              </TooltipTrigger>
              <TooltipContent aria-label="Progresso realizado">
                Você realizou {realizedProgress}% dos seus atendimentos
              </TooltipContent>
            </Tooltip>
          </Flex>
        </UI.AttendanceInfoWrapper>
      </UI.AttendanceListItem>
    </>
  );
}
