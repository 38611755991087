import { AttendanceSituationStatus } from "@/core/models/client.model";
import { useByAttendanceSituationStatus } from "@/hooks/useByAttendanceSituationStatus";
import { Caption } from "@gogeo-io/ui-library";
import styled from "styled-components";

interface ClientStatusProps {
  status: AttendanceSituationStatus;
}

export default function ClientAttendanceStatus({ status }: ClientStatusProps) {
  const { getIconByStatus, getColorByStatus } =
    useByAttendanceSituationStatus();

  return (
    <StatusPill style={{ backgroundColor: getColorByStatus(status.code) }}>
      <StatusIcon>{getIconByStatus(status.code)}</StatusIcon>
      <Caption css={{ color: "$white" }}>{status.description}</Caption>
    </StatusPill>
  );
}

export const StatusPill = styled.div`
  display: inline-flex;
  align-items: center;
  text-align: center;
  justify-content: flex-start;
  height: 2rem;
  padding-inline: 0.75rem;
  font-size: 0.75rem;
  line-height: 1;
  border-radius: 999rem;
  color: white;
  width: fit-content;
`;

export const StatusIcon = styled.div`
  margin-right: 0.25rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
`;
