import styled from "styled-components";

export const ListDivider = styled.div<{
  isSkeleton?: boolean;
}>`
  display: inline-flex;
  width: 100%;
  height: 2px;
  background-color: ${(props) =>
    props.isSkeleton ? props.theme.colors.gray50 : props.theme.colors.gray100};
`;
