import { FilterTrigger } from "@/components/advancedFilters/FilterTrigger";
import { AdvancedFilter } from "@/core/models/advanced-filters.model";
import { useFormatPrice } from "@/hooks/useFormatPrice";
import { useEffect, useState } from "react";

interface CurrencyFilterTriggerProps {
  filter: AdvancedFilter;
  value: string;
  onRemoveFilter?: (filter: AdvancedFilter) => void;
  notShowCloseIcon?: boolean;
}

export function CurrencyFilterTrigger({
  filter,
  value,
  onRemoveFilter,
  notShowCloseIcon,
}: CurrencyFilterTriggerProps) {
  const { getMinMaxFromValue, formatBRLPrice } = useFormatPrice();

  const [minValue, setMinValue] = useState<number>(0);
  const [maxValue, setMaxValue] = useState<number>(0);
  const [title, setTitle] = useState("");

  useEffect(() => {
    setMinValue(getMinMaxFromValue(value)?.min ?? 0);
    setMaxValue(getMinMaxFromValue(value)?.max ?? 0);
  }, [value]);

  useEffect(() => {
    setTitle(`${formatBRLPrice(minValue)} & ${formatBRLPrice(maxValue)}`);
  }, [minValue, maxValue]);

  return (
    <FilterTrigger
      value={title}
      filter={filter}
      onRemoveFilter={onRemoveFilter}
      notShowCloseIcon={notShowCloseIcon}
    />
  );
}
