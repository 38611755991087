import { ListOfTags } from "@/core/models/listOfTags.model";
import {
  Button,
  Caption,
  DialogClose,
  Flex,
  Input,
} from "@gogeo-io/ui-library";
import { format } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import { useState } from "react";

interface SaveAsProps {
  selectedList: ListOfTags;
  onSaveAsList: (list_name: string) => void;
}

export function SaveAsDialog({ selectedList, onSaveAsList }: SaveAsProps) {
  const [newListName, setNewListName] = useState(
    `${selectedList.name} - cópia em ${format(
      new Date(),
      "dd/MM/yyyy 'às' hh:mm:ss'h'",
      { locale: ptBR }
    )}`
  );

  const handleSaveList = () => {
    onSaveAsList(newListName);
  };

  return (
    <Flex css={{ flexDirection: "column", gap: "$4" }}>
      <Flex css={{ flexDirection: "column", gap: "$1", mt: "$2" }}>
        <Caption style={{ display: "block" }}>
          Digite um nome para sua nova lista:
        </Caption>
        <Input
          placeholder="Ex. Lista de Adimplentes"
          value={newListName}
          onChange={(e) => setNewListName(e.target.value)}
        />
      </Flex>

      <Flex
        css={{
          gap: "$2",
          alignItems: "flex-end",
          justifyContent: "flex-end",
          mt: "$4",
        }}
      >
        <DialogClose asChild>
          <Button bordered>Cancelar</Button>
        </DialogClose>
        <DialogClose>
          <Button onClick={handleSaveList}>Salvar como</Button>
        </DialogClose>
      </Flex>
    </Flex>
  );
}
