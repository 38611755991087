import { WhatsappClientDialog } from "@/components/WhatsappClientDialog";
import { Client } from "@/core/models/client.model";
import { User } from "@/core/models/user.model";
import { getPreDefinedMessages } from "@/features/profile/store/preDefinedMessagesSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Button, Flex } from "@gogeo-io/ui-library";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import styled from "styled-components";

interface ClientContactProps {
  client: Client;
  user: User;
}

export function ClientProfileContact({ client, user }: ClientContactProps) {
  const dispatch = useAppDispatch();

  return (
    <ContactArea>
      <WhatsappClientDialog
        client={client}
        user={user}
        phone={client.main_phone}
      >
        <Button
          color="primary"
          variant="ghost"
          bordered
          onClick={() =>
            dispatch(
              getPreDefinedMessages({
                page: 0,
                size: import.meta.env.VITE_PAGE_SIZE,
              })
            )
          }
        >
          <Flex css={{ alignItems: "center", gap: "$1" }}>
            <WhatsAppIcon color="inherit" fontSize="inherit" />
            Whatsapp
          </Flex>
        </Button>
      </WhatsappClientDialog>
      {/* <Dialog>
        <DialogTrigger asChild>
          <Button color="primary" variant="ghost" bordered>
            <Flex css={{ alignItems: "center", gap: "$1" }}>
              <SpeedIcon color="inherit" fontSize="inherit" />
              Termômetro do cliente
            </Flex>
          </Button>
        </DialogTrigger>
        <DialogContent style={{ width: "400px" }}>
          <DialogTitle>Prioridade do cliente</DialogTitle>

          <DialogClose asChild>
            <CloseIcon
              style={{ position: "absolute", top: "1rem", right: "1rem" }}
            />
          </DialogClose>

          <Flex css={{ marginTop: "$5", width: "100%", gap: "$2" }}>
            <Text css={{ color: "$gray500" }}>Baixa</Text>
            <Thermomether />
            <Text css={{ color: "$gray500" }}>Alta</Text>
          </Flex>
        </DialogContent>
      </Dialog> */}
    </ContactArea>
  );
}

export const ContactArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.space[3]};
  font-size: 18px;
`;
