import styled from "styled-components";

export const UserRole = styled.div`
  border-radius: 4px;
  padding: 0.5rem 1rem;
  background: ${(props) => props.theme.colors.blue100};
  color: ${(props) => props.theme.colors.blue500};
  font-weight: bold;
  text-transform: capitalize;
`;
