import { ReduxThunkError } from "@/core/errors/ReduxThunkError";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";
import { Feedback } from "../../../core/models/feedback.model";
import { RootState } from "../../../core/store/store";

interface FeedbackState {
  feedback: Feedback;
  status: "loading" | "success" | "failed";
}

const initialState = {
  feedback: {},
  status: "loading",
} as FeedbackState;

interface FeedbackProps {
  feedback: Feedback;
  client_id: string;
}

export const createFeedback = createAsyncThunk(
  "feedback/createFeedback",
  async ({ feedback, client_id }: FeedbackProps, thunkAPI) => {
    const data = { client_id, ...feedback };

    try {
      const response = await axios.post(`/feedback/insert`, data);
      if (
        response.status !== 200 &&
        response.status !== 201 &&
        response.status !== 202
      ) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

export const updateFeedback = createAsyncThunk(
  "feedback/updateFeedback",
  async ({ feedback, client_id }: FeedbackProps, thunkAPI) => {
    const data = { client_id, ...feedback };

    try {
      const response = await axios.put(`/feedback/update/${data.id}`, data);
      if (response.status !== 200 && response.status !== 204) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

export const feedbackSlice = createSlice({
  name: "feedbackSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createFeedback.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(createFeedback.fulfilled, (state, action) => {
      state.status = "success";
    });
    builder.addCase(
      createFeedback.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = "failed";
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error creating the feedback");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
    builder.addCase(updateFeedback.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(updateFeedback.fulfilled, (state, action) => {
      state.status = "success";
    });
    builder.addCase(
      updateFeedback.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = "failed";
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error updating the feedback");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
  },
});

export const selectFeedback = (state: RootState) =>
  state.feedbackState.feedback;

export const feedbackReducer = feedbackSlice.reducer;
