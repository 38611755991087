import { ClientsTableSkeletonPage } from "@/components/Skeleton/ClientsTableSkeletonPage";
import { TrackingEvents } from "@/config/mixpanel";
import { Breadcrumb } from "@/core/models/breadcrumb";
import { SearchFilters } from "@/core/models/listOfTags.model";
import { setBreadcrumbs } from "@/core/store/breadcrumbs/breadcrumbsSlice";
import { AdvancedFiltersToParams } from "@/features/attendance/components/advancedFilters/AdvancedFiltersToParams";
import {
  addTagsOnSelectedClientFilter,
  setSelectedAdvancedFilters,
} from "@/features/attendance/store/clientAdvancedFiltersSlice";
import { emitEventToChangeClients } from "@/features/attendance/store/emitEventToChangeClientsSlice";
import { getListOfAllClients } from "@/features/attendance/store/listOfTagsSlice";
import { selectListToEdit } from "@/features/attendance/store/listSelectedSlice";
import { setSelectedSearchFilters } from "@/features/attendance/store/selectedSearchFiltersSlice";
import {
  getClientAttrOpts,
  setAttributesToFilter,
  setDefaultAttributesToFilter,
} from "@/features/clientInfo/store/clientAttrOptsSlice";
import {
  cleanColumnsToSort,
  setColumnsToSort,
} from "@/features/clientInfo/store/clientColumnsToSortSlice";
import { getClientMatchTypes } from "@/features/clientInfo/store/clientSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { useMixpanel } from "@/hooks/useMixpanel";
import { PageLayout } from "@/layouts/PageLayout";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

export function ParamsPage() {
  const [findListStatus, setFindListStatus] = useState("loading");

  const { pageViewed } = useMixpanel();
  const dispatch = useAppDispatch();

  useEffect(() => {
    pageViewed(TrackingEvents.PARAMS_PAGE);

    async function loadBreadcrumbs() {
      const breadcrumbs: Breadcrumb[] = [
        { item: "Parâmetros de Atendimento", url: "/params/clients" },
      ];
      await dispatch(setBreadcrumbs(breadcrumbs));
    }

    async function loadClientAttrOpts() {
      await dispatch(getClientAttrOpts());
    }

    async function loadMatchTypes() {
      await dispatch(getClientMatchTypes());
    }

    async function loadList() {
      await dispatch(getListOfAllClients()).then(async (obj) => {
        if (obj.meta.requestStatus === "fulfilled") {
          await dispatch(emitEventToChangeClients(false));

          const myClientsList = obj.payload;
          await dispatch(selectListToEdit(myClientsList));

          const columnsToSort = myClientsList.filters?.sort?.orders;
          if (columnsToSort !== undefined && columnsToSort.length > 0) {
            await dispatch(setColumnsToSort(columnsToSort));
          } else {
            await dispatch(cleanColumnsToSort());
          }

          const advancedFilters = myClientsList.filters?.advanced_filters;
          if (advancedFilters !== undefined && advancedFilters.length > 0) {
            await dispatch(setSelectedAdvancedFilters(advancedFilters));
          } else {
            await dispatch(setSelectedAdvancedFilters([]));
          }

          const tags = myClientsList.filters?.tags;
          if (tags !== undefined && tags.length > 0) {
            await dispatch(addTagsOnSelectedClientFilter(tags));
          } else {
            await dispatch(addTagsOnSelectedClientFilter([]));
          }

          const attrOpts = myClientsList.filters?.attr_opts;
          if (attrOpts !== undefined) {
            await dispatch(setAttributesToFilter(attrOpts));
          } else {
            await dispatch(setDefaultAttributesToFilter());
          }

          const searchFilters: SearchFilters = {
            match_type: "Geral",
            match_value: "",
          };

          await dispatch(setSelectedSearchFilters(searchFilters));

          await dispatch(emitEventToChangeClients(true));

          setFindListStatus("fulfilled");
        }
      });
    }

    loadBreadcrumbs();
    loadClientAttrOpts();
    loadMatchTypes();
    loadList();
  }, []);

  return (
    <PageLayout
      leftSidebar={<AdvancedFiltersToParams />}
      content={
        <>
          {findListStatus === "fulfilled" ? (
            <Outlet />
          ) : (
            <ClientsTableSkeletonPage />
          )}
        </>
      }
    />
  );
}
