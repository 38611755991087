import { selectUser } from "@/core/store/users/usersSlice";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

export function RedirectFiltersPage() {
  const user = useSelector(selectUser);

  const location = useLocation();
  const navigateSearch = useNavigate();

  useEffect(() => {
    const my_clients_list_slug = `${user.account_id}_${user.id}_meus_clientes`;

    navigateSearch(
      `/attendance/${my_clients_list_slug}/filters${location.search}`
    );
  });

  return <></>;
}
