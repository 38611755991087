import styled from "styled-components";

export const TagsListItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.7rem;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 4px;
  background: ${(props) => props.theme.colors.gray50};
  position: relative;
  padding-left: 4px;
  cursor: pointer;
  top: 0;
  right: 0;
  margin-bottom: -10px;
  border-radius: 4px;
  transition: all 150ms ease-out;
  border-radius: 6px;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    width: 2px;
    height: 100%;
    background: transparent;
    transition: all 150ms ease-out;
  }

  &.active {
    background: ${(props) => props.theme.colors.green50};

    &::before {
      background: ${(props) => props.theme.colors.primary400};
    }
  }
`;
