import { gvTheme } from "@gogeo-io/ui-library";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import CheckIcon from "@mui/icons-material/Check";

export const useByAttendanceSituationStatus = () => {
  const getIconByStatus = (code: number) => {
    if (code === undefined) {
      return <></>;
    } else {
      if (code === 0) {
        return <AccessTimeIcon color="inherit" fontSize="inherit" />;
      } else if (code >= 10 && code < 100) {
        return <AutorenewIcon color="inherit" fontSize="inherit" />;
      } else if (code >= 100) {
        return <CheckIcon color="inherit" fontSize="inherit" />;
      } else {
        return <AccessTimeIcon color="inherit" fontSize="inherit" />;
      }
    }
  };

  const getColorByStatus = (code: number) => {
    if (code === undefined) {
      return gvTheme.colors.gray500;
    } else {
      if (code === 0) {
        return gvTheme.colors.gray500;
      } else if (code >= 10 && code < 100) {
        return gvTheme.colors.yellow500;
      } else if (code >= 100) {
        return gvTheme.colors.blue500;
      } else {
        return gvTheme.colors.gray500;
      }
    }
  };

  return {
    getIconByStatus,
    getColorByStatus,
  };
};
