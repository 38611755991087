import { SelectOption } from "@/core/models/advanced-filters.model";
import { ReactNode, useState } from "react";
import { GenericFilterPopover } from "../GenericFilterPopover";
import { SelectGenericOption } from "./SelectGenericOption";

interface GenericDateFilterProps {
  children?: ReactNode;
  filterId: number;
  value: SelectOption;
  options: SelectOption[];
  onUpdatedFilter: (filterId: number, newOption: SelectOption) => void;
}

interface FilterToChange {
  filterId: number;
  option: SelectOption;
}

export function GenericSelectFilter({
  children,
  filterId,
  value,
  options,
  onUpdatedFilter,
}: GenericDateFilterProps) {
  const [isOpen, setIsOpen] = useState(false);

  const [filterChanged, setFilterChanged] = useState<FilterToChange>(
    {} as FilterToChange
  );

  const handleOptionSelected = async (option: SelectOption) => {
    const filter: FilterToChange = { filterId, option };
    setFilterChanged(filter);
  };

  const handlePopoverOpenChange = (value: boolean) => {
    setIsOpen(value);
  };

  const openPopover = () => {
    setIsOpen(true);
  };

  const closePopover = () => {
    setIsOpen(false);
  };

  const applyFilter = () => {
    onUpdatedFilter(filterChanged.filterId, filterChanged.option);
    setFilterChanged({} as FilterToChange);
    closePopover();
  };

  return (
    <GenericFilterPopover
      trigger={children}
      popoverIsOpen={isOpen}
      onOpenPopover={openPopover}
      onPopoverOpenChange={handlePopoverOpenChange}
    >
      <SelectGenericOption
        optionSelected={value}
        setOptionSelected={handleOptionSelected}
        options={options}
        onApplyFilter={applyFilter}
      />
    </GenericFilterPopover>
  );
}
