import {
  AttrOpts,
  MatchTypes,
  SearchFilters,
} from "@/core/models/listOfTags.model";
import { SelectedTagsBasedOnSelectedFilters } from "@/features/attendance/components/SelectedTagsBasedOnSelectedFilters";
import { emitEventToChangeClients } from "@/features/attendance/store/emitEventToChangeClientsSlice";
import {
  selectSelectedSearchFilters,
  setSelectedSearchFilters,
} from "@/features/attendance/store/selectedSearchFiltersSlice";
import {
  removeAttributeToFilter,
  selectAttrOptsToFilter,
  selectClientAttrOpts,
  setAttributeToFilter,
} from "@/features/clientInfo/store/clientAttrOptsSlice";
import { selectMatchTypes } from "@/features/clientInfo/store/matchTypesSlice";
import { useClientsMixpanel } from "@/features/clients/hooks/useClientsMixpanel";
import useAppDispatch from "@/hooks/useAppDispatch";
import { useByPlaceholder } from "@/hooks/useByPlaceholder";
import {
  Checkbox,
  Flex,
  IconButton,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Select,
  SelectItem,
  SelectItemText,
  Text,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@gogeo-io/ui-library";
import { FilterAlt } from "@mui/icons-material";
import { debounce, isEmpty, isNull, isUndefined } from "lodash";
import { useState } from "react";
import { useSelector } from "react-redux";

export function ParamsTableFilterSearch() {
  const match_types = useSelector(selectMatchTypes);
  const selectedSearchFilters = useSelector(selectSelectedSearchFilters);
  const client_attributes = useSelector(selectClientAttrOpts);
  const attributes_to_filter = useSelector(selectAttrOptsToFilter);

  const [filterOptionSelected, setFilterOptionSelected] = useState<MatchTypes>(
    selectedSearchFilters.match_type
  );
  const [searchTerm, setSearchTerm] = useState(
    selectedSearchFilters.match_value
  );

  const { buildPlaceholder } = useByPlaceholder();
  const { searchClientEvent } = useClientsMixpanel();
  const dispatch = useAppDispatch();

  const handleInputChange = async (value: string) => {
    await dispatch(emitEventToChangeClients(false));
    setSearchTerm(value);

    const searchFilters: SearchFilters = {
      match_type: filterOptionSelected,
      match_value: value,
    };

    await dispatch(setSelectedSearchFilters(searchFilters));
    await dispatch(emitEventToChangeClients(true));

    searchClientEvent(value, filterOptionSelected);
  };

  const handleSelectFilterOptionChange = async (value: MatchTypes) => {
    await dispatch(emitEventToChangeClients(false));
    setFilterOptionSelected(value);

    const searchFilters: SearchFilters = {
      match_type: value,
      match_value: searchTerm,
    };

    if (searchTerm !== "") {
      await dispatch(setSelectedSearchFilters(searchFilters));
      await dispatch(emitEventToChangeClients(true));
    }
  };

  const debounceOnChangeInput = debounce(handleInputChange, 500);

  const handleCheckboxChange = async (value: boolean, attribute: AttrOpts) => {
    await dispatch(emitEventToChangeClients(false));

    value
      ? await dispatch(setAttributeToFilter(attribute))
      : await dispatch(removeAttributeToFilter(attribute));

    await dispatch(emitEventToChangeClients(true));
  };

  const attributesToFilterHasAttribute = (attribute: AttrOpts): boolean => {
    return (
      attributes_to_filter.filter(
        (a) =>
          !isUndefined(a) && !isNull(a) && !isEmpty(a) && a.id === attribute.id
      ).length > 0
    );
  };

  const cannotShowMoreColumns = (attribute: AttrOpts) =>
    attributes_to_filter.length === 5 &&
    !attributesToFilterHasAttribute(attribute);

  return (
    <Flex css={{ alignItems: "center", justifyContent: "space-between" }}>
      <Flex css={{ gap: "$4", alignItems: "center" }}>
        <Input
          defaultValue={searchTerm}
          placeholder={buildPlaceholder(filterOptionSelected)}
          style={{ width: "240px" }}
          onChange={(e) => debounceOnChangeInput(e.target.value)}
        />
        <Select
          defaultValue={filterOptionSelected}
          ariaLabel="Select filter"
          onValueChange={(e) => handleSelectFilterOptionChange(e as MatchTypes)}
        >
          {match_types.map((match) => {
            return (
              <SelectItem key={match} value={match}>
                <SelectItemText>{match}</SelectItemText>
              </SelectItem>
            );
          })}
        </Select>
        <Popover>
          <PopoverTrigger>
            <IconButton type="ghost">
              <FilterAlt />
            </IconButton>
          </PopoverTrigger>
          <PopoverContent align="center">
            <Flex css={{ flexDirection: "column", gap: "0.5rem" }}>
              {client_attributes.map((attribute) => {
                return (
                  <Flex key={attribute.id} css={{ gap: "0.5rem" }}>
                    {cannotShowMoreColumns(attribute) ? (
                      <Tooltip>
                        <TooltipTrigger style={{ background: "transparent" }}>
                          <Checkbox disabled />
                        </TooltipTrigger>
                        <TooltipContent aria-label="Você pode escolher no máximo 2 colunas">
                          Você pode escolher no máximo 5 colunas, remove uma
                          pelo menos para selecionar outra(s).
                        </TooltipContent>
                      </Tooltip>
                    ) : (
                      <Checkbox
                        checked={
                          attributes_to_filter.filter(
                            (a) => a.id === attribute.id
                          ).length > 0
                        }
                        onCheckedChange={(e) =>
                          handleCheckboxChange(Boolean(e), attribute)
                        }
                      />
                    )}

                    <Text>{attribute.name}</Text>
                  </Flex>
                );
              })}
            </Flex>
          </PopoverContent>
        </Popover>
        <SelectedTagsBasedOnSelectedFilters />
      </Flex>
    </Flex>
  );
}
