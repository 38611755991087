import { MixpanelTracking, TrackingEvents } from "@/config/mixpanel";
import { Client } from "@/core/models/client.model";
import { Feedback } from "@/core/models/feedback.model";
import { BuildMixpanelProps } from "@/utils/buildMixpanelProps";

export const useClientInfoMixpanel = () => {
  const buildProps = BuildMixpanelProps();

  const sendedMessageWhatsappEvent = (
    client: Client,
    phone: string,
    text: string
  ) => {
    MixpanelTracking.getInstance().track(TrackingEvents.CLIENT_SENDED_MESSAGE, {
      ...buildProps("client"),
      client,
      phone,
      text,
    });
  };

  const sendedEmailToClientEvent = (email: string) => {
    MixpanelTracking.getInstance().track(TrackingEvents.CLIENT_OPENED_MAIL, {
      ...buildProps("client"),
      client_email: email,
    });
  };

  const registeredFeedbackOpenedEvent = () => {
    MixpanelTracking.getInstance().track(
      TrackingEvents.CLIENT_OPENED_REGISTER_ATTENDANCE,
      {
        ...buildProps("client"),
      }
    );
  };

  const registeredFeedbackEvent = (feedback: Feedback) => {
    MixpanelTracking.getInstance().track(
      TrackingEvents.CLIENT_FEEDBACK_CREATED,
      {
        ...buildProps("client"),
        feedback,
      }
    );
  };

  const rescheduleClientEvent = (date: string) => {
    MixpanelTracking.getInstance().track(TrackingEvents.CLIENT_RESCHEDULED, {
      ...buildProps("client"),
      RescheduleTo: date,
    });
  };

  const clientProfileParametersClickedEvent = () => {
    MixpanelTracking.getInstance().track(TrackingEvents.CLIENT_OPENED_PARAMS, {
      ...buildProps(),
    });
  };

  const clientProfileContactDateClickedEvent = () => {
    MixpanelTracking.getInstance().track(
      TrackingEvents.CLIENT_OPENED_CONTACT_DATA,
      {
        ...buildProps(),
      }
    );
  };

  const clientProfileFinancialDataClickedEvent = () => {
    MixpanelTracking.getInstance().track(
      TrackingEvents.CLIENT_OPENED_FINANCIAL_DATA,
      {
        ...buildProps(),
      }
    );
  };

  const clientProfileActivityAreaDataClickedEvent = () => {
    MixpanelTracking.getInstance().track(
      TrackingEvents.CLIENT_OPENED_ACTIVITY_AREA_DATA,
      {
        ...buildProps(),
      }
    );
  };

  return {
    sendedMessageWhatsappEvent,
    sendedEmailToClientEvent,
    registeredFeedbackEvent,
    rescheduleClientEvent,
    clientProfileParametersClickedEvent,
    clientProfileContactDateClickedEvent,
    clientProfileFinancialDataClickedEvent,
    registeredFeedbackOpenedEvent,
    clientProfileActivityAreaDataClickedEvent,
  };
};
