import { TagLabel } from "@/components/TagLabel";
import { Tag } from "@/core/models/tag.model";
import useAppDispatch from "@/hooks/useAppDispatch";
import {
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@gogeo-io/ui-library";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAttendanceMixpanel } from "../hooks/useAttendanceMixpanel";
import {
  removeTagOnSelectedClientFilter,
  selectSelectedTagsOnClientFilters,
} from "../store/clientAdvancedFiltersSlice";
import { emitEventToChangeClients } from "../store/emitEventToChangeClientsSlice";
import {
  selectSelectedList,
  selectSelectedListToEdit,
} from "../store/listSelectedSlice";

export function SelectedTagsBasedOnSelectedFilters() {
  const selectedTagsOnClientFilters = useSelector(
    selectSelectedTagsOnClientFilters
  );
  const selectedList = useSelector(selectSelectedList);
  const selectedListToEdit = useSelector(selectSelectedListToEdit);

  const [threeFirstTags, setThreeFirstTags] = useState<Tag[]>([]);
  const [othersTags, setOthersTags] = useState<Tag[]>([]);

  const dispatch = useAppDispatch();
  const { tagRemovedOnListEvent } = useAttendanceMixpanel();

  const removeTag = async (tag: Tag) => {
    await dispatch(emitEventToChangeClients(false));

    await dispatch(removeTagOnSelectedClientFilter(tag));
    tagRemovedOnListEvent(tag, {}, selectedList);

    await dispatch(emitEventToChangeClients(true));
  };

  useEffect(() => {
    if (!isEmpty(selectedListToEdit)) {
      setThreeFirstTags(
        selectedTagsOnClientFilters && selectedTagsOnClientFilters.slice(0, 3)
      );
      setOthersTags(
        selectedTagsOnClientFilters && selectedTagsOnClientFilters.slice(3)
      );
    } else {
      setThreeFirstTags(
        selectedList.filters.tags && selectedList.filters.tags.slice(0, 3)
      );
      setOthersTags(
        selectedList.filters.tags && selectedList.filters.tags.slice(3)
      );
    }
  }, [selectedList, selectedTagsOnClientFilters, selectedListToEdit]);

  return (
    <Flex css={{ gap: "$2" }}>
      <>
        {threeFirstTags &&
          threeFirstTags.map((tag) => {
            return (
              <TagLabel
                key={tag.id}
                isSelected
                hasCloseButton={!isEmpty(selectedListToEdit)}
                onClickCloseButton={() => removeTag(tag)}
              >
                {tag.label}
              </TagLabel>
            );
          })}
        {othersTags && othersTags.length > 0 && (
          <Popover>
            <PopoverTrigger>
              <TagLabel style={{ fontWeight: "bold", cursor: "pointer" }}>
                + {othersTags.length}
              </TagLabel>
            </PopoverTrigger>
            <PopoverContent align="center">
              <Flex css={{ flexWrap: "wrap", gap: "0.5rem" }}>
                {othersTags.map((tag) => {
                  return (
                    <TagLabel
                      key={tag.id}
                      isSelected
                      hasCloseButton
                      onClickCloseButton={() => removeTag(tag)}
                    >
                      {tag.label}
                    </TagLabel>
                  );
                })}
              </Flex>
            </PopoverContent>
          </Popover>
        )}
      </>
    </Flex>
  );
}
