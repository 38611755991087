import { FeedbackMapping } from "@/core/models/feedbackMapping.model";
import styled from "styled-components";
import { TagLabel } from "../../../../components/TagLabel";

type TagCloudFeedbackMappingProps = {
  feedbackMapping: FeedbackMapping[];
  feedbackSelected: number;
  onFeedbackSelected?: (feedback_id: number) => void;
};

export function TagCloudFeedbackMapping({
  feedbackMapping,
  feedbackSelected,
  onFeedbackSelected,
}: TagCloudFeedbackMappingProps) {
  return (
    <TagCloudWrapper>
      {feedbackMapping.map((feedback) => {
        return (
          <TagLabel
            selectable
            isSelected={feedbackSelected === feedback.feedback_id}
            onClick={() =>
              onFeedbackSelected !== undefined &&
              onFeedbackSelected(feedback.feedback_id)
            }
            key={feedback.feedback_id}
          >
            {feedback.feedback_message}
          </TagLabel>
        );
      })}
    </TagCloudWrapper>
  );
}

export const TagCloudWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`;
