import { ListOfTags } from "@/core/models/listOfTags.model";
import {
  Button,
  Caption,
  DialogClose,
  Flex,
  Input,
} from "@gogeo-io/ui-library";
import { useState } from "react";

interface SaveAsProps {
  selectedList: ListOfTags;
  onChangeName: (list_name: string) => void;
}

export function NewListNameModal({ selectedList, onChangeName }: SaveAsProps) {
  const [newListName, setNewListName] = useState(selectedList.name);

  const handleSaveList = () => {
    onChangeName(newListName);
  };

  return (
    <Flex css={{ flexDirection: "column", gap: "$4" }}>
      <Flex css={{ flexDirection: "column", gap: "$1", mt: "$2" }}>
        <Caption style={{ display: "block" }}>
          Digite um nome para sua nova lista:
        </Caption>
        <Input
          placeholder="Ex. Lista de Adimplentes"
          value={newListName}
          onChange={(e) => setNewListName(e.target.value)}
          onClick={async (event) => {
            event.stopPropagation();
          }}
        />
      </Flex>

      <Flex
        css={{
          gap: "$2",
          alignItems: "flex-end",
          justifyContent: "flex-end",
          mt: "$4",
        }}
      >
        <DialogClose asChild>
          <Button bordered>Cancelar</Button>
        </DialogClose>
        <DialogClose>
          <Button onClick={handleSaveList} disabled={newListName === ""}>
            Salvar
          </Button>
        </DialogClose>
      </Flex>
    </Flex>
  );
}
