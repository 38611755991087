import { ClientsTableSkeletonPage } from "@/components/Skeleton/ClientsTableSkeletonPage";
import { ClientParamsResponse } from "@/core/models/client.model";
import { ListOfTagsFilter, SortOrder } from "@/core/models/listOfTags.model";
import {
  selectColumnsToSort,
  setColumnsToSort,
} from "@/features/clientInfo/store/clientColumnsToSortSlice";
import { ClientsTable } from "@/features/clients/components/ClientsTable";
import { SearchResultText } from "@/features/clients/components/SearchResultText";
import { useBreadcrumbsForClientsListPage } from "@/features/clients/hooks/useBreadcrumbsForClientsListPage";
import { useClientsColumns } from "@/features/clients/hooks/useClientsColumns";
import { useClientsMixpanel } from "@/features/clients/hooks/useClientsMixpanel";
import { useGetClientsByFiltersParams } from "@/features/clients/hooks/useGetClientsByFiltersParams";
import {
  getClientsByFilters,
  getClientsCount,
} from "@/features/clients/store/clientsSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { isEmpty, isUndefined } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  emitEventToChangeClients,
  selectStatusOfEmitEventToChangeClients,
} from "../store/emitEventToChangeClientsSlice";
import {
  selectSelectedList,
  selectSelectedListToEdit,
} from "../store/listSelectedSlice";

export function ListViewOfClients() {
  const [hasMorePages, setHasMorePages] = useState(true);
  const [clients, setClients] = useState<ClientParamsResponse[]>([]);
  const [clientStatus, setClientsStatus] = useState("loading");
  const [clientsCount, setClientsCount] = useState("-");
  const [currentPage, setCurrentPage] = useState(0);
  const [loadedPage, setLoadedPage] = useState(false);

  const selectedList = useSelector(selectSelectedList);
  const selectedListToEdit = useSelector(selectSelectedListToEdit);
  const statusOfEmitEventToChangeClients = useSelector(
    selectStatusOfEmitEventToChangeClients
  );
  const selectedColumnsToSort = useSelector(selectColumnsToSort);

  const { showBreadcrumbs } = useBreadcrumbsForClientsListPage();
  const { loadClientsEvent } = useClientsMixpanel();
  const { paramsOnListView, paramsOnEditListView } =
    useGetClientsByFiltersParams();
  const dispatch = useAppDispatch();

  const handleIncreasePageNumber = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handleSetSort = async (
    direction: "ASC" | "DESC" | "",
    property: string
  ) => {
    await dispatch(emitEventToChangeClients(false));

    const newColumn: SortOrder = { direction, index: 0, property };

    if (selectedColumnsToSort.length === 0) {
      await dispatch(setColumnsToSort([newColumn]));
    } else {
      const findedColumn = selectedColumnsToSort.find(
        (selectedColum) => selectedColum.property === newColumn.property
      );

      let columnsWithNewColumn: SortOrder[] = [];

      if (isUndefined(findedColumn)) {
        columnsWithNewColumn = [newColumn, ...selectedColumnsToSort];
      } else {
        const columnsWithoutRemovedOne = selectedColumnsToSort.filter(
          (selectedColum) => selectedColum.property !== newColumn.property
        );
        columnsWithNewColumn = [newColumn, ...columnsWithoutRemovedOne];
      }

      const columnsWithoutEmptyDirection = columnsWithNewColumn.filter(
        (selectedColum) => selectedColum.direction !== ""
      );

      const newOrderOfColumns = columnsWithoutEmptyDirection.map(
        (col, idx) => col && { ...col, index: idx }
      );

      await dispatch(setColumnsToSort(newOrderOfColumns));
    }

    await dispatch(emitEventToChangeClients(true));
  };

  useEffect(() => {
    if (statusOfEmitEventToChangeClients) {
      setCurrentPage(0);
      setHasMorePages(true);
      setClientsStatus("loading");
      setClientsCount("-");

      loadClients();
    }
  }, [statusOfEmitEventToChangeClients]);

  useEffect(() => {
    setCurrentPage(0);
    setHasMorePages(true);
    setClientsStatus("loading");
    setClientsCount("-");

    loadClients();
  }, []);

  async function loadClients() {
    let params: ListOfTagsFilter = {};

    if (isEmpty(selectedListToEdit)) {
      params = paramsOnListView();
    } else {
      params = paramsOnEditListView();
    }

    await dispatch(
      getClientsByFilters({
        page: 0,
        size: import.meta.env.VITE_PAGE_SIZE,
        params,
      })
    ).then(async (res) => {
      if (res.payload.clients.length < import.meta.env.VITE_PAGE_SIZE) {
        setHasMorePages(false);
        setClients(res.payload.clients);
      } else {
        setClients(res.payload.clients);
      }
      loadClientsEvent(selectedList, params, res.payload.clients);
      showBreadcrumbs(
        isEmpty(selectedListToEdit) ? selectedList : selectedListToEdit
      );
      setClientsStatus("fulfilled");
      setLoadedPage(true);
    });
    await dispatch(
      getClientsCount({
        params,
      })
    ).then((res) => {
      const count = res.payload;
      setClientsCount(count);
    });
  }

  const getPaginatedClients = async (currentPage: number) => {
    let params: ListOfTagsFilter = {};

    if (isEmpty(selectedListToEdit)) {
      params = paramsOnListView();
    } else {
      params = paramsOnEditListView();
    }

    await dispatch(
      getClientsByFilters({
        page: currentPage,
        size: import.meta.env.VITE_PAGE_SIZE,
        params,
      })
    ).then((res) => {
      if (res.payload.clients.length === 0) {
        setHasMorePages(false);
      } else {
        setClients((prevClients) => [...prevClients, ...res.payload.clients]);
      }
    });
  };

  const clientsColumns = useClientsColumns(handleSetSort);

  return (
    <>
      <SearchResultText count={clientsCount} />

      {loadedPage ? (
        <ClientsTable
          columns={clientsColumns}
          data={clients}
          onPageChange={getPaginatedClients}
          currentPage={currentPage}
          onIncreasePageNumber={handleIncreasePageNumber}
          hasMorePages={hasMorePages}
          status={clientStatus}
        />
      ) : (
        <ClientsTableSkeletonPage />
      )}
    </>
  );
}
