import { setBreadcrumbs } from "@/core/store/breadcrumbs/breadcrumbsSlice";
import { selectUser } from "@/core/store/users/usersSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Caption,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Flex,
  IconButton,
  RightSlot,
  Text,
} from "@gogeo-io/ui-library";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LogoutIcon from "@mui/icons-material/Logout";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const profileItems = [
  {
    name: "Perfil e Preferências",
    url: "/profile/pre-defined-messages",
  },
];

const menuItems = [
  {
    name: "Atendimento",
    url: "/attendance",
  },
  {
    name: "Parâmetros de atendimento",
    url: "/params/clients",
  },
  {
    name: "Relatórios",
    url: "/reports",
  },
];

export function UserMenuWithKeycloak(): JSX.Element {
  const user = useSelector(selectUser);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleLogout = async () => {
    navigate("/logout");
  };

  const handleNavigate = async (url: string) => {
    await dispatch(setBreadcrumbs([]));
    navigate(url);
  };

  const openGoVendasAcademyInAnotherTab = () => {
    window.open(
      "https://academia.govendas.com/",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <UserAvatarMenu>
            <>
              {!isEmpty(user.user_photo) ? (
                <Avatar size="small">
                  {user.user_photo.photo_sm_url ? (
                    <AvatarImage src={user.user_photo.photo_sm_url} />
                  ) : (
                    <AvatarFallback>{user.user_photo.fallback}</AvatarFallback>
                  )}
                </Avatar>
              ) : (
                <Avatar size="small">
                  <AvatarFallback>GO</AvatarFallback>
                </Avatar>
              )}
            </>
            <Flex css={{ flexDirection: "column", gap: "0px" }}>
              <Text size="body2">{user.name}</Text>
              <Caption>{user.email ?? "Não tem e-mail"}</Caption>
            </Flex>
            <IconButton color="gray" type="ghost" size="medium">
              <KeyboardArrowDownIcon fontSize="inherit" />
            </IconButton>
          </UserAvatarMenu>
        </DropdownMenuTrigger>

        <DropdownMenuContent sideOffset={5} style={{ zIndex: "1" }}>
          {menuItems.map((item) => {
            return (
              <DropdownMenuItem
                key={item.name}
                onClick={() => handleNavigate(item.url)}
              >
                {item.name}
              </DropdownMenuItem>
            );
          })}

          <DropdownMenuSeparator />

          {profileItems.map((item) => {
            return (
              <DropdownMenuItem
                key={item.name}
                onClick={() => handleNavigate(item.url)}
              >
                {item.name}
              </DropdownMenuItem>
            );
          })}

          <DropdownMenuItem onClick={openGoVendasAcademyInAnotherTab}>
            Academia GoVendas
          </DropdownMenuItem>

          <DropdownMenuSeparator />

          <DropdownMenuItem onClick={handleLogout}>
            Sair
            <RightSlot>
              <LogoutIcon fontSize="inherit" />
            </RightSlot>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}
export const UserAvatarMenu = styled.div`
  display: flex;
  gap: ${(props) => props.theme.space[2]};
  align-items: center;
  cursor: pointer;
`;
