import { Report } from "@/core/models/reports.model";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Flex, Heading, Text } from "@gogeo-io/ui-library";
import { FiInfo } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";
import { setSelectedReport } from "../store/reportsSlice";
import UI from "../ui";

interface ReportCardProps {
  report: Report;
}

export function ReportCard({ report }: ReportCardProps) {
  const { dashboard_name } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const openReport = async () => {
    await dispatch(setSelectedReport(report));
    navigate(`/reports/${report.dashboard_name}`);
  };

  const openTutorialLink = () => {
    window.open(report.tutorial_link, "_blank", "noreferrer");
  };

  return (
    <UI.ReportCard
      className={report.dashboard_name === dashboard_name ? "active" : ""}
    >
      <Flex
        css={{
          flexDirection: "column",
          padding: "$3",
          gap: "$1",
          flex: 1,
        }}
        onClick={openReport}
      >
        <Heading size="subtitle1">{report.title}</Heading>
        <Text css={{ color: "$gray400" }}>{report.description}</Text>
      </Flex>

      <FiInfo onClick={openTutorialLink} />
    </UI.ReportCard>
  );
}
