import { ReduxThunkError } from "@/core/errors/ReduxThunkError";
import { PaginatedListOfTagsResponse } from "@/core/models/paginatedListOfTags.model";
import { PaginatedRequestBaseProps } from "@/core/models/paginatedRequest.model";
import { setClientsHasError500 } from "@/features/clients/store/clientsSlice";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";
import {
  ListOfTags,
  ListOfTagsFilter,
} from "../../../core/models/listOfTags.model";
import { RootState } from "../../../core/store/store";

interface ListOfTagsState {
  paginatedLists: PaginatedListOfTagsResponse;
  totalListOfTags: number;
  my_clients_list: ListOfTags;
  myClientsListhas500error: boolean;
  has500error: boolean;
  lists_status: "loading" | "fulfilled";
  my_clients_list_status: "loading" | "fulfilled";
}

const initialState = {
  paginatedLists: {},
  my_clients_list: {},
  myClientsListhas500error: false,
  has500error: false,
  lists_status: "loading",
  my_clients_list_status: "loading",
} as ListOfTagsState;

interface GetListsOfTagsProps extends PaginatedRequestBaseProps {
  query?: string;
}

interface ListBaseProps {
  id: string;
}
interface ListSlugBaseProps {
  slug: string;
}

export const getListsOfTags = createAsyncThunk(
  "tags/getListOfTags",
  async ({ page, size, query }: GetListsOfTagsProps, thunkAPI) => {
    try {
      let url = "";
      if (query) {
        url = `/list/search?page=${page}&size=${size}&query=${query}`;
      } else {
        url = `/list/search?page=${page}&size=${size}`;
      }
      const response = await axios.get(url);
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

export interface CreateListOfTagsBody {
  id?: string;
  name: string;
  description?: string;
  filters: ListOfTagsFilter;
}

export const createListOfTags = createAsyncThunk(
  "tags/createListOfTags",
  async (data: CreateListOfTagsBody, thunkAPI) => {
    try {
      const response = await axios.post("/list", data);
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

export const getListBySlug = createAsyncThunk(
  "tags/getListBySlug",
  async (data: ListSlugBaseProps, thunkAPI) => {
    try {
      const response = await axios.get(`/list/slug/${data.slug}`);
      if (response.status !== 200 && response.status !== 202) {
        await thunkAPI.dispatch(setClientsHasError500(true));
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        await thunkAPI.dispatch(setClientsHasError500(true));
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

export const deleteList = createAsyncThunk(
  "tags/deleteList",
  async (data: ListBaseProps, thunkAPI) => {
    try {
      const response = await axios.delete(`/list/${data.id}`);
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

export const fixList = createAsyncThunk(
  "tags/fixList",
  async (data: ListBaseProps, thunkAPI) => {
    try {
      const response = await axios.put(`/list/${data.id}/fix`);
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

export const unfixList = createAsyncThunk(
  "tags/unfixList",
  async (data: ListBaseProps, thunkAPI) => {
    try {
      const response = await axios.put(`/list/${data.id}/unfix`);
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

export const removeBrandNew = createAsyncThunk(
  "tags/removeBrandNew",
  async (data: ListBaseProps, thunkAPI) => {
    try {
      const response = await axios.put(`/list/${data.id}/oldnews`);
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

interface ChangeAllListProps {
  lists: ListOfTags[];
}

export const changeAllList = createAsyncThunk(
  "tags/changeAllList",
  async (data: ChangeAllListProps, thunkAPI) => {
    try {
      const response = await axios.put(`/list/allrefpos`, data.lists);
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

export const getListOfAllClients = createAsyncThunk(
  "tags/getListOfAllClients",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(`/list/allclients`);
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

interface GetIndicatorsProps {
  ids: string[];
}

export const getIndicators = createAsyncThunk(
  "tags/getIndicators",
  async (data: GetIndicatorsProps, thunkAPI) => {
    try {
      const response = await axios.post(`/list/info`, data.ids);
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

export const listOfTagsSlice = createSlice({
  name: "listOfTagsSlice",
  initialState,
  reducers: {
    removeListFromState(state, action: PayloadAction<ListOfTags>) {
      const stateWithoutRemovedOne = state.paginatedLists.lists.filter(
        (list) => list.id !== action.payload.id
      );
      state.paginatedLists.lists = stateWithoutRemovedOne;
    },
    updateListFromState(state, action: PayloadAction<ListOfTags>) {
      state.paginatedLists.lists.forEach((list) =>
        list.id === action.payload.id ? action.payload : list
      );
    },
    fixListFromState(state, action: PayloadAction<ListOfTags>) {
      state.paginatedLists.lists.map((list) =>
        list.id === action.payload.id ? (list.fixed = true) : list
      );
    },
    unfixListFromState(state, action: PayloadAction<ListOfTags>) {
      state.paginatedLists.lists.map((list) =>
        list.id === action.payload.id ? (list.fixed = false) : list
      );
    },
    removedBrandNewFromState(state, action: PayloadAction<ListOfTags>) {
      state.paginatedLists.lists.map((list) =>
        list.id === action.payload.id ? (list.brand_new = false) : list
      );
    },
    setListsStatusToFulfilled(state) {
      state.lists_status = "fulfilled";
    },
    setMyListStatusToFulfilled(state) {
      state.my_clients_list_status = "fulfilled";
    },
    addMyClientsListOnState(state, action: PayloadAction<ListOfTags>) {
      state.my_clients_list = action.payload;
    },
    addListsOnState(state, action: PayloadAction<ListOfTags[]>) {
      state.paginatedLists.lists = action.payload;
    },
    addListOnState(state, action: PayloadAction<ListOfTags>) {
      state.paginatedLists.lists.push(action.payload);
    },
    setTotalListOfTags(state, action: PayloadAction<number>) {
      state.totalListOfTags = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getListsOfTags.pending, (state, action) => {
      state.has500error = false;
    });
    builder.addCase(getListsOfTags.fulfilled, (state, action) => {
      state.has500error = false;
    });
    builder.addCase(
      getListsOfTags.rejected,
      (state, action: PayloadAction<any>) => {
        if (action.payload.status === 500) {
          state.has500error = true;
        }
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setExtras(action.payload);
          scope.setTransactionName("Error getting the list of tags");
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
    builder.addCase(
      createListOfTags.rejected,
      (state, action: PayloadAction<any>) => {
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setExtras(action.payload);
          scope.setTransactionName("Error creating the list with tags");
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
    builder.addCase(
      getListBySlug.rejected,
      (state, action: PayloadAction<any>) => {
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setExtras(action.payload);
          scope.setTransactionName("Error getting the list by slug");
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
    builder.addCase(
      deleteList.rejected,
      (state, action: PayloadAction<any>) => {
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setExtras(action.payload);
          scope.setTransactionName("Error deleting a list");
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
    builder.addCase(
      getIndicators.rejected,
      (state, action: PayloadAction<any>) => {
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setExtras(action.payload);
          scope.setTransactionName("Error getting the indicators from list");
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
    builder.addCase(fixList.rejected, (state, action: PayloadAction<any>) => {
      Sentry.configureScope((scope) => {
        scope.setLevel("error");
        scope.setExtras(action.payload);
        scope.setTransactionName("Error fixing a list");
      });
      Sentry.captureException(new ReduxThunkError(action.payload));
      toast.error(action.payload.errorMessage);
    });
    builder.addCase(unfixList.rejected, (state, action: PayloadAction<any>) => {
      Sentry.configureScope((scope) => {
        scope.setLevel("error");
        scope.setExtras(action.payload);
        scope.setTransactionName("Error unfixing a list");
      });
      Sentry.captureException(new ReduxThunkError(action.payload));
      toast.error(action.payload.errorMessage);
    });
    builder.addCase(
      removeBrandNew.rejected,
      (state, action: PayloadAction<any>) => {
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setExtras(action.payload);
          scope.setTransactionName("Error unfixing a list");
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
    builder.addCase(
      changeAllList.rejected,
      (state, action: PayloadAction<any>) => {
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setExtras(action.payload);
          scope.setTransactionName("Error changing lists");
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
    builder.addCase(
      getListOfAllClients.rejected,
      (state, action: PayloadAction<any>) => {
        if (action.payload.status === 500) {
          state.myClientsListhas500error = true;
        }
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setExtras(action.payload);
          scope.setTransactionName("Error getting all clients list");
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
  },
});

export const selectListsOfTags = (state: RootState) =>
  state.listsOfTagsState.paginatedLists.lists;
export const selectMyListOfTags = (state: RootState) =>
  state.listsOfTagsState.my_clients_list;
export const selectListsStatus = (state: RootState) =>
  state.listsOfTagsState.lists_status;
export const selectMyClientsStatus = (state: RootState) =>
  state.listsOfTagsState.my_clients_list_status;
export const selectTotalListOfTags = (state: RootState) =>
  state.listsOfTagsState.totalListOfTags;
export const selectMyListOfTagsHasError500 = (state: RootState) =>
  state.listsOfTagsState.myClientsListhas500error;
export const selectListsHasError500 = (state: RootState) =>
  state.listsOfTagsState.has500error;

export const {
  setTotalListOfTags,
  addListOnState,
  addListsOnState,
  addMyClientsListOnState,
  fixListFromState,
  removeListFromState,
  removedBrandNewFromState,
  setListsStatusToFulfilled,
  setMyListStatusToFulfilled,
  unfixListFromState,
  updateListFromState,
} = listOfTagsSlice.actions;

export const listsOfTagsReducer = listOfTagsSlice.reducer;
