import { ReduxThunkError } from "@/core/errors/ReduxThunkError";
import { Notification } from "@/core/models/notification.model";
import { RootState } from "@/core/store/store";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";

interface NotificationState {
  notifications: Notification[];
  status: "loading" | "success" | "failed";
}

const initialState = {
  notifications: [],
  status: "loading",
} as NotificationState;

export const getNotifications = createAsyncThunk(
  "notifications/getNotifications",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get("/notifications");
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

interface UpdateNotificationProps {
  id: number;
  isRead: boolean;
}

export const updateNotification = createAsyncThunk(
  "reminotificationsnder/updateNotification",
  async ({ id, isRead }: UpdateNotificationProps, thunkAPI) => {
    try {
      const response = await axios.put(`/notifications/${id}?isRead=${isRead}`);
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

export const notificationSlice = createSlice({
  name: "notificationSlice",
  initialState,
  reducers: {
    readNotification(state, action: PayloadAction<number>) {
      [...state.notifications].filter((notification) =>
        notification.id === action.payload
          ? (notification.read = true)
          : notification
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNotifications.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getNotifications.fulfilled, (state, action) => {
      state.status = "success";
      state.notifications = action.payload;
    });
    builder.addCase(
      getNotifications.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = "failed";
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error getting the notifications");
        });
        Sentry.captureException(action.payload);
        toast.error(action.payload.errorMessage);
      }
    );
    builder.addCase(updateNotification.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(updateNotification.fulfilled, (state, action) => {
      state.status = "success";
    });
    builder.addCase(
      updateNotification.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = "failed";
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error updating the notification status");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
  },
});

export const selectNotifications = (state: RootState) =>
  state.notificationsState.notifications;
export const selectNotificationsStatus = (state: RootState) =>
  state.notificationsState.status;
export const selectCountOfNotificationsNotReaded = (state: RootState) =>
  state.notificationsState.notifications.filter(
    (notification) => !notification.read
  ).length;

export const { readNotification } = notificationSlice.actions;

export const notificationsReducer = notificationSlice.reducer;
