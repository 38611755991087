import { Notification } from "@/core/models/notification.model";
import { useNotificationsMixpanel } from "@/features/notifications/hooks/useNotificationsMixpanel";
import { useNotificationType } from "@/features/notifications/hooks/useNotificationType";
import {
  readNotification,
  selectNotifications,
  updateNotification,
} from "@/features/notifications/store/notificationsSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import CheckIcon from "@mui/icons-material/Check";
import { AnimatePresence } from "framer-motion";
import { useSelector } from "react-redux";
import { TopbarLink, TopbarStyled } from "./styles";

export function Topbar() {
  const { data: notifications, status } = useSelector(selectNotifications);

  const dispatch = useAppDispatch();
  const { notificationReadedEvent } = useNotificationsMixpanel();
  const { getNotificationIcon, getBackgroundColor, getColor } =
    useNotificationType();

  const handleReadNotification = async (notification: Notification) => {
    await dispatch(
      updateNotification({ id: notification.id, isRead: true })
    ).then(async (obj) => {
      if (obj.meta.requestStatus === "fulfilled") {
        notificationReadedEvent(notification.id);
        await dispatch(readNotification(notification.id));
      }
    });
  };

  return (
    <>
      {status === "success" && (
        <AnimatePresence exitBeforeEnter initial>
          {notifications.map((notification) => {
            return (
              <div key={notification.id}>
                {!notification.read && (
                  <TopbarStyled
                    type={notification.color}
                    bgColor={getBackgroundColor(notification.color)}
                    color={getColor(notification.color)}
                    key={notification.id}
                    initial={{ y: -20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -20, opacity: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <div className="message-container">
                      {getNotificationIcon(notification.type)}
                      <TopbarLink
                        color={getColor(notification.color)}
                        onClick={() => handleReadNotification(notification)}
                      >
                        <a href={notification.link}>
                          {notification.description}
                        </a>
                      </TopbarLink>
                    </div>
                    <CheckIcon
                      onClick={() => handleReadNotification(notification)}
                    />
                  </TopbarStyled>
                )}
              </div>
            );
          })}
        </AnimatePresence>
      )}
    </>
  );
}
