import { Params } from "@/core/models/params.model";
import { Text } from "@gogeo-io/ui-library";
import { isEmpty } from "lodash";
import styled from "styled-components";

interface PreferedWeeksProps {
  params: Params;
  isBlocked?: boolean;
}

export function PreferedWeeks({
  params,
  isBlocked = false,
}: PreferedWeeksProps) {
  return (
    <Container isBlocked={isBlocked}>
      <Text>
        {!isEmpty(params) && !isEmpty(params.week_pref) ? (
          <>{`${params.week_pref.range} ${params.week_pref.value}`}</>
        ) : (
          "Não Possui"
        )}
      </Text>
    </Container>
  );
}

export const Container = styled.div<{
  isBlocked: boolean;
}>`
  display: flex;
  cursor: ${({ isBlocked }) => (isBlocked ? "not-allowed" : "pointer")};
`;
