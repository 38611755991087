import { ColumnOnClientsKanban } from "@/features/clients/components/ColumnOnClientsKanban";
import { SearchResultText } from "@/features/clients/components/SearchResultText";
import { Flex } from "@gogeo-io/ui-library";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectSelectedFunnel } from "../store/funnelsSlice";

interface KanbanOfClientsProps {}

export function KanbanViewOfClients({}: KanbanOfClientsProps) {
  const selectedFunnel = useSelector(selectSelectedFunnel);

  const handleDragEnd = async (result) => {
    const { destination, source, draggableId } = result;

    if (source.droppableId == destination.droppableId) return;
  };

  const clientsCount = useMemo(() => {
    return (
      selectedFunnel.kanban_columns !== undefined &&
      selectedFunnel.kanban_columns.reduce(
        (acc, current) => acc + current.clients_count,
        0
      )
    );
  }, [selectedFunnel]);

  return (
    <Flex css={{ flexDirection: "column", height: "calc(100% - 6.2rem)" }}>
      <SearchResultText
        count={clientsCount !== undefined ? clientsCount.toString() : "-"}
      />

      <Flex css={{ gap: "$2", height: "100%" }}>
        {selectedFunnel.kanban_columns !== undefined &&
          selectedFunnel.kanban_columns.map((column) => {
            return (
              <ColumnOnClientsKanban
                key={column.id}
                columnName={column.name}
                columnId={column.id}
                columnCount={column.clients_count}
                columnFilter={column.filter}
                columnIcon={column.icon}
              />
            );
          })}
      </Flex>
    </Flex>
  );
}
