import { TrackingEvents } from "@/config/mixpanel";
import { useMixpanel } from "@/hooks/useMixpanel";
import { useEffect } from "react";
import { ReminderContainer } from "../components/ReminderContainer";

export function Reminders() {
  const { pageViewed } = useMixpanel();

  useEffect(() => {
    pageViewed(TrackingEvents.REMINDERS_PAGE);
  }, []);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <ReminderContainer />
    </div>
  );
}
