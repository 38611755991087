import { Flex, Heading } from "@gogeo-io/ui-library";
import styled from "styled-components";
import UnderConstructionSvg from "../assets/under-construction.svg";

export function UnderConstruction() {
  return (
    <Wrapper>
      <img src={UnderConstructionSvg} alt="sistema fora do ar" />
      <Flex css={{ flexDirection: "column", gap: "$2", textAlign: "center" }}>
        <Heading size="title3" className="title">
          Página em Construção
        </Heading>
        <Heading size="subtitle1">
          Essa página está em construção, não se preocupe, logo você poderá
          usufruir dessa funcionalidade.
        </Heading>
      </Flex>
    </Wrapper>
  );
}

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.space[6]};

  img {
    width: 600px;
  }

  .title {
    color: ${({ theme }) => theme.colors.primary500};
  }
`;
