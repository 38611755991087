import { AdvancedFilter } from "@/core/models/advanced-filters.model";
import { Tooltip, TooltipContent, TooltipTrigger } from "@gogeo-io/ui-library";
import { ReactNode } from "react";

interface FilterContainer {
  filter: AdvancedFilter;
  triggerComponent: ReactNode;
}

export function AdvancedFilterContainer({
  filter,
  triggerComponent,
}: FilterContainer) {
  return (
    <Tooltip>
      <TooltipTrigger style={{ background: "transparent" }}>
        {triggerComponent}
      </TooltipTrigger>
      <TooltipContent aria-label={filter.description}>
        {filter.description}
      </TooltipContent>
    </Tooltip>
  );
}
