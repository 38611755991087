import { Flex } from "@gogeo-io/ui-library";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { BsKanban } from "react-icons/bs";
import { ClientsTypeOfView } from "../pages/ClientsListPage";

interface SelectClientsTypeOfViewProps {
  clientsTypeOfView: ClientsTypeOfView;
  onClientsTypeOfViewChange: (option: ClientsTypeOfView) => void;
}

export function SelectClientsTypeOfView({
  clientsTypeOfView,
  onClientsTypeOfViewChange,
}: SelectClientsTypeOfViewProps) {
  return (
    <Flex css={{ gap: "$2" }}>
      <Flex
        className={clientsTypeOfView === "list" ? "selected" : ""}
        css={{
          width: "30px",
          height: "30px",
          borderRadius: "4px",
          padding: "0.375rem",
          background: "$gray200",
          color: "$gray800",
          alignItems: "center",
          justifyContent: "center",
          fontWeight: "bold",
          cursor: "pointer",
          transition: "all 1s ease",
          "&:hover": {
            filter: "brightness(90%)",
          },
          "&.selected": {
            filter: "brightness(85%)",
          },
        }}
        onClick={() => onClientsTypeOfViewChange("list")}
      >
        <AiOutlineUnorderedList fontSize="inherit" color="inherit" />
      </Flex>
      <Flex
        className={clientsTypeOfView === "kanban" ? "selected" : ""}
        css={{
          width: "30px",
          height: "30px",
          borderRadius: "4px",
          padding: "0.375rem",
          background: "$gray200",
          color: "$gray800",
          alignItems: "center",
          justifyContent: "center",
          fontWeight: "bold",
          cursor: "pointer",
          transition: "all 1s ease",
          "&:hover": {
            filter: "brightness(90%)",
          },
          "&.selected": {
            filter: "brightness(85%)",
          },
        }}
        onClick={() => onClientsTypeOfViewChange("kanban")}
      >
        <BsKanban fontSize="inherit" color="inherit" />
      </Flex>
    </Flex>
  );
}
