import { ListDivider } from "@/components/ListDivider";
import { selectUser } from "@/core/store/users/usersSlice";
import { ClientProfileInfoPhone } from "@/features/attendance/components/ClientProfileInfoPhone";
import { ProfileInfo } from "@/features/attendance/components/ProfileInfo";
import { ClientProfileAvatar } from "@/features/clientInfo/components/ClientProfileAvatar";
import { ClientProfileLastAttendance } from "@/features/clientInfo/components/ClientProfileLastAttendance";
import { ClientProfileTags } from "@/features/clientInfo/components/ClientProfileTags";
import { useFormatAddress } from "@/hooks/useFormatAddress";
import { useFormatDate } from "@/hooks/useFormatDate";
import { useMask } from "@/hooks/useMask";
import { Client } from "@core/models/client.model";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Flex,
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@gogeo-io/ui-library";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import styled from "styled-components";

interface AvatarOfClientWithHoverInfoProps {
  client: Client;
}

export function AvatarOfClientWithHoverInfo({
  client,
}: AvatarOfClientWithHoverInfoProps) {
  const user = useSelector(selectUser);

  const { usePhoneMask } = useMask();
  const { formatBasicDateWithSlash } = useFormatDate();

  return (
    <HoverCard openDelay={500} closeDelay={150}>
      <HoverCardTrigger>
        <Flex css={{ position: "relative" }}>
          {!isEmpty(client.client_photo) ? (
            <Avatar size="medium">
              {client.client_photo.photo_sm_url ? (
                <AvatarImage src={client.client_photo.photo_sm_url} />
              ) : (
                <AvatarFallback>{client.client_photo.fallback}</AvatarFallback>
              )}
            </Avatar>
          ) : (
            <Avatar size="medium">
              <AvatarFallback>GO</AvatarFallback>
            </Avatar>
          )}
          {client.attendance_situation?.rescheduled_creation_date && (
            <RescheduledDateDot />
          )}
        </Flex>
      </HoverCardTrigger>

      <HoverCardContent
        align="center"
        css={{ width: "450px" }}
        sideOffset={-5}
        onClick={(event) => event.stopPropagation()}
      >
        <Flex css={{ flexDirection: "column", gap: "$3" }}>
          <ClientProfileAvatar client={client} showGoBackButton={false} />
          {client.attendance_situation &&
            client.attendance_situation.rescheduled_creation_date && (
              <ProfileInfo
                label="Cliente reagendado para:"
                value={formatBasicDateWithSlash(
                  new Date(
                    client.attendance_situation.rescheduled_creation_date
                  )
                )}
              />
            )}
          <ProfileInfo label="ID do cliente" value={client.id} />
          <ClientProfileInfoPhone
            label="Telefone"
            value={usePhoneMask(client.main_phone)}
            client={client}
            user={user}
          />
          <ProfileInfo
            label="Endereço"
            value={useFormatAddress(client.address)}
          />
          <ListDivider />
          {!isEmpty(client.attendance_situation) &&
            !isEmpty(client.attendance_situation.lastAttendanceRecord) && (
              <ClientProfileLastAttendance client={client} />
            )}
          {!isEmpty(client.tags) && <ClientProfileTags client={client} />}
        </Flex>
      </HoverCardContent>
    </HoverCard>
  );
}

const RescheduledDateDot = styled.div`
  position: absolute;
  top: -4px;
  right: -4px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${(props) => props.theme.colors.primary500};
`;
