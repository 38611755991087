import { ClientsTableSkeletonPage } from "@/components/Skeleton/ClientsTableSkeletonPage";
import { ClientParamsResponse } from "@/core/models/client.model";
import { ListOfTagsFilter, SortOrder } from "@/core/models/listOfTags.model";
import { selectUser } from "@/core/store/users/usersSlice";
import {
  emitEventToChangeClients,
  selectStatusOfEmitEventToChangeClients,
} from "@/features/attendance/store/emitEventToChangeClientsSlice";
import {
  selectColumnsToSort,
  setColumnsToSort,
} from "@/features/clientInfo/store/clientColumnsToSortSlice";
import { SearchResultText } from "@/features/clients/components/SearchResultText";
import { useGetClientsByFiltersParams } from "@/features/clients/hooks/useGetClientsByFiltersParams";
import {
  getClientsByFilters,
  getClientsCount,
} from "@/features/clients/store/clientsSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Dialog, DialogContent, DialogTrigger } from "@gogeo-io/ui-library";
import { isUndefined } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParamsColumns } from "../hooks/useParamsColumns";
import { setClientToChangeParamsCount } from "../store/clientsToChangeParamsCountSlice";
import { changeParams } from "../store/paramsSlice";
import { ChangeParamsForm } from "./ChangeParamsForm";
import { FloatButtonToUpdateParams } from "./FloatButtonToUpdateParams";
import { ParamsClientsTable } from "./ParamsClientsTable";
import { ParamsTableFilterSearch } from "./ParamsTableFilterSearch";

export function ParamsClients() {
  const [hasMorePages, setHasMorePages] = useState(true);
  const [clients, setClients] = useState<ClientParamsResponse[]>([]);
  const [clientStatus, setClientsStatus] = useState("loading");
  const [clientsCount, setClientsCount] = useState(0);
  const [loadedPage, setLoadedPage] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [openChangeParamsDialog, setOpenChangeParamsDialog] = useState(false);

  const user = useSelector(selectUser);
  const selectedColumnsToSort = useSelector(selectColumnsToSort);
  const statusOfEmitEventToChangeClients = useSelector(
    selectStatusOfEmitEventToChangeClients
  );

  const dispatch = useAppDispatch();
  const { paramsOnEditListView } = useGetClientsByFiltersParams();

  const handleIncreasePageNumber = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handleSetSort = async (
    direction: "ASC" | "DESC" | "",
    property: string
  ) => {
    await dispatch(emitEventToChangeClients(false));

    const newColumn: SortOrder = { direction, index: 0, property };

    if (selectedColumnsToSort.length === 0) {
      await dispatch(setColumnsToSort([newColumn]));
    } else {
      const findedColumn = selectedColumnsToSort.find(
        (selectedColum) => selectedColum.property === newColumn.property
      );

      let columnsWithNewColumn: SortOrder[] = [];

      if (isUndefined(findedColumn)) {
        columnsWithNewColumn = [newColumn, ...selectedColumnsToSort];
      } else {
        const columnsWithoutRemovedOne = selectedColumnsToSort.filter(
          (selectedColum) => selectedColum.property !== newColumn.property
        );
        columnsWithNewColumn = [newColumn, ...columnsWithoutRemovedOne];
      }

      const columnsWithoutEmptyDirection = columnsWithNewColumn.filter(
        (selectedColum) => selectedColum.direction !== ""
      );

      const newOrderOfColumns = columnsWithoutEmptyDirection.map(
        (col, idx) => col && { ...col, index: idx }
      );

      await dispatch(setColumnsToSort(newOrderOfColumns));
    }

    await dispatch(emitEventToChangeClients(true));
  };

  const loadClients = async () => {
    setCurrentPage(0);
    setHasMorePages(true);
    setClientsStatus("loading");

    let params: ListOfTagsFilter = {};

    params = paramsOnEditListView();

    await dispatch(
      getClientsByFilters({
        page: 0,
        size: import.meta.env.VITE_PAGE_SIZE,
        params,
        bringParams: true,
      })
    ).then(async (res) => {
      setClients(res.payload.clients);
      setClientsStatus("fulfilled");
      setLoadedPage(true);
    });
    await dispatch(
      getClientsCount({
        params,
      })
    ).then(async (res) => {
      const count = res.payload;
      setClientsCount(count);
      await dispatch(setClientToChangeParamsCount(count));
    });
  };

  useEffect(() => {
    loadClients();
  }, []);

  useEffect(() => {
    if (statusOfEmitEventToChangeClients) {
      loadClients();
    }
  }, [statusOfEmitEventToChangeClients]);

  const getPaginatedClients = async (currentPage: number) => {
    let params: ListOfTagsFilter = {};

    params = paramsOnEditListView();

    await dispatch(
      getClientsByFilters({
        page: currentPage,
        size: import.meta.env.VITE_PAGE_SIZE,
        params,
        bringParams: true,
      })
    ).then((res) => {
      if (res.payload.clients.length === 0) {
        setHasMorePages(false);
      } else {
        setClients((prevClients) => [...prevClients, ...res.payload.clients]);
      }
    });
  };

  const handleCancelChanges = async () => {
    await dispatch(changeParams({ params: "cleanAllFilters" }));
  };

  const handleUpdateParams = () => {
    loadClients();
    setOpenChangeParamsDialog(false);
  };

  const clientsParamsColumns = useParamsColumns(handleSetSort);

  return (
    <>
      <ParamsTableFilterSearch />

      <SearchResultText count={String(clientsCount)} />

      {loadedPage ? (
        <ParamsClientsTable
          columns={clientsParamsColumns}
          data={clients}
          onPageChange={getPaginatedClients}
          currentPage={currentPage}
          onIncreasePageNumber={handleIncreasePageNumber}
          hasMorePages={hasMorePages}
          status={clientStatus}
        />
      ) : (
        <ClientsTableSkeletonPage />
      )}

      <Dialog
        open={openChangeParamsDialog}
        onOpenChange={setOpenChangeParamsDialog}
      >
        <DialogTrigger>
          {user.permissions.change_params && <FloatButtonToUpdateParams />}
        </DialogTrigger>
        <DialogContent
          style={{ width: "400px", maxHeight: "85%", overflow: "scroll" }}
          onCloseAutoFocus={handleCancelChanges}
        >
          <ChangeParamsForm onUpdateParams={handleUpdateParams} />
        </DialogContent>
      </Dialog>
    </>
  );
}
