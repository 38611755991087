import { Client } from "@/core/models/client.model";
import { useClientInfoMixpanel } from "@/features/clientInfo/hooks/useClientInfoMixpanel";

export const useWhatsapp = () => {
  const { sendedMessageWhatsappEvent } = useClientInfoMixpanel();

  const sendWhatsappToClient = (
    client: Client,
    phone: string,
    text: string
  ): void => {
    phone = phone.replace(/\D/g, "");

    window.open(
      `https://api.whatsapp.com/send/?phone=55${phone}&text=${text} `,
      "_blank"
    );
    sendedMessageWhatsappEvent(client, phone, text);
  };

  const sendWhatsappToUser = (phone: string, text: string): void => {
    phone = phone.replace(/\D/g, "");

    window.open(
      `https://api.whatsapp.com/send/?phone=55${phone}&text=${text} `,
      "_blank"
    );
  };

  return {
    sendWhatsappToClient,
    sendWhatsappToUser,
  };
};
