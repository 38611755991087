import { ReduxThunkError } from "@/core/errors/ReduxThunkError";
import { ClientOnFunnelCount, Funnel } from "@/core/models/funnel-model";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";
import { RootState } from "../../../core/store/store";

interface FunnelState {
  funnels: Funnel[];
  selectedFunnel: Funnel;
  has500error: boolean;
  status: "loading" | "success" | "failed";
}

const initialState = {
  funnels: [],
  selectedFunnel: {},
  has500error: false,
  status: "loading",
} as FunnelState;

export const getFunnels = createAsyncThunk(
  "funnel/getFunnels",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get("/filter/kanban");
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
        });
      }
    }
  }
);

interface GetFunnelProps {
  id: string;
  params?: object;
}

export const getFunnel = createAsyncThunk(
  "funnel/getFunnel",
  async ({ id }: GetFunnelProps, thunkAPI) => {
    try {
      const response = await axios.get(`/filter/kanban/${id}`);
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
        });
      }
    }
  }
);

export const getFunnelCountOfClients = createAsyncThunk(
  "funnel/getFunnelCountOfClients",
  async ({ id, params }: GetFunnelProps, thunkAPI) => {
    try {
      const response = await axios.post(
        `/filter/kanban/${id}/clients-count`,
        params
      );
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
        });
      }
    }
  }
);

export const funnelsSlice = createSlice({
  name: "funnelsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getFunnels.fulfilled,
      (state, action: PayloadAction<Funnel[]>) => {
        state.funnels = action.payload;
        state.has500error = false;
      }
    );
    builder.addCase(
      getFunnels.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = "failed";
        if (action.payload.status === 500) {
          state.has500error = true;
        }
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error getting the funnels");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
    ///////////////
    builder.addCase(getFunnel.pending, (state, action) => {
      state.status = "loading";
      state.has500error = false;
    });
    builder.addCase(
      getFunnel.fulfilled,
      (state, action: PayloadAction<Funnel>) => {
        state.status = "success";
        state.selectedFunnel = action.payload;
        state.has500error = false;
      }
    );
    builder.addCase(getFunnel.rejected, (state, action: PayloadAction<any>) => {
      state.status = "failed";
      if (action.payload.status === 500) {
        state.has500error = true;
      }
      Sentry.configureScope((scope) => {
        scope.setLevel("error");
        scope.setTransactionName("Error getting the specific funnel");
        scope.setExtras(action.payload);
      });
      Sentry.captureException(new ReduxThunkError(action.payload));
      toast.error(action.payload.errorMessage);
    });
    ///////////////
    builder.addCase(getFunnelCountOfClients.pending, (state, action) => {
      state.status = "loading";
      state.has500error = false;
    });
    builder.addCase(
      getFunnelCountOfClients.fulfilled,
      (state, action: PayloadAction<ClientOnFunnelCount[]>) => {
        state.status = "success";
        state.selectedFunnel.kanban_columns.forEach((column) => {
          const matchingClient = action.payload.find(
            (client) => client.id === column.id
          );

          if (matchingClient) {
            column.clients_count = matchingClient.count;
          }
        });
        state.has500error = false;
      }
    );
    builder.addCase(
      getFunnelCountOfClients.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = "failed";
        if (action.payload.status === 500) {
          state.has500error = true;
        }
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName(
            "Error getting the count of clients on funnel"
          );
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
  },
});

export const selectAvailableFunnels = (state: RootState) =>
  state.funnelsState.funnels;
export const selectSelectedFunnel = (state: RootState) =>
  state.funnelsState.selectedFunnel;
export const selectFunnelsStatus = (state: RootState) =>
  state.funnelsState.status;
export const selectFunnelsHasError500 = (state: RootState) =>
  state.funnelsState.has500error;

export const funnelsReducer = funnelsSlice.reducer;
