import { ListDivider } from "@/components/ListDivider";
import { Flex } from "@gogeo-io/ui-library";
import RLSSkeleton from "react-loading-skeleton";

export function ReportSkeletonPage() {
  return (
    <Flex
      css={{
        flexDirection: "column",
        gap: "$2",
        width: "100%",
        height: "100%",
      }}
    >
      <Flex css={{ alignItems: "flex-end", justifyContent: "flex-end" }}>
        <Flex css={{ alignItems: "center", gap: "$4" }}>
          <RLSSkeleton width={162} height={40} borderRadius={4} />
          <RLSSkeleton width={162} height={40} borderRadius={4} />
          <RLSSkeleton width={162} height={40} borderRadius={4} />
        </Flex>
      </Flex>

      <RLSSkeleton height={200} borderRadius={4} />
      <ListDivider isSkeleton />
      <RLSSkeleton height={450} borderRadius={4} />
    </Flex>
  );
}
