import { useFormatDate } from "@/hooks/useFormatDate";
import { Flex, Text } from "@gogeo-io/ui-library";

import { addMinutes, getHours, getMinutes } from "date-fns";
import { useEffect, useState } from "react";
import { ReminderPickHourAmPm } from "./ReminderPickHourAmPm";
import { ReminderPickHourInput } from "./ReminderPickHourInput";
import { ReminderPickMinutesInput } from "./ReminderPickMinutesInput";

interface PickHourProps {
  onChange: (...event: any[]) => void;
}

export function ReminderPickHour({ onChange }: PickHourProps) {
  const [hour, setHour] = useState(getHours(new Date()) - 12);
  const [minutes, setMinutes] = useState(
    getMinutes(addMinutes(new Date(), 10))
  );
  const [amPm, setAmPm] = useState<"am" | "pm">(
    getHours(new Date()) < 12 ? "am" : "pm"
  );

  const { formatWithDigits } = useFormatDate();

  const handleSetAmPm = (option: "am" | "pm") => setAmPm(option);

  const handleHourChange = (hour: number) => setHour(hour);

  const handleMinutesChange = (minutes: number) => setMinutes(minutes);

  useEffect(() => {
    let hourFormatted = hour;

    if (amPm === "pm") {
      hourFormatted = hourFormatted + 12;
    }

    onChange(
      `${formatWithDigits(hourFormatted, 2)}:${formatWithDigits(minutes, 2)}`
    );
  }, [hour, minutes, amPm]);

  return (
    <Flex css={{ flexDirection: "column" }}>
      <Text css={{ marginBottom: "$2", textAlign: "left" }}>
        Selecione a hora do lembrete:
      </Text>
      <Flex css={{ alignItems: "center", gap: "$4" }}>
        <ReminderPickHourInput
          handleHourChange={handleHourChange}
          defaultHour={hour}
        />
        :
        <ReminderPickMinutesInput
          handleMinutesChange={handleMinutesChange}
          defaultMinutes={minutes}
        />
        <ReminderPickHourAmPm
          selectedOption={amPm}
          onSelectedOptionChange={handleSetAmPm}
        />
      </Flex>
    </Flex>
  );
}
