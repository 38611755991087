import { MixpanelTracking, TrackingEvents } from "@/config/mixpanel";
import { BuildMixpanelProps } from "@/utils/buildMixpanelProps";

export const useNotificationsMixpanel = () => {
  const buildProps = BuildMixpanelProps();

  const notificationReadedEvent = (id: number) => {
    MixpanelTracking.getInstance().track(TrackingEvents.NOTIFICATION_READ, {
      ...buildProps(),
      "ID da Notificação": id,
    });
  };

  return { notificationReadedEvent };
};
