import { Flex } from "@gogeo-io/ui-library";
import { ReactNode } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

interface NavigatorHeaderProps {
  title: string;
  children: ReactNode;
}

function NavigatorHeader({ children }: NavigatorHeaderProps) {
  return (
    <>
      <Flex css={{ alignItems: "center", gap: "$2", mb: "$4" }}></Flex>
      {children}
    </>
  );
}

interface NavigatorProps {
  children: React.ReactElement[];
}

function Navigator({ children }: NavigatorProps) {
  const { pathname } = useLocation();

  return (
    <>
      {children.some((child) => pathname.includes(child.props.specificRoute))
        ? children
            .filter((child) => pathname.includes(child.props.specificRoute))
            .map((child, idx) => {
              return (
                <NavigatorHeader
                  key={child.props.title}
                  title={child.props.title}
                >
                  {child.props.component}
                </NavigatorHeader>
              );
            })
        : children
            .filter((child) => !child.props.specificRoute)
            .map((child) => {
              return (
                <Flex
                  key={child.props.title}
                  css={{ height: "100%", width: "100%" }}
                >
                  {child.props.component}
                </Flex>
              );
            })}
    </>
  );
}

interface ScreenProps {
  component: React.ReactNode;
  specificRoute?: string;
  title?: string;
}

function Screen({ component }: ScreenProps) {
  return <ScreenComponent>{component}</ScreenComponent>;
}

export const Stack = {
  Navigator,
  Screen,
};

export const ScreenComponent = styled.div`
  overflow-y: auto;
  width: 100%;
  height: 100%;
`;
