import { selectAvailableHistoryFilters } from "@/features/history/store/historyFiltersSlice";
import { Select, SelectItem, SelectItemText } from "@gogeo-io/ui-library";
import { useState } from "react";
import { useSelector } from "react-redux";

interface ObservationsProps {
  onChange: (...event: any[]) => void;
  value: string;
}

export function AttendanceFeedbackChannels({
  onChange,
  value,
}: ObservationsProps) {
  const availableFilters = useSelector(selectAvailableHistoryFilters);

  const [selectedChannel, setSelectedChannel] = useState(value);

  const channels = availableFilters.find(
    (filter) => filter.property === "channel"
  );

  const handleValueChange = async (newValue: string) => {
    onChange(newValue);
    setSelectedChannel(newValue);
  };

  return (
    <Select
      value={selectedChannel}
      defaultValue={selectedChannel}
      ariaLabel="Select option"
      onValueChange={handleValueChange}
    >
      {channels.options.map((option) => {
        return (
          <SelectItem key={option.id} value={option.value}>
            <SelectItemText>{option.value}</SelectItemText>
          </SelectItem>
        );
      })}
    </Select>
  );
}
