import { EmptyContent } from "@/components/EmptyContent";
import { ErrorContent } from "@/components/ErrorContent";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Heading } from "@gogeo-io/ui-library";
import AddIcon from "@mui/icons-material/Add";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getReminders,
  selectReminderHasError500,
  selectReminders,
  selectRemindersStatus,
} from "../store/reminderSlice";
import UI from "../ui";
import { ReminderForm } from "./ReminderForm";
import { ReminderHeader } from "./ReminderHeader";
import { ReminderList } from "./ReminderList";
import { ReminderSkeleton } from "./ReminderSkeleton";

export function ReminderContainer() {
  const [canSeeReminderForm, setCanSeeReminderForm] = useState(false);

  const handleCanSeeReminderForm = () => {
    setCanSeeReminderForm(!canSeeReminderForm);
  };

  const dispatch = useAppDispatch();

  const reminders = useSelector(selectReminders);
  const remindersStatus = useSelector(selectRemindersStatus);
  const reminderHasError500 = useSelector(selectReminderHasError500);

  useEffect(() => {
    async function loadReminders() {
      await dispatch(getReminders());
    }

    loadReminders();
  }, []);

  return (
    <UI.ReminderContainer canSeeReminderForm={canSeeReminderForm}>
      {reminderHasError500 ? (
        <ErrorContent size="medium">
          Não conseguimos buscar seus lembretes, tente novamente mais tarde ou
          atualize a página
        </ErrorContent>
      ) : (
        <>
          {remindersStatus !== "success" ? (
            <ReminderSkeleton />
          ) : (
            <>
              <div className="left">
                <ReminderHeader />
                <div>
                  {!isEmpty(reminders) ? (
                    <ReminderList />
                  ) : (
                    <EmptyContent size="medium">
                      Você não possui lembretes
                    </EmptyContent>
                  )}
                </div>
                <UI.ReminderAddWrapper>
                  <UI.ReminderAddButton onClick={handleCanSeeReminderForm}>
                    <AddIcon fontSize="inherit" />
                  </UI.ReminderAddButton>
                </UI.ReminderAddWrapper>
              </div>
              {canSeeReminderForm && (
                <div className="right">
                  <UI.ReminderFormTitle>
                    <Heading size="title6">Criar um lembrete</Heading>
                    <CalendarTodayIcon fontSize="inherit" />
                  </UI.ReminderFormTitle>
                  <ReminderForm
                    handleCanSeeReminderForm={handleCanSeeReminderForm}
                  />
                </div>
              )}
            </>
          )}
        </>
      )}
    </UI.ReminderContainer>
  );
}
