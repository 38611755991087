import { Breadcrumb } from "@/core/models/breadcrumb";
import { setBreadcrumbs } from "@/core/store/breadcrumbs/breadcrumbsSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Tooltip, TooltipContent, TooltipTrigger } from "@gogeo-io/ui-library";
import { KeyboardArrowRight } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { BreadcrumbItemContainer, BreadcrumbText } from "./BreadCrumbs";

interface BreadcrumbItemProps {
  breadcrumb: Breadcrumb;
}

export function BreadcrumbItem({ breadcrumb }: BreadcrumbItemProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleNavigation = async (url: string) => {
    await dispatch(setBreadcrumbs([]));
    navigate(url);
  };

  const handleTrimBreadrumbsText = (text: string, size: number) => {
    return `${text.slice(0, size)}...`;
  };

  return (
    <BreadcrumbItemContainer
      key={breadcrumb.item}
      onClick={() => handleNavigation(breadcrumb.url)}
    >
      {breadcrumb.canAdjust ? (
        <>
          {breadcrumb.item.length > 14 ? (
            <Tooltip>
              <TooltipTrigger style={{ background: "transparent" }}>
                <BreadcrumbText size="body1">
                  {handleTrimBreadrumbsText(breadcrumb.item, 14)}
                </BreadcrumbText>
              </TooltipTrigger>
              <TooltipContent aria-label="Remarcação de agendamento">
                {breadcrumb.item}
              </TooltipContent>
            </Tooltip>
          ) : (
            <BreadcrumbText size="body1">{breadcrumb.item}</BreadcrumbText>
          )}
        </>
      ) : (
        <>
          {breadcrumb.item.length > 28 ? (
            <Tooltip>
              <TooltipTrigger style={{ background: "transparent" }}>
                <BreadcrumbText size="body1">
                  {handleTrimBreadrumbsText(breadcrumb.item, 28)}
                </BreadcrumbText>
              </TooltipTrigger>
              <TooltipContent aria-label="Remarcação de agendamento">
                {breadcrumb.item}
              </TooltipContent>
            </Tooltip>
          ) : (
            <BreadcrumbText size="body1">{breadcrumb.item}</BreadcrumbText>
          )}
        </>
      )}

      <KeyboardArrowRight />
    </BreadcrumbItemContainer>
  );
}
