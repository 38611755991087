import G_UI from "@ui/index";
import { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  selectAllClientsSelectedToChangeParams,
  selectClientSelectedToChangeParams,
} from "../../store/clientSelectedToChangeParamsSlice";
import { selectClientsSelectedToChangeParamsCountSlice } from "../../store/clientsToChangeParamsCountSlice";
import { ChangeParamsFormActions } from "./ChangeParamsFormActions";
import { ChangeParamsFormClientName } from "./ChangeParamsFormClientName";
import { ChangeParamsFormContactDate } from "./ChangeParamsFormContactDate";
import { ChangeParamsFormFrequency } from "./ChangeParamsFormFrequency";
import { ChangeParamsFormNonSchedulable } from "./ChangeParamsFormNonSchedulable";
import { ChangeParamsFormRoutable } from "./ChangeParamsFormRoutable";
import { ChangeParamsFormSchedulingPreference } from "./ChangeParamsFormSchedulingPreference";
import { ChangeParamsFormWeekRestriction } from "./ChangeParamsFormWeekRestriction";
import { ChangeParamsReasonToChange } from "./ChangeParamsReasonToChange";

export interface ChangeParamsFormProps {
  onUpdateParams: () => void;
}

export function ChangeParamsForm({ onUpdateParams }: ChangeParamsFormProps) {
  const [canOpenWeekRestriction, setCanOpenWeekRestriction] = useState(false);

  const selectedClientsToChangeParams = useSelector(
    selectClientSelectedToChangeParams
  );
  const allClientsSelectedToChangeParams = useSelector(
    selectAllClientsSelectedToChangeParams
  );
  const totalClients = useSelector(
    selectClientsSelectedToChangeParamsCountSlice
  );
  const handleCanOpenWeekRestriction = (value: boolean) => {
    setCanOpenWeekRestriction(!value);
  };

  const haveClientsSelected =
    selectedClientsToChangeParams.length <= 0 &&
    !allClientsSelectedToChangeParams;

  return (
    <Container>
      <ChangeParamsFormClientName />

      <ChangeParamsFormNonSchedulable />
      <ChangeParamsFormRoutable />
      <ChangeParamsFormFrequency />
      <ChangeParamsFormSchedulingPreference
        openWeekRestriction={handleCanOpenWeekRestriction}
      />
      {!canOpenWeekRestriction && !haveClientsSelected && (
        <ChangeParamsFormWeekRestriction />
      )}
      <ChangeParamsFormContactDate />
      <ChangeParamsReasonToChange />
      <G_UI.Info>{`* Parâmetros não configurados serão definidos valores padrões pelo goVendas.`}</G_UI.Info>
      {allClientsSelectedToChangeParams && (
        <G_UI.Info>
          {`Atenção!!! As alterações realizadas acima serão aplicadas para todos os ${totalClients} clientes selecionados exceto os excluídos.`}
        </G_UI.Info>
      )}
      {selectedClientsToChangeParams.length > 1 && (
        <G_UI.Info>
          {`Atenção!!! As alterações realizadas acima serão aplicadas para todos os ${selectedClientsToChangeParams.length} clientes selecionados.`}
        </G_UI.Info>
      )}
      <ChangeParamsFormActions onUpdateParams={onUpdateParams} />
    </Container>
  );
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[5]};
  padding: 5px;
`;
