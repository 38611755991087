import { ReactNode } from "react";
import styled from "styled-components";
import { SidebarLeftSize } from "./PageLayout";

interface SidebarLeftProps {
  children?: ReactNode;
  sidebarLeftSize: SidebarLeftSize;
}

export default function SidebarLeft({
  children,
  sidebarLeftSize,
}: SidebarLeftProps) {
  return <Wrapper sidebarLeftSize={sidebarLeftSize}>{children}</Wrapper>;
}

interface WrapperProps {
  sidebarLeftSize: SidebarLeftSize;
}

const Wrapper = styled.aside<WrapperProps>`
  height: calc(100% - var(--header-height));
  border-right: 2px solid ${({ theme }) => theme.colors.gray200};
  padding: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  background: ${({ theme }) => theme.colors.white};
  ${(props) => {
    if (props.sidebarLeftSize === "large") {
      return `
        min-width: 400px;
        max-width: 400px;
    `;
    } else if (props.sidebarLeftSize === "medium") {
      return `
        min-width: 300px;
        max-width: 300px;
    `;
    } else if (props.sidebarLeftSize === "small") {
      return `
        min-width: 100px;
        max-width: 100px;
    `;
    } else if (props.sidebarLeftSize === "none") {
      return `
        min-width: 0px;
        max-width: 0px;
    `;
    } else {
      return `
        min-width: 400px;
        max-width: 400px;
    `;
    }
  }}
`;
