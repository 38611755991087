import { Flex, TabsList, TabsRoot, TabsTrigger } from "@gogeo-io/ui-library";
import { ReactElement } from "react";
import { useNavigate } from "react-router-dom";

interface UsersPreferenceTabsProps {
  children: ReactElement;
}

type Tabs =
  | "business-goals"
  | "attendance-justifications"
  | "pre-defined-messages"
  | "change-password";

export function UsersPreferenceTabs({ children }: UsersPreferenceTabsProps) {
  const navigate = useNavigate();

  const handleClickTab = (tab: Tabs) => navigate(`/profile/${tab}`);

  return (
    <Flex css={{ flexDirection: "column" }}>
      <TabsRoot
        defaultValue="pre-defined-messages"
        css={{
          width: "100%",
          height: "100%",
          boxShadow: "none",
        }}
      >
        <TabsList
          aria-label="Manage your account"
          defaultValue="business-goals"
        >
          <TabsTrigger
            value="pre-defined-messages"
            onClick={() => handleClickTab("pre-defined-messages")}
          >
            Mensagens pré definidas
          </TabsTrigger>
          <TabsTrigger
            value="business-goals"
            onClick={() => handleClickTab("business-goals")}
          >
            Objetivos de negócio
          </TabsTrigger>
          <TabsTrigger
            value="attendance-justifications"
            onClick={() => handleClickTab("attendance-justifications")}
          >
            Justificativas de atendimento
          </TabsTrigger>
          <TabsTrigger
            value="change-password"
            onClick={() => handleClickTab("change-password")}
          >
            Trocar Senha
          </TabsTrigger>
        </TabsList>
      </TabsRoot>

      {children}
    </Flex>
  );
}
