import { ReduxThunkError } from "@/core/errors/ReduxThunkError";
import { PaginatedRequestBaseProps } from "@/core/models/paginatedRequest.model";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";
import { Tag } from "../../models/tag.model";
import { RootState } from "../store";

interface TagsState {
  tags: Tag[];
  has500error: boolean;
  status: "loading" | "success" | "failed";
}

const initialState = {
  tags: [],
  has500error: false,
  status: "loading",
} as TagsState;

interface GetUserTagsProps extends PaginatedRequestBaseProps {
  query?: string;
}

export const getUserTags = createAsyncThunk(
  "tags/getUserTags",
  async ({ page, size, query }: GetUserTagsProps, thunkAPI) => {
    try {
      let url = "";
      if (query) {
        url = `/tags?page=${page}&size=${size}&query=${query}`;
      } else {
        url = `/tags?page=${page}&size=${size}`;
      }
      const response = await axios.get(url);
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

interface GetTagsProps {
  tags?: any;
}

export const getTags = createAsyncThunk(
  "tags/getTags",
  async ({ tags }: GetTagsProps, thunkAPI) => {
    try {
      const url = `/tags/list`;
      const response = await axios.post(url, tags);
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

export const tagsSlice = createSlice({
  name: "tagsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserTags.pending, (state, action) => {
      state.status = "loading";
      state.has500error = false;
    });
    builder.addCase(getUserTags.fulfilled, (state, action) => {
      state.status = "success";
      state.tags = action.payload;
      state.has500error = false;
    });
    builder.addCase(
      getUserTags.rejected,
      (state, action: PayloadAction<any>) => {
        if (action.payload.status === 500) {
          state.has500error = true;
        }
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error getting user tags");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
    builder.addCase(getTags.rejected, (state, action: PayloadAction<any>) => {
      Sentry.configureScope((scope) => {
        scope.setLevel("error");
        scope.setTransactionName("Error getting tags");
        scope.setExtras(action.payload);
      });
      Sentry.captureException(new ReduxThunkError(action.payload));
      toast.error(action.payload.errorMessage);
    });
  },
});

export const selectUserTagsHasError500 = (state: RootState) =>
  state.tagsState.has500error;

export const tagsReducer = tagsSlice.reducer;
