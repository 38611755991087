import { Text } from "@gogeo-io/ui-library";

interface SearchResultTextProps {
  count: string;
}

export function SearchResultText({ count }: SearchResultTextProps) {
  return (
    <Text css={{ color: "$gray500" }}>
      Resultado da busca: {count} clientes encontrados
    </Text>
  );
}
