import { selectUser } from "@/core/store/users/usersSlice";
import { useGoBack } from "@/hooks/useGoBack";
import { ErrorPageLayout } from "@/layouts/ErrorPageLayout";
import { Button, Flex, Heading } from "@gogeo-io/ui-library";
import { useAuth } from "react-oidc-context";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import ServerDownSvg from "../assets/server-down.svg";

export function Page500() {
  const user = useSelector(selectUser);

  const { navigateGoBack } = useGoBack();
  const auth = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    navigate("/");
    auth.removeUser();
  };

  return (
    <ErrorPageLayout
      content={
        <Wrapper>
          <img src={ServerDownSvg} alt="sistema fora do ar" />
          <Flex
            css={{ flexDirection: "column", gap: "$2", textAlign: "center" }}
          >
            <Heading size="title3" className="title">
              Tivemos um problema interno
            </Heading>
            <Heading size="subtitle1">
              Oopss, aconteceu um problema inesperado, clique no botão abaixo
              para atualizar a página, se persistir, sinta-se a vontade para
              entrar em contato conosco.
            </Heading>
          </Flex>
          <Flex css={{ gap: "$2" }}>
            <Button onClick={() => navigateGoBack()} bordered>
              Atualizar Página
            </Button>
            {user !== undefined && (
              <Button color="red" onClick={handleLogout}>
                Deslogar
              </Button>
            )}
          </Flex>
        </Wrapper>
      }
    />
  );
}

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.space[6]};

  img {
    width: 600px;
  }

  .title {
    color: ${({ theme }) => theme.colors.primary500};
  }
`;
