import { ClientNote } from "@/core/models/client-notes.model";
import useAppDispatch from "@/hooks/useAppDispatch";
import { useFormatDate } from "@/hooks/useFormatDate";
import {
  Caption,
  Flex,
  Heading,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@gogeo-io/ui-library";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { updateIsFavoriteOnClientNote } from "../../store/clientNotesSlice";
import { ClientNoteExcerpt } from "./ClientNoteExcerpt";
import { ClientNoteFilesOnItem } from "./ClientNoteFilesOnItem";
import { ClientNoteItemActions } from "./ClientNoteItemActions";

export interface ClientNoteItemProps {
  note: ClientNote;
}

export function ClientNoteItem({ note }: ClientNoteItemProps) {
  const { formatLongDatePtBr } = useFormatDate();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleClickNote = async () => {
    navigate(`list/${note.id}`);
  };

  const handleClickIsFavorite = async (
    note_id: string,
    is_favorite: boolean
  ) => {
    await dispatch(updateIsFavoriteOnClientNote({ note_id, is_favorite }));
  };

  const renderNoteTitle = (note_title: string) => {
    if (note_title.length > 30) {
      return `${note_title.slice(0, 29)}...`;
    }

    return note_title;
  };

  return (
    <Flex
      css={{
        width: "300px",
        boxShadow: "$sm",
        borderRadius: "4px",
        gap: "$4",
        padding: "$3",
        background: "$white",
        flexDirection: "column",
      }}
    >
      <Flex
        css={{
          alignItems: "center",
          justifyContent: "space-between",
          color: "$yellow500",
        }}
      >
        <Tooltip>
          <TooltipTrigger style={{ background: "transparent" }}>
            <Flex
              css={{
                alignItems: "center",
                justifyContent: "space-between",
                color: "$yellow500",
              }}
            >
              <Heading size="subtitle1" css={{ color: "$gray600" }}>
                {renderNoteTitle(note.title)}
              </Heading>
            </Flex>
          </TooltipTrigger>
          <TooltipContent aria-label="Progresso realizado">
            {note.title}
          </TooltipContent>
        </Tooltip>

        {note.is_favorite ? (
          <AiFillStar
            color="inherit"
            onClick={() => handleClickIsFavorite(note.id, false)}
            size={22}
            cursor="pointer"
          />
        ) : (
          <AiOutlineStar
            onClick={() => handleClickIsFavorite(note.id, true)}
            size={22}
            cursor="pointer"
          />
        )}
      </Flex>

      <Flex
        css={{
          flex: "1",
          cursor: "pointer",
          alignItems: "center",
        }}
        onClick={() => handleClickNote()}
      >
        <ClientNoteExcerpt excerpt={note.excerpt} />
      </Flex>

      <Flex css={{ gap: "$3", alignItems: "center" }}>
        <Caption css={{ color: "$gray500" }}>Anexos:</Caption>
        <ClientNoteFilesOnItem
          files={note.files}
          onOpenNote={handleClickNote}
        />
      </Flex>

      <Flex>
        <Flex
          css={{
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Caption css={{ color: "$gray500" }}>
            {formatLongDatePtBr(note.inserted_at)}
          </Caption>

          <ClientNoteItemActions note={note} />
        </Flex>
      </Flex>
    </Flex>
  );
}
