import { EmptyContent } from "@/components/EmptyContent";
import { ErrorContent } from "@/components/ErrorContent";
import { ClientsTableSkeletonPage } from "@/components/Skeleton/ClientsTableSkeletonPage";
import {
  PreDefinedMessage,
  PreDefinedMessageSubject,
} from "@/core/models/preDefinedMessage.model";
import useAppDispatch from "@/hooks/useAppDispatch";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CreatePreDefinedMessage } from "../components/CreatePreDefinedMessage";
import { PreDefinedMessagesTable } from "../components/PreDefinedMessagesTable";
import { usePreDefinedMessagesColumns } from "../hooks/usePreDefinedMessagesColumns";
import { usePreDefinedMessagesMixpanel } from "../hooks/usePreDefinedMessagesMixpanel";
import {
  createPreDefinedMessage,
  deletePreDefinedMessage,
  getPreDefinedMessages,
  selectPreDefinedMessagesHasError500,
  setTotalPreDefinedMessages,
  updatePreDefinedMessage,
} from "../store/preDefinedMessagesSlice";

export function PreDefinedMessagesPage() {
  const [preDefinedMessages, setPreDefinedMessages] = useState<
    PreDefinedMessage[]
  >([]);
  const [preDefinedMessageStatus, setPreDefinedMessageStatus] =
    useState("loading");
  const [loadedPage, setLoadedPage] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);

  const dispatch = useAppDispatch();

  const preDefinedMessagesHasError500 = useSelector(
    selectPreDefinedMessagesHasError500
  );

  const {
    createPreDefinedMessageEvent,
    editPreDefinedMessageEvent,
    deletedPreDefinedMessageEvent,
    getMessagesPreDefinedMessageEvent,
  } = usePreDefinedMessagesMixpanel();

  useEffect(() => {
    async function loadPreDefinedMessages() {
      await dispatch(
        getPreDefinedMessages({
          page: 0,
          size: import.meta.env.VITE_PAGE_SIZE,
        })
      ).then(async (res) => {
        if (res.meta.requestStatus === "fulfilled") {
          setPreDefinedMessages((prevMessages) => [
            ...prevMessages,
            ...res.payload.messages,
          ]);
          getMessagesPreDefinedMessageEvent();
          setLoadedPage(true);
          setPreDefinedMessageStatus("fulfilled");
          await dispatch(setTotalPreDefinedMessages(res.payload.total));
        }
      });
    }

    loadPreDefinedMessages();
  }, []);

  const getDefinedMessages = async (currentPage: number) => {
    await dispatch(
      getPreDefinedMessages({
        page: currentPage,
        size: import.meta.env.VITE_PAGE_SIZE,
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        if (!isEmpty(res.payload.messages)) {
          setPreDefinedMessages((prevMessages) => [
            ...prevMessages,
            ...res.payload.messages,
          ]);
        }
      }
    });
  };

  const handleDeletePreDefinedMessage = async (id: number) => {
    setPreDefinedMessageStatus("loading");

    await dispatch(deletePreDefinedMessage({ id })).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        const messageWithoutTheRemovedOne = preDefinedMessages.filter(
          (msg) => msg.id !== res.payload.id
        );
        deletedPreDefinedMessageEvent(id, res.payload);
        setPreDefinedMessages(messageWithoutTheRemovedOne);
        setPreDefinedMessageStatus("fulfilled");
      }
    });
  };

  const handleEditPreDefinedMessage = async (
    id: number,
    subject: PreDefinedMessageSubject,
    title: string,
    message: string
  ) => {
    setPreDefinedMessageStatus("loading");

    await dispatch(
      updatePreDefinedMessage({ id, subject, title, message })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setPreDefinedMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg.id === res.payload.id ? res.payload : msg
          )
        );
        editPreDefinedMessageEvent(id, res.payload);
        setPreDefinedMessageStatus("fulfilled");
      }
    });
  };

  const handleCreatePreDefinedMessage = async (
    subject: PreDefinedMessageSubject,
    title: string,
    message: string
  ) => {
    setPreDefinedMessageStatus("loading");

    await dispatch(createPreDefinedMessage({ subject, title, message })).then(
      (res) => {
        if (res.meta.requestStatus === "fulfilled") {
          setPreDefinedMessages((prevMessages) => [
            ...prevMessages,
            res.payload,
          ]);
          createPreDefinedMessageEvent(res.payload);
          setPreDefinedMessageStatus("fulfilled");
        }
      }
    );
  };

  const handleIncreasePageNumber = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const preDefinedMessagesColumns = usePreDefinedMessagesColumns(
    handleDeletePreDefinedMessage,
    handleEditPreDefinedMessage
  );

  return (
    <>
      <CreatePreDefinedMessage
        onCreatePreDefinedMessage={handleCreatePreDefinedMessage}
      />

      {preDefinedMessagesHasError500 ? (
        <ErrorContent size="medium">
          Não conseguimos buscar suas mensagens pré definidas, tente novamente
          mais tarde ou atualize a página
        </ErrorContent>
      ) : (
        <>
          {loadedPage ? (
            <>
              {!isEmpty(preDefinedMessages) ? (
                <PreDefinedMessagesTable
                  columns={preDefinedMessagesColumns}
                  data={preDefinedMessages}
                  onPageChange={getDefinedMessages}
                  currentPage={currentPage}
                  onIncreasePageNumber={handleIncreasePageNumber}
                  status={preDefinedMessageStatus}
                />
              ) : (
                <EmptyContent size="medium">
                  Você não mensagens pré definidas
                </EmptyContent>
              )}
            </>
          ) : (
            <ClientsTableSkeletonPage />
          )}
        </>
      )}
    </>
  );
}
