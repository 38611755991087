import { ErrorContent } from "@/components/ErrorContent";
import { selectClientsHasError500 } from "@/features/clients/store/clientsSlice";
import { Flex } from "@gogeo-io/ui-library";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { ParamsClients } from "../components/ParamsClients";

export function ParamsClientsPage() {
  const hasError500 = useSelector(selectClientsHasError500);

  return (
    <>
      {hasError500 ? (
        <ErrorContent size="medium">
          Não conseguimos buscar os clientes de sua lista, tente novamente mais
          tarde ou atualize a página
        </ErrorContent>
      ) : (
        <Container>
          <Flex css={{ flexDirection: "column", height: "100%" }}>
            <ParamsClients />
          </Flex>
        </Container>
      )}
    </>
  );
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: ${({ theme }) => theme.space[2]};
`;
