import { updateAttendanceSchema } from "@/config/registerAttendanceSchema";
import { Feedback } from "@/core/models/feedback.model";
import { AttendanceRecord } from "@/core/models/history.model";
import { uploadFileToRegisterAttendance } from "@/core/store/uploadFile/uploadFileSlice";
import { useClientInfoMixpanel } from "@/features/clientInfo/hooks/useClientInfoMixpanel";
import { selectClient } from "@/features/clientInfo/store/clientSlice";
import { selectSelectedHistoryFilters } from "@/features/history/store/historyFiltersSlice";
import { getHistory } from "@/features/history/store/historySlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
  Flex,
  Heading,
  IconButton,
  Text,
} from "@gogeo-io/ui-library";
import { yupResolver } from "@hookform/resolvers/yup";
import G_UI from "@ui/index";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { AiOutlineClose } from "react-icons/ai";
import { BsPencil } from "react-icons/bs";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { selectFeedbackMappingHasError500 } from "../../store/feedbackMappingSlice";
import { updateFeedback } from "../../store/feedbackSlice";
import UI from "../../ui";
import { FeedbackObservations } from "../feedback/FeedbackObservations";
import { AttachFileToRegister } from "./AttachFileToRegister";
import { AttachedFilesOnRegister } from "./AttachedFilesOnRegister";
import { AttendanceFeedbackChannels } from "./AttendanceCommunicationChannel";
import DateActions from "./RegisterAttendanceDateActions";
import { TryAgainUploadFileToRegisterAttendance } from "./TryAgainUploadFileToRegisterAttendance";

interface UpdateAttendanceProps {
  attendance_record: AttendanceRecord;
}

export function UpdateAttendance({ attendance_record }: UpdateAttendanceProps) {
  const selectedClient = useSelector(selectClient);
  const selectedHistoryFilters = useSelector(selectSelectedHistoryFilters);
  const feedbackMappingHasError500 = useSelector(
    selectFeedbackMappingHasError500
  );

  const [updateAttendanceFormIsOpen, setUpdateAttendanceFormIsOpen] =
    useState(false);

  const [registerAttendanceStatus, setRegisterAttendanceStatus] =
    useState("success");
  const [uploadFileErrorMessage, setUploadFileErrorMessage] = useState("");
  const [uploadFileHasError, setUploadFileHasError] = useState(false);
  const [filesToSend, setFilesToSend] = useState<File[]>([]);
  const { registeredFeedbackEvent, registeredFeedbackOpenedEvent } =
    useClientInfoMixpanel();
  const dispatch = useAppDispatch();

  const formData = new FormData();

  const { handleSubmit, control, setValue, reset } = useForm<Feedback>({
    mode: "onBlur",
    resolver: yupResolver(updateAttendanceSchema),
    defaultValues: {
      observations: "",
      files: [],
      files_to_send: [],
    },
  });

  const onSubmit: SubmitHandler<Feedback> = async (
    data: Feedback
  ): Promise<void> => {
    setRegisterAttendanceStatus("loading");

    handleUpdateAttendance(data);
  };

  const handleUpdateAttendance = async (data: Feedback) => {
    setUploadFileHasError(false);

    await dispatch(
      updateFeedback({ feedback: data, client_id: selectedClient.id })
    ).then(async (res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setFilesToSend(data.files_to_send);

        if (data.files_to_send && data.files_to_send.length > 0) {
          for (let i = 0; i < data.files_to_send.length; i++) {
            formData.append(`files`, data.files_to_send[i]);
          }

          await dispatch(
            uploadFileToRegisterAttendance({
              feedback_id: attendance_record.id,
              data: formData,
            })
          ).then(async (res) => {
            if (res.meta.requestStatus === "fulfilled") {
              registeredFeedbackEvent(data);
              handleSetUpdateAttendanceFormIsOpen(false);
              onUpdateAttendanceSuccess();
            } else {
              setRegisterAttendanceStatus("fail");
              handleSetUpdateAttendanceFormIsOpen(false);
              setUploadFileHasError(true);
              setUploadFileErrorMessage(res.payload.errorMessage);
            }
          });
        } else {
          registeredFeedbackEvent(data);
          onUpdateAttendanceSuccess();
        }
      } else {
        handleSetUpdateAttendanceFormIsOpen(false);
        setRegisterAttendanceStatus("fail");
      }
    });
  };

  const onUpdateAttendanceSuccess = async () => {
    toast.success("Registro de atendimento concluído");
    reset();
    reloadHistory();
  };

  const reloadHistory = async () => {
    await dispatch(
      getHistory({
        client_id: selectedClient.id,
        page: 0,
        size: import.meta.env.VITE_PAGE_SIZE,
        filters: selectedHistoryFilters,
      })
    ).then(() => {
      handleSetUpdateAttendanceFormIsOpen(false);
      setRegisterAttendanceStatus("success");
    });
  };

  const handleSetUpdateAttendanceFormIsOpen = (value: boolean) => {
    setUpdateAttendanceFormIsOpen(value);
  };

  const onError = (errors: any) => {
    if (!isEmpty(errors)) {
      toast.error("Não foi possível registrar atendimento!");
    }
  };

  const handleCancelChanges = () => {
    reset();
    handleSetUpdateAttendanceFormIsOpen(false);
  };

  useEffect(() => {
    setValue("id", attendance_record.id);
    setValue("files", attendance_record.files);
    setValue("observations", attendance_record.observations);
    setValue("rescheduled_date", attendance_record.rescheduled_date);
    registeredFeedbackOpenedEvent();
  }, []);

  return (
    <>
      <Dialog
        open={updateAttendanceFormIsOpen}
        onOpenChange={handleSetUpdateAttendanceFormIsOpen}
      >
        <DialogTrigger asChild>
          <Flex
            css={{
              cursor: "pointer",
              fontSize: "1.25rem",
              color: "$gray700",
            }}
          >
            <BsPencil color="inherit" fontSize="inherit" />
          </Flex>
        </DialogTrigger>
        <DialogContent style={{ width: "450px" }}>
          <DialogClose asChild>
            <IconButton
              type="ghost"
              css={{
                position: "absolute",
                top: "1rem",
                right: "1rem",
              }}
            >
              <AiOutlineClose />
            </IconButton>
          </DialogClose>

          <UI.RegisterAttendanceForm noValidate>
            <Heading
              size="subtitle1"
              css={{ color: "$gray900", fontWeight: "bold" }}
            >
              Atualizar Atendimento
            </Heading>

            <Controller
              control={control}
              name="channel"
              render={({ field: { onChange, value } }) => {
                return (
                  <AttendanceFeedbackChannels
                    onChange={onChange}
                    value={value!}
                  />
                );
              }}
            />

            <Controller
              control={control}
              name="observations"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <Flex css={{ flexDirection: "column", gap: "$1" }}>
                    <Text>
                      Observações
                      <G_UI.ErrorMessage>
                        {!!error && error.message}
                      </G_UI.ErrorMessage>
                    </Text>
                    <FeedbackObservations onChange={onChange} value={value!} />
                  </Flex>
                );
              }}
            />

            <Controller
              control={control}
              name="files"
              render={({ field: { onChange, value: files } }) => {
                return (
                  <>
                    {files.length > 0 && (
                      <>
                        <Text>Arquivos Anexados no Atendimento</Text>
                        <AttachedFilesOnRegister
                          onChange={onChange}
                          value={files}
                        />
                      </>
                    )}
                  </>
                );
              }}
            />

            <Controller
              control={control}
              name="files_to_send"
              render={({ field: { onChange, value: files } }) => {
                return (
                  <AttachFileToRegister
                    onChange={onChange}
                    files={files}
                    titleOfDialog="Anexar Mais Arquivos"
                  />
                );
              }}
            />

            <Controller
              control={control}
              name="rescheduled_date"
              render={({ field: { onChange, value } }) => {
                return <DateActions onChange={onChange} value={value!} />;
              }}
            />

            <Flex css={{ gap: "$3" }}>
              <Button
                color="primary"
                bordered
                fullWidth
                onClick={handleCancelChanges}
              >
                Cancelar
              </Button>
              <Button
                fullWidth
                disabled={feedbackMappingHasError500}
                isLoading={registerAttendanceStatus === "loading"}
                onClick={() => handleSubmit(onSubmit, onError)()}
              >
                Salvar Alterações
              </Button>
            </Flex>
          </UI.RegisterAttendanceForm>
        </DialogContent>
      </Dialog>

      {uploadFileHasError && (
        <TryAgainUploadFileToRegisterAttendance
          feedbackId={attendance_record.id}
          triedToUploadFiles={filesToSend}
          onRegisterAttendanceSuccess={onUpdateAttendanceSuccess}
          errorMessage={uploadFileErrorMessage}
          onResetForm={reset}
          onReloadHistory={reloadHistory}
        />
      )}
    </>
  );
}
