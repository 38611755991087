import { ReduxThunkError } from "@/core/errors/ReduxThunkError";
import { AdvancedFilter } from "@/core/models/advanced-filters.model";
import { Tag } from "@/core/models/tag.model";
import { RootState } from "@/core/store/store";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";

interface ClientAdvancedFiltersState {
  available_advanced_filters: AdvancedFilter[];
  selected_advanced_filters: AdvancedFilter[];
  selected_tags: Tag[];
  status: "loading" | "success" | "failed";
}

const initialState = {
  available_advanced_filters: [],
  selected_advanced_filters: [],
  selected_tags: [],
  status: "loading",
} as ClientAdvancedFiltersState;

export const getAvailableClientFilters = createAsyncThunk(
  "clientAdvancedFilters/getAvailableClientFilters",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(`/available-advanced-filters/client`);
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

export const clientAdvancedFiltersSlice = createSlice({
  name: "clientAdvancedFiltersSlice",
  initialState,
  reducers: {
    updateValueFromSelectedAdvancedFilter(
      state,
      action: PayloadAction<{ filterId: number; newValue: any }>
    ) {
      state.selected_advanced_filters = state.selected_advanced_filters.map(
        (filter) =>
          filter.id === action.payload.filterId
            ? { ...filter, value: action.payload.newValue }
            : filter
      );
    },
    addTagsOnSelectedClientFilter(state, action: PayloadAction<Tag[]>) {
      state.selected_tags = action.payload;
    },
    addTagOnSelectedClientFilter(state, action: PayloadAction<Tag>) {
      const tagToAdd = action.payload;

      state.selected_tags = state.selected_tags ? state.selected_tags : [];
      const index = state.selected_tags.findIndex(
        (tag) =>
          tag.slug === tagToAdd.slug ||
          tag.label === tagToAdd.slug ||
          tag.id === tagToAdd.id
      );
      if (index === -1) {
        state.selected_tags.push(tagToAdd);
      } else {
        state.selected_tags[index] = tagToAdd;
      }
    },
    removeTagOnSelectedClientFilter(state, action: PayloadAction<Tag>) {
      const tagToRemove = action.payload;

      const idx = state.selected_tags.findIndex(
        (tag) =>
          tag.slug === tagToRemove.slug ||
          tag.label === tagToRemove.slug ||
          tag.id === tagToRemove.id
      );
      state.selected_tags.splice(idx, 1);
    },
    removeSelectedAdvancedFilter(state, action: PayloadAction<AdvancedFilter>) {
      state.selected_advanced_filters = state.selected_advanced_filters.filter(
        (filter) => filter.id !== action.payload.id
      );
    },
    addSelectedAdvancedFilter(state, action: PayloadAction<AdvancedFilter[]>) {
      state.selected_advanced_filters = [
        ...state.selected_advanced_filters,
        ...action.payload,
      ];
    },
    setSelectedAdvancedFilters(state, action: PayloadAction<AdvancedFilter[]>) {
      state.selected_advanced_filters = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAvailableClientFilters.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getAvailableClientFilters.fulfilled, (state, action) => {
      state.status = "success";
      state.available_advanced_filters = action.payload;
    });
    builder.addCase(
      getAvailableClientFilters.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = "failed";
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName(
            "Error getting the available filters for client"
          );
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
  },
});

////////////////////////////////////////////////////

export const selectAvailableAdvancedFilters = (state: RootState) =>
  state.clientFiltersState.available_advanced_filters;
export const selectSelectedTagsOnClientFilters = (state: RootState) =>
  state.clientFiltersState.selected_tags;
export const selectSelectedAdvancedFilters = (state: RootState) =>
  state.clientFiltersState.selected_advanced_filters;
export const selectAvailableAdvancedFiltersStatus = (state: RootState) =>
  state.clientFiltersState.status;

////////////////////////////////////////////////////

export const {
  setSelectedAdvancedFilters,
  removeSelectedAdvancedFilter,
  updateValueFromSelectedAdvancedFilter,
  addSelectedAdvancedFilter,
  addTagOnSelectedClientFilter,
  removeTagOnSelectedClientFilter,
  addTagsOnSelectedClientFilter,
} = clientAdvancedFiltersSlice.actions;

////////////////////////////////////////////////////

export const clientFiltersReducer = clientAdvancedFiltersSlice.reducer;
