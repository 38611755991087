import {
  PreDefinedMessage,
  PreDefinedMessageSubject,
} from "@/core/models/preDefinedMessage.model";
import { Flex, IconButton } from "@gogeo-io/ui-library";
import { Delete, Edit } from "@mui/icons-material";
import { UpdatePreDefinedMessage } from "../UpdatePreDefinedMessage";

interface PreDefinedMessagesActionsCellProps {
  preDefinedMessage: PreDefinedMessage;
  onDeletePreDefinedMessage: (id: number) => void;
  onUpdatePreDefinedMessage: (
    id: number,
    subject: PreDefinedMessageSubject,
    title: string,
    message: string
  ) => Promise<void>;
}

export function PreDefinedMessagesActionsCell({
  preDefinedMessage,
  onDeletePreDefinedMessage,
  onUpdatePreDefinedMessage,
}: PreDefinedMessagesActionsCellProps) {
  const handleDeletePreDefinedMessage = () => {
    onDeletePreDefinedMessage(preDefinedMessage.id);
  };

  return (
    <Flex css={{ gap: "$2" }}>
      <UpdatePreDefinedMessage
        preDefinedMessage={preDefinedMessage}
        onUpdatePreDefinedMessage={onUpdatePreDefinedMessage}
      >
        <IconButton type="soft">
          <Edit />
        </IconButton>
      </UpdatePreDefinedMessage>

      <IconButton color="danger" onClick={handleDeletePreDefinedMessage}>
        <Delete />
      </IconButton>
    </Flex>
  );
}
