import { ListOfTags } from "@/core/models/listOfTags.model";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import PushPinIcon from "@mui/icons-material/PushPin";
import { motion } from "framer-motion";
import UI from "../ui";

interface AttendanceListItemIconProps {
  listOfTags: ListOfTags;
}

export function AttendanceListItemIcon({
  listOfTags,
}: AttendanceListItemIconProps) {
  const returnRightIcon = (listOfTags: ListOfTags) => {
    if (listOfTags.fixed && listOfTags.brand_new) {
      return (
        <UI.ListIsBrandNew>
          <motion.div
            variants={{
              pulse: {
                scale: [1, 1.2, 1, 1.3, 1, 1],
                transition: {
                  repeat: Infinity,
                  duration: 2,
                },
              },
            }}
            animate="pulse"
          >
            <NewReleasesIcon fontSize="inherit" color="inherit" />
          </motion.div>
        </UI.ListIsBrandNew>
      );
    } else if (listOfTags.fixed) {
      return (
        <UI.ListIsItemFixed>
          <PushPinIcon fontSize="inherit" color="inherit" />
        </UI.ListIsItemFixed>
      );
    } else if (listOfTags.brand_new) {
      return (
        <UI.ListIsBrandNew>
          <motion.div
            variants={{
              pulse: {
                scale: [1, 1.2, 1, 1.3, 1, 1],
                transition: {
                  repeat: Infinity,
                  duration: 2,
                },
              },
            }}
            animate="pulse"
          >
            <NewReleasesIcon fontSize="inherit" color="inherit" />
          </motion.div>
        </UI.ListIsBrandNew>
      );
    }
  };

  return returnRightIcon(listOfTags);
}
