import { ListDivider } from "@/components/ListDivider";
import { selectUser } from "@/core/store/users/usersSlice";
import { ClientProfileInfoPhone } from "@/features/attendance/components/ClientProfileInfoPhone";
import { ProfileInfo } from "@/features/attendance/components/ProfileInfo";
import { selectClient } from "@/features/clientInfo/store/clientSlice";
import { useMask } from "@/hooks/useMask";
import { Flex, Heading } from "@gogeo-io/ui-library";
import { isEmpty, isUndefined } from "lodash";
import { useSelector } from "react-redux";

export function ClientProfileContactData() {
  const selectedClient = useSelector(selectClient);
  const user = useSelector(selectUser);

  const { usePhoneMask } = useMask();

  return (
    <Flex
      css={{
        flexDirection: "column",
        gap: "0.625rem",
        padding: "0.625rem",
      }}
    >
      <Heading size="subtitle1" css={{ color: "$gray800" }}>
        Dados de Contato
      </Heading>
      <ListDivider />
      {!isEmpty(selectedClient.email) && !isUndefined(selectedClient.email) ? (
        <ProfileInfo
          asOuterLink
          label="Email"
          value={selectedClient.email}
          href={selectedClient.email}
        />
      ) : (
        <ProfileInfo label="Email" value="Não consta" />
      )}

      {!isEmpty(selectedClient.main_phone) &&
      !isUndefined(selectedClient.main_phone) ? (
        <ClientProfileInfoPhone
          label="Telefone Principal"
          value={usePhoneMask(selectedClient.main_phone)}
          client={selectedClient}
          user={user}
        />
      ) : (
        <ProfileInfo label="Telefone Principal" value="Não consta" />
      )}

      {!isEmpty(selectedClient.phones) &&
        !isUndefined(selectedClient.phones) &&
        selectedClient.phones.map((phone, idx) => {
          return (
            <ClientProfileInfoPhone
              key={idx}
              label={phone.label}
              value={usePhoneMask(phone.value)}
              client={selectedClient}
              user={user}
            />
          );
        })}
    </Flex>
  );
}
