import { ListOfTags, ListOfTagsFilter } from "@/core/models/listOfTags.model";
import { selectAttrOptsToFilter } from "@/features/clientInfo/store/clientAttrOptsSlice";
import { selectColumnsToSort } from "@/features/clientInfo/store/clientColumnsToSortSlice";
import { useGetClientsByFiltersParams } from "@/features/clients/hooks/useGetClientsByFiltersParams";
import useAppDispatch from "@/hooks/useAppDispatch";
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Flex,
  IconButton,
} from "@gogeo-io/ui-library";
import { Close } from "@mui/icons-material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAttendanceMixpanel } from "../../hooks/useAttendanceMixpanel";
import {
  selectSelectedAdvancedFilters,
  selectSelectedTagsOnClientFilters,
} from "../../store/clientAdvancedFiltersSlice";
import { emitEventToChangeClients } from "../../store/emitEventToChangeClientsSlice";
import {
  addListOnState,
  createListOfTags,
  updateListFromState,
} from "../../store/listOfTagsSlice";
import {
  selectList,
  selectSelectedListToEdit,
} from "../../store/listSelectedSlice";
import { SaveAsDialog } from "./SaveAsDialog";

interface AdvancedFiltersActionsProps {
  onCleanFilters: () => Promise<void>;
}

export function AdvancedFiltersActions({
  onCleanFilters,
}: AdvancedFiltersActionsProps) {
  const [saveAsDialogIsOpen, setSaveAsDialogIsOpen] = useState(false);

  const selectedAdvancedFilters = useSelector(selectSelectedAdvancedFilters);
  const selectedTagsOnClientFilters = useSelector(
    selectSelectedTagsOnClientFilters
  );
  const selectedListToEdit = useSelector(selectSelectedListToEdit);

  const columnsToSort = useSelector(selectColumnsToSort);
  const selectedAttrOpts = useSelector(selectAttrOptsToFilter);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { updateListOfTagsEvent, createListOfTagsEvent } =
    useAttendanceMixpanel();
  const { paramsOnEditListView } = useGetClientsByFiltersParams();

  const handleSetSaveAsDialogIsOpen = (value: boolean) =>
    setSaveAsDialogIsOpen(value);

  const handleCreateList = async (list_name: string) => {
    await dispatch(emitEventToChangeClients(false));

    let filters: ListOfTagsFilter = {};

    filters = paramsOnEditListView();
    delete filters.match;

    if (list_name === "")
      return toast.error("Dê um nome a lista antes de salvar");

    const args = {
      name: list_name,
      filters,
    };

    await dispatch(createListOfTags(args)).then(async (value) => {
      if (value.payload.status === 409) {
        return;
      }

      if (value.meta.requestStatus === "fulfilled") {
        const createdList: ListOfTags = value.payload;

        createListOfTagsEvent(createdList);
        await dispatch(addListOnState(createdList));

        await dispatch(selectList(createdList));

        await dispatch(emitEventToChangeClients(true));

        navigate(`/attendance/${createdList.slug}`);
      }
    });
  };

  const handleUpdateList = async () => {
    await dispatch(emitEventToChangeClients(false));

    const filters: ListOfTagsFilter = {
      advanced_filters: [...selectedAdvancedFilters],
      tags: [...selectedTagsOnClientFilters],
      ...(selectedAttrOpts !== undefined &&
        selectedAttrOpts.length > 0 && {
          attr_opts: [...selectedAttrOpts],
        }),
      ...(columnsToSort !== undefined &&
        columnsToSort.length > 0 && {
          sort: {
            orders: columnsToSort,
          },
        }),
    };

    const args = {
      id: selectedListToEdit.id,
      name: selectedListToEdit.name,
      filters,
    };

    await dispatch(createListOfTags(args)).then(async (value) => {
      if (value.meta.requestStatus === "fulfilled") {
        const createdList: ListOfTags = value.payload;

        updateListOfTagsEvent(selectedListToEdit, value.payload);

        await dispatch(selectList(createdList));

        await dispatch(updateListFromState(createdList));

        await dispatch(emitEventToChangeClients(true));

        navigate(`/attendance/${createdList.slug}`);
      }
    });
  };

  return (
    <Flex
      css={{
        flexDirection: "column",
        alignItems: "center",
        gap: "$2",
      }}
    >
      <Flex align="end" justify="end" css={{ gap: "$4", width: "100%" }}>
        <Button bordered fullWidth onClick={() => onCleanFilters()}>
          Limpar Alterações
        </Button>

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button fullWidth>Salvar Alterações</Button>
          </DropdownMenuTrigger>

          <DropdownMenuContent sideOffset={5}>
            <DropdownMenuItem
              onClick={handleUpdateList}
              disabled={selectedListToEdit.is_suggested_by_govendas}
            >
              Salvar
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => setSaveAsDialogIsOpen(true)}>
              Salvar como
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </Flex>

      <Dialog
        open={saveAsDialogIsOpen}
        onOpenChange={handleSetSaveAsDialogIsOpen}
      >
        <DialogTrigger />
        <DialogContent style={{ width: "450px" }}>
          <DialogTitle>Salvar como</DialogTitle>

          <DialogClose asChild>
            <IconButton
              type="ghost"
              css={{ position: "absolute", top: "1rem", right: "1rem" }}
            >
              <Close />
            </IconButton>
          </DialogClose>

          <SaveAsDialog
            selectedList={selectedListToEdit}
            onSaveAsList={handleCreateList}
          />
        </DialogContent>
      </Dialog>
    </Flex>
  );
}
