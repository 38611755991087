import { ParamsFreq } from "@/components/ParamsFreq";
import { ShowPreferedDays } from "@/components/PreferedDays/ShowPreferedDays";
import { PreferedDaysAndWeeksSubtitle } from "@/components/PreferedDaysAndWeeksSubtitle";
import { PreferedWeeks } from "@/components/PreferedWeeks";
import { selectClientParams } from "@/features/clientInfo/store/clientSlice";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Flex,
  Text,
} from "@gogeo-io/ui-library";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useClientInfoMixpanel } from "../../hooks/useClientInfoMixpanel";
import FiberNew from "@mui/icons-material/FiberNew";

export function ClientProfileParameters() {
  const selectedClientParams = useSelector(selectClientParams);

  const { clientProfileParametersClickedEvent } = useClientInfoMixpanel();

  return (
    <Accordion type="single" collapsible width="full" defaultValue="item-1">
      <AccordionItem value="item-1">
        <AccordionTrigger onClick={() => clientProfileParametersClickedEvent()}>
          <Text>Parametrização</Text>
        </AccordionTrigger>
        <AccordionContent>
          <AccordionContainerContent>
            <ParamsFreq freq={selectedClientParams.freq} isBlocked />
            <div className="item">
              <Text css={{ color: "$gray500" }}>
                Semana de atendimento no mês:
              </Text>

              <PreferedWeeks params={selectedClientParams} isBlocked />
            </div>
            <div className="item">
              <Text css={{ color: "$gray500" }}> Dias preferidos no mês:</Text>

              <ShowPreferedDays
                isBlocked
                daysWeekPrefReceived={selectedClientParams.days_week_pref}
              />
            </div>

            <PreferedDaysAndWeeksSubtitle />

            <div className="item">
              <Flex css={{ color: "$primary500", alignItems: "center" }}>
                <Text bold css={{ color: "$gray500" }}>
                  Regra Comercial Aplicada:
                </Text>
                <FiberNew color="inherit" />
              </Flex>
              <Text bold>{selectedClientParams.observations}</Text>
            </div>
          </AccordionContainerContent>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}

export const AccordionContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[4]};
  width: 100%;
  height: 100%;
  overflow-x: auto;

  .item {
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.space[2]};
  }
`;
