import { Flex } from "@gogeo-io/ui-library";
import G_UI from "@ui/index";
import RLSSkeleton from "react-loading-skeleton";
import { ListDivider } from "../ListDivider";

export function SelectPreDefinedMessageToSendSkeleton() {
  return (
    <Flex
      css={{
        flexDirection: "column",
        gap: "1.5rem",
        padding: "1rem 0",
      }}
    >
      <Flex css={{ flexDirection: "column", gap: "$2" }}>
        <RLSSkeleton width={260} height={16} />

        <RLSSkeleton height={37} />
      </Flex>

      <Flex css={{ flexDirection: "column", gap: "$2" }}>
        <RLSSkeleton width={120} height={16} />
        <RLSSkeleton height={75} />
      </Flex>

      <ListDivider isSkeleton />

      <G_UI.ButtonGroup>
        <RLSSkeleton width={80} height={32} />

        <RLSSkeleton width={165} height={32} />
      </G_UI.ButtonGroup>
    </Flex>
  );
}
