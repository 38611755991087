import { EmptyContent } from "@/components/EmptyContent";
import { TrackingEvents } from "@/config/mixpanel";
import { ReportParams } from "@/core/models/reports.model";
import { selectUser } from "@/core/store/users/usersSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { useMixpanel } from "@/hooks/useMixpanel";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ReportItem } from "../components/ReportItem";
import { getReport } from "../store/reportSlice";
import { selectReports } from "../store/reportsSlice";
import { ReportSkeletonPage } from "./ReportSkeletonPage";

export function SpecificReport() {
  const [loading, setLoading] = useState(true);
  const [errorOnGetTheReport, setErrorOnGetTheReport] = useState(false);
  const [dateToReport, setDateToReport] = useState("");

  const user = useSelector(selectUser);
  const reports = useSelector(selectReports);

  const { dashboard_name } = useParams();

  const dispatch = useAppDispatch();

  const { reportPageViewed } = useMixpanel();

  useEffect(() => {
    const findedReport = reports.find(
      (report) => report.dashboard_name === dashboard_name
    );

    async function loadReports() {
      if (findedReport !== undefined) {
        setErrorOnGetTheReport(false);

        await dispatch(
          getReport({
            dashboard_name,
            data: {
              date_filter: findedReport.filter_fields.find(
                (filter) => filter.name === "date_filter"
              ).default_value,
              salesman_ids: [user.salesman_id],
              salesman_type: null,
              schedule_type: null,
            } as ReportParams,
          })
        ).then((res) => {
          if (res.meta.requestStatus === "fulfilled") {
            setDateToReport(
              findedReport.filter_fields.find(
                (filter) => filter.name === "date_filter"
              ).default_value
            );
            setLoading(false);
          }
        });
      } else {
        setErrorOnGetTheReport(true);
      }
    }

    loadReports();

    reportPageViewed(TrackingEvents.REPORT_PAGE, dashboard_name);
  }, [dashboard_name]);

  const handleSetDateOnReport = (date: string) => setDateToReport(date);

  if (errorOnGetTheReport) {
    return <EmptyContent>Não foi possível encontrar o relatório</EmptyContent>;
  }

  return (
    <>
      {!loading ? (
        <ReportItem
          dateToReport={dateToReport}
          onSetDateToReport={handleSetDateOnReport}
        />
      ) : (
        <ReportSkeletonPage />
      )}
    </>
  );
}
