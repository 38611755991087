import { AppDispatch } from "@/core/store/store";
import { selectUser } from "@/core/store/users/usersSlice";
import { selectSelectedList } from "@/features/attendance/store/listSelectedSlice";
import { ClientsPageSkeleton } from "@/features/clientInfo/components/ClientsPageSkeleton";
import {
  getAvailableHistoryFilters,
  selectAvailableHistoryFiltersStatus,
} from "@/features/history/store/historyFiltersSlice";
import { useNavigateSearch } from "@/hooks/useNavigateSearch";
import { PageLayout } from "@/layouts/PageLayout";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import { ClientProfile } from "../components/ClientProfile";
import { getClientById, selectClientStatus } from "../store/clientSlice";

export const ClientProfilePage = () => {
  const clientStatus = useSelector(selectClientStatus);
  const selectedList = useSelector(selectSelectedList);
  const user = useSelector(selectUser);
  const availableHistoryFiltersStatus = useSelector(
    selectAvailableHistoryFiltersStatus
  );

  const dispatch = useDispatch<AppDispatch>();
  const navigateSearch = useNavigateSearch();

  const { client_id } = useParams();

  const setParams = async () => {
    const { filters } = selectedList;
    const { tags } = filters;

    const params = {
      ...(filters &&
        tags && {
          tags: tags.map((tag) => {
            return Number(tag.id);
          }),
        }),
    };

    return params;
  };

  useEffect(() => {
    async function loadClientAndParams() {
      if (client_id) {
        await dispatch(getClientById({ client_id: client_id })).then(
          async (obj) => {
            if (obj.meta.requestStatus === "rejected") {
              if (!isEmpty(selectedList)) {
                const params = setParams();
                navigateSearch(
                  `/attendance/${selectedList.slug}/filters`,
                  params
                );
              } else {
                const my_clients_list_slug = `${user.account_id}_${user.id}_meus_clientes`;
                navigateSearch(
                  `/attendance/${my_clients_list_slug}/filters`,
                  {}
                );
                navigateSearch(
                  `/attendance/${my_clients_list_slug}/filters`,
                  {}
                );
              }
            }
          }
        );
      }
    }

    async function loadHistoryAvailableFilters() {
      await dispatch(getAvailableHistoryFilters());
    }

    loadClientAndParams();
    loadHistoryAvailableFilters();
  }, []);

  return (
    <PageLayout
      leftSidebar={
        clientStatus === "success" &&
        availableHistoryFiltersStatus === "success" ? (
          <ClientProfile />
        ) : (
          <ClientsPageSkeleton />
        )
      }
      content={<Outlet />}
    ></PageLayout>
  );
};
